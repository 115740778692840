import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import CreateFolderButton from 'components/folder/createFolderButton/CreateFolderButton'
import CreateBoardButton from 'components/board/createBoardButton/CreateBoardButton'
import CreatePinButton from 'components/pin/createPinButton/CreatePinButton'
import LogoutButton from 'components/user/logoutButton/LogoutButton'
import ToogleNSFWButton from 'components/common/toogleNSFWButton/ToogleNSFWButton'

import styles from 'components/common/popupMenuWrapper/popupMenuWrapper.module.css'

function UserMenu(props) {

  return (
    <ul>
      <li><Link
        to={`/${props.username}/pins/`}
      >My pins</Link></li>
      <hr className={styles.textPaddedHr} />
      <li><Link
        to={`/${props.username}/boards/`}
      >My boards</Link></li>
      <hr className={styles.textPaddedHr} />
      <li><Link
        to={`/${props.username}/folders/`}
      >My folders</Link></li>
      <hr />
      <li><CreatePinButton /></li>
      <hr className={styles.textPaddedHr} />
      <li><CreateBoardButton /></li>
      <hr className={styles.textPaddedHr} />
      <li><CreateBoardButton
        private={true}
      /></li>
      <hr className={styles.textPaddedHr} />
      <li><CreateFolderButton /></li>
      <hr className={styles.textPaddedHr} />
      <li><CreateFolderButton
        private={true}
      /></li>
      <hr />
      <li><ToogleNSFWButton /></li>
      <hr />
      <li><Link
        to="/profile/"
      >Profile</Link></li>
      <hr />
      <li><LogoutButton /></li>
    </ul>
  )
}

UserMenu.propTypes = {
  username: PropTypes.string.isRequired,
}

export default UserMenu
