import React, { useState } from 'react'
import PropTypes from 'prop-types'

import OrderPinsItem from 'components/pin/orderPinsItem/OrderPinsItem'
import styles from 'components/board/userBoards/userBoards.module.css'

export const PIN_DRAG_TYPE = "pinpict/pin"

const ACCEPTED_DROP = PIN_DRAG_TYPE

function OrderPinsList(props) {
  //console.log('OrderPinsList', props)
  
  const [dragIndex, setDragIndex] = useState(null)

  const handleDrag = (e, index) => {
    /*
     * On drag start, store action targets
     * and drag type in state
     */
    e.persist()
    e.dataTransfer.setData(PIN_DRAG_TYPE, index)
    e.dataTransfer.dropEffect = "move"

    /* We store index in state because it can't be read
     * from dataTranfer on over effect
     */
    setDragIndex(index)
  }

  const handleDrop = (e, dropIndex) => {
    let newPins = props.pins.slice()
    const dragged = props.pins[dragIndex]

    if (dragIndex < dropIndex) dropIndex = dropIndex - 1
    // remove draged item from list
    newPins.splice(dragIndex, 1)
    // add draged pin to new position
    newPins.splice(dropIndex, 0, dragged)
    props.setPins(newPins)
  }

  const handleDragend = () => {
    setDragIndex(null)
  }

  const dropValid = (types) => {
    /*
     * We convert to a list because firefox convert types to a 
     * "DomStringList" which made everything fail
     */
    types = Array.prototype.slice.call(types, 0)
    return types.includes(ACCEPTED_DROP)
  }


  return (
    <section className={styles.boardsSection + " columned"}>
      <div>
        {props.pins.map((pin, index) =>
          (<OrderPinsItem
            key={pin.node.id}
            pinID={pin.node.id}
            index={index}
            handleDrag={handleDrag}
            handleDrop={handleDrop}
            handleDragend={handleDragend}
            dropValid={dropValid}
            dragIndex={dragIndex}
          />)
        )}
      </div>
    </section>
  )
}

OrderPinsList.propTypes = {
  pins: PropTypes.array.isRequired,
  setPins: PropTypes.func.isRequired,
}


export default OrderPinsList
