import PropTypes from 'prop-types'
import {
  gql,
  useQuery,
} from '@apollo/client'
import { Link } from 'react-router-dom'

import PinItButton from 'components/pin/pinItButton/PinItButton'
import PinImg from 'components/pin/pinImg/PinImg'
import ErrorMessage from 'components/common/errorMessage/ErrorMessage'
import Spinner from 'components/common/spinner/Spinner'

import styles from './pinBoards.module.css'
import { PIN_IMAGE_FRAGMENT } from 'components/pin/pinPicture/fragments'
import mainStyles from 'components/pin/pinDetail/pinDetail.module.css'


const PIN_BOARDS_QUERY = gql`
  ${PIN_IMAGE_FRAGMENT}
  query PinBoardsQuery($pinID: ID!) {
    pin(id: $pinID) {
      id
      user {
        username
      }
      boards {
        edges {
          id
          slug
          title
          pins { totalCount }
          folder { name }
          cover1 { ...PinImageFragment }
        }
      }
    }
  }
`



function PinBoards({ pinID, owner }) {
  
  const { loading, data, error } = useQuery(PIN_BOARDS_QUERY, {
    variables: { pinID }
  })

  if (loading) return <Layout><Spinner /></Layout>
  if (error) {
    return <Layout><ErrorMessage error={error} /></Layout>
  }

  const boards = data.pin.boards.edges
  
  return (
    <Layout>
      <div>
        {boards.map(board =>
          <BoardListItem
            key={board.id}
            slug={board.slug}
            username={data.pin.user.username}
            cover={board.cover1}
            title={board.title}
            folderName={board.folder?.name}
            pinsCount={board.pins.totalCount}
          />
        )}
        {owner ? (
          <PinOnBoardsButton pinID={pinID} owner={owner} />
        ) : null}
      </div>
      <footer className={mainStyles.cardFooter}>
        <span>Pinned on</span>{boards.length} {boards.length === 1 ? "board" : "boards"}
      </footer>
    </Layout>
  )
}

export default PinBoards


PinBoards.propTypes = {
  pinID: PropTypes.string.isRequired,
  owner: PropTypes.bool.isRequired,
}

const BoardListItem = ({ slug, username, cover, title, folderName, pinsCount }) => {
  return (
    <div className={styles.boardListItemWrapper}>
      <div className={styles.boardListItem}>
        <Link to={`/${username}/boards/${slug}/`}>
          <div className={`borderedImgWrapper ${styles.imgWrapper}`}>
            {cover ? (
              <PinImg
                pinID={cover.id}
                minWidth={48}
                minHeight={48}
                alt={title}
              />
            ) : null}
          </div>
          <div className={styles.textWrapper}>
            <div className={styles.mainline}>{title}</div>
            <div className={styles.subline}>{folderName ? `In "${folderName}"\u2002\u2022\u2002` : ""}{pinsCount}{pinsCount === 1 ? " pin" : " pins"}</div>
          </div> 
        </Link>
      </div>
      <hr className={styles.paddedHr} />
    </div>
  )
}

const PinOnBoardsButton = ({ pinID, owner }) => {
  return (
    <div className={styles.boardListItem}>
      <PinItButton pinID={pinID} owner={owner}>
        <div className={`borderedImgWrapper ${styles.pinItLogo}`} />
        <div className={styles.textWrapper}>
          <div className={styles.buttonLine}>Pin on boards...</div>
        </div>
      </PinItButton>
    </div>
  )
}

const Layout = ({ children }) => {
  return (
    <article className={mainStyles.card}>
      <header className={mainStyles.cardHeader}>
        <h5>Boards</h5>
      </header>
      {children}
    </article>
  )
}
