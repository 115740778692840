
import PropTypes from 'prop-types'
import PinItButton from 'components/pin/pinItButton/PinItButton'
import UpdatePinButton from 'components/pin/updatePinButton/UpdatePinButton'
import SetCoverButton from 'components/board/setCoverButton/SetCoverButton'
import DeletePinButton from 'components/pin/deletePinButton/DeletePinButton'

import styles from 'components/common/popupMenuWrapper/popupMenuWrapper.module.css'



function PinDetailMenu({ owner, pinID, boardID, sourceFileUrl, original, backLink }) {
  return (
    <ul>
      <li><PinItButton
        owner={owner}
        pinID={pinID}
      /></li>
      { owner ? (
        <>
          <hr className={styles.textPaddedHr} />
          <li><UpdatePinButton
            text="Edit pin"
            pinID={pinID}
          /></li>
        </>
      ) : null}
      { sourceFileUrl ? (
        <>
          <hr className={styles.textPaddedHr} />
          <li><a 
            href={sourceFileUrl}
            target="_blank"
            rel="noopener noreferrer"
          >Visit origin website</a></li>
        </>
      ) : null}
      <hr className={styles.textPaddedHr} />
      <li><a
        href={original}
        target="_blank"
        rel="noopener noreferrer"
      >Open original image</a></li>
      { owner && pinID && boardID ? (
        <>
          <hr className={styles.textPaddedHr} />
          <li><SetCoverButton
            text="Set pin as board cover"
            pinID={pinID}
            boardID={boardID}
          /></li>
        </>
      ) : null}
      { owner && pinID ? (
        <>
        <hr />
        <li><DeletePinButton
          text="Delete pin"
          pinID={pinID}
          backLink={backLink}
        /></li></>
      ) : null}
    </ul>
  )

}

PinDetailMenu.propTypes = {
  owner: PropTypes.bool.isRequired,
  pinID: PropTypes.string.isRequired,
  boardID: PropTypes.string,
  sourceFileUrl: PropTypes.string,
  original: PropTypes.string,
  backLink: PropTypes.string,
}

export default PinDetailMenu