import FieldWrapper from 'components/forms/fieldWrapper/FieldWrapper'
import FormRequiredField from 'components/forms/formRequiredField/FormRequiredField'

function FormRequiredFields() {
  return (
    <FieldWrapper>
      <FormRequiredField /> Required fields</FieldWrapper>)
}

export default FormRequiredFields
