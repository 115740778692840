import PropTypes from 'prop-types'

import FormRequiredField from 'components/forms/formRequiredField/FormRequiredField'
import FormRequiredFields from 'components/forms/formRequiredFields/FormRequiredFields'
import FieldWrapper from 'components/forms/fieldWrapper/FieldWrapper'
import FormErrors from 'components/forms/formErrors/FormErrors'
import ResetInput from 'components/forms/resetInput/ResetInput'

function DownloadPinForm(props) {
  return (
    <form
      id={props.form}
      onSubmit={props.onSubmit}
    >
      <FormRequiredFields />
      <FieldWrapper>
        <label htmlFor="id-url">Url <FormRequiredField /></label>
        <ResetInput
          id="id-url"
          name="url"
          type="text"
          value={props.url}
          handleChange={props.handleUrlChange}
          required={true}
          autoFocus={true}
        />
      </FieldWrapper>
      <FieldWrapper>
        <FormErrors
          error={props.error}
        />
      </FieldWrapper>
    </form>
  )
}


DownloadPinForm.propTypes = {
  form: PropTypes.string.isRequired,
  url: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleUrlChange: PropTypes.func.isRequired,
}


export default DownloadPinForm
