import PropTypes from 'prop-types'

import AccessibilityText from 'components/common/accessibilityText/AccessibilityText'
import styles from './checkboxButton.module.css'

function CheckboxButton({ checked, onClick, label, uncheckedTitle, checkedTitle }) {
  return (
    <button 
      type="button"
      className={checked ? styles.checked : styles.unchecked}
      onClick={onClick}
      title={checked ? checkedTitle : uncheckedTitle}
    ><AccessibilityText text={checked ? "checked" : "unchecked"} />
      {label}
    </button>
  )
}

CheckboxButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  uncheckedTitle: PropTypes.string,
  checkedTitle: PropTypes.string,
}

export default CheckboxButton