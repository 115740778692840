
import {
  useQuery,
  gql,
} from '@apollo/client'
import { useParams } from 'react-router-dom'
import { NetworkStatus } from '@apollo/client'

import PinsList from 'components/pin/pinsList/PinsList'
import ErrorMessage from 'components/common/errorMessage/ErrorMessage'
import Spinner from 'components/common/spinner/Spinner'

const USER_PINS_QUERY = gql`
  query UserPinsQuery(
    $username: String!,
    $first: Int,
    $after: String,
  ) {
    me {
      id
    }
    user(username: $username) {
      id
      pins(first: $first, after: $after) {
        edges {
          node {
            id
            caption
            rating
            height
            width
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

function UserPins(props) {
  let { username } = useParams()
  const { data, error, fetchMore, networkStatus } = useQuery(USER_PINS_QUERY, {
    variables: { 
      username,
      first: 50,
      after: null,
    },
  })

  if (networkStatus === NetworkStatus.loading) return <Spinner />
  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }
  let owner = !!data.me && data.me.id === data.user.id
  let user = data.user
  
  //console.log('UserPins', username, data, error)

  return (
    <>
      <PinsList
        pins={user.pins.edges}
        owner={owner}
        hasNextPage={user.pins.pageInfo.hasNextPage}
        loadMore={() => {
          //console.log('Load more after', user.pins.pageInfo.endCursor)
          return fetchMore({
            variables: { 
              after: user.pins.pageInfo.endCursor,
            },
            notifyOnNetworkStatusChange: true
          })
        }}
        loadingMore={networkStatus === NetworkStatus.fetchMore }
        username={username}
        baseLink={`/${username}/`}
      />
    </>
  )
}


export default UserPins
