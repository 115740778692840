import PropTypes from 'prop-types'
import { modalStatusVar, modalTypes } from 'modal'

function DeletePinButton(props) {
  //console.log('DeletePinButton', props)
  
  const handleClick = () => {
    modalStatusVar({
      type: modalTypes.DELETE_PIN,
      options: { 
        pinID: props.pinID,
        backLink: props.backLink,
      },
    })
  }

  return (
    <button
      title={props.title || ""}
      className={props.className}
      onClick={handleClick}
    >{props.text || "Delete pin"}</button>
  )

}

DeletePinButton.propTypes = {
  pinID: PropTypes.string.isRequired,
  backLink: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
}

export default DeletePinButton

