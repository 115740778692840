import PropTypes from 'prop-types'
import { useMemo, useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'

import { PIN_IMAGE_FRAGMENT } from 'components/pin/pinPicture/fragments'
import PinImg from 'components/pin/pinImg/PinImg'
import MinusButton from 'components/forms/minusButton/MinusButton'

import styles from './boardSelect.module.css'

const BOARD_INFO_QUERY = gql`
  ${PIN_IMAGE_FRAGMENT}
  query BoardInfoQuery($boardID: ID!) {
    board(id: $boardID) {
      __typename
      ... on Board {
        id
        title
        cover1 { ...PinImageFragment }
        folder { name }
        pins { totalCount }
      }
    }
  }
`

function BoardSelect({ selectedBoardIDs, setSelectedBoardIDs, recentBoards, allBoards }) {
  const [getBoardInfo] = useLazyQuery(BOARD_INFO_QUERY)
  const [selectedBoards, setSelectedBoards] = useState([])
  const filterBoards = (boards, selectedBoardIDs) => {
    let filtered = []
    for (let board of boards) {
      if (!selectedBoardIDs.includes(board.id)) {
        filtered.push(board)
      }
    }

    return filtered
  }

  useEffect(() => {
    const promises = []
    const newBoards = []
    // We get expensive cover, board counts and folder data only for selected board, else everything gets crazy slow
    for (let i=0; i < allBoards.length; i++) {
      const board = allBoards[i]
      if (selectedBoardIDs.includes(board.id)) {
        promises.push(
          getBoardInfo({ variables: { boardID: board.id }}).then((value) => {
            newBoards[i] = value.data.board
          })
        )
      }
    }
    Promise.all(promises).then(() => {
      setSelectedBoards(newBoards)
    })
  }, [selectedBoardIDs, allBoards, getBoardInfo])

  const visibleAllBoards = useMemo(() => filterBoards(allBoards, selectedBoardIDs), [selectedBoardIDs, allBoards])

  const visibleRecentBoards = useMemo(() => filterBoards(recentBoards, selectedBoardIDs), [selectedBoardIDs, recentBoards])

  const handleSelectBoard = (e) => {
    if (selectedBoardIDs.includes(e.target.value)) return
    setSelectedBoardIDs([...selectedBoardIDs, e.target.value])
  }

  const handleUnselectBoard = (e, boardID) => {
    e.preventDefault()
    const index = selectedBoardIDs.indexOf(boardID)
    if (index === -1) return
    const nextIDs = selectedBoardIDs.slice()
    nextIDs.splice(index, 1)
    setSelectedBoardIDs(nextIDs)
  }

  return (
    <>
      {selectedBoards.length > 0 ? (
        <div className={styles.boardsList}>
          {selectedBoards.map(board =>  
            <BoardListItem
              key={board.id}
              pinID={board.id}
              cover={board.cover1}
              title={board.title}
              folderName={board.folder?.name}
              pinsCount={board.pins?.totalCount}
              handleUnselectBoard={handleUnselectBoard}
            />
          )}
        </div>
      ) : null}
      <select
        id="id-boards"
        name="board-select"
        value=""
        className={`plusSelect ${styles.boardSelect}`}
        onChange={handleSelectBoard}
      >
        <option disabled value="">Pin on board...</option>
        <hr />
        <optgroup label="Recent boards">
          {visibleRecentBoards.length === 0 ? <option disabled>No recent boards...</option>: null}
          {visibleRecentBoards.map(board =>
            <BoardOption board={board} key={board.id} />
          )}
        </optgroup>
        <hr />
        <optgroup label="All boards">
          {visibleAllBoards.length === 0 ? <option disabled>No boards...</option>: null}
          {visibleAllBoards.map(board =>
            <BoardOption board={board} key={board.id} />
          )}
        </optgroup>
      </select>
    </>
  )
}

export default BoardSelect


BoardSelect.propTypes = {
  selectedBoardIDs: PropTypes.array.isRequired,
  setSelectedBoardIDs: PropTypes.func.isRequired,
  recentBoards: PropTypes.array.isRequired,
  allBoards: PropTypes.array.isRequired,
}

const BoardOption = ({ board }) => {
  return (
    <option
      value={board.id}
    >{board.title}{board.folder ? `\u2002\u2022\u2002in "${board.folder.name}"` : null}</option>
  )
}

const BoardListItem = ({ pinID, cover, title, folderName, pinsCount, handleUnselectBoard }) => {
  const folderText = folderName ? `In "${folderName}"\u2002\u2022\u2002` : ""
  const pinsCountText = `${pinsCount} ${pinsCount === 1 ? "pin" : "pins"}`
  return (
    <div className={styles.boardListItemWrapper}>
      <div className={styles.boardListItem}>
        <MinusButton
          onClick={(e) => handleUnselectBoard(e, pinID)}
          title="Unpin from this board"
          text="Unpin from this board"
        />
        <div className={`borderedImgWrapper ${styles.imgWrapper}`}>
          {cover ? (
            <PinImg pinID={cover.id} alt={title} minWidth={48} minHeight={48} />) : null}
        </div>
        <div className={styles.textWrapper}>
            <div className={styles.mainline}>{title}</div>
            <div className={styles.subline}>{folderText}{pinsCountText}</div>
        </div>
      </div>
      <hr className={styles.paddedHr} />
    </div>
  )
}