import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { listsHaveCommon } from 'helpers/CommonHelpers'

import PinUploaderItem from 'components/pin/pinUploaderItem/PinUploaderItem'
import Button from 'components/forms/button/Button'

import styles from './pinUploader.module.css'

const VALID_DROPS = ["Files"]
const IMAGE_TYPE = /image.*/

const dropValid = (types) => {
  /*
   * Returns true if drag object is valid for basket
   */
  // we convert to a list because firefox convert types to a "DomStringList"
  // which mades everything fail
  types = Array.prototype.slice.call(types, 0)
  return listsHaveCommon(types, VALID_DROPS)
}



function PinUploader(props) {
  
  const [dragover, setDragover] = useState(false)

  const handleAddPictureClick = (e) => {
    /*
     * Emulate click on file input when button
     * is clicked to hide ugly input and open browser
     * file selection window
     */
    e.preventDefault()
    let input = document.getElementById('uploader-input')
    input.click()
  }

  const handleAddFile = (file) => {
    /*
     * Add given file to list
     */
    console.log('file', file)
    if (! file.type.match(IMAGE_TYPE)) return
    file.addedAt = Date.now()
    props.setFile(file)
  }

  const handleDrop = (e) => {
    console.log('files', e.dataTransfer.files)
    if (dropValid(e.dataTransfer.types)) {
      e.preventDefault()
      setDragover(false)
      handleAddFile(e.dataTransfer.files[0])
    }
  }

  const handleDragEnter = (e) => {
    if (dropValid(e.dataTransfer.types)) {
      e.preventDefault()
      setDragover(true)
    }
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setDragover(false)
  }

  const handleDragOver = (e) => {
    if (dropValid(e.dataTransfer.types)) {
      e.preventDefault()
      e.dataTransfer.dropEffect = "copy"
    }
  }

  const handleInputChange = (e) => {
    handleAddFile(e.target.files[0])
  }


  const getClasses = () => {
    let classes = [styles.uploader]
    if (dragover) {
      classes.push(styles.dragover)
    }
    return classes.join(" ")
  }

  return (
        <div
          className={getClasses()}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          title="You can add a picture's file here by drag and drop or clicking on 'Add picture button'. Click 'upload' button when it's ok."
        >
          <div className={styles.itemsWrapper}>
            { props.file ? (
            <PinUploaderItem
              file={props.file}
              remove={() => props.setFile(null)}
            />) : null}
          </div>
          {props.file ? null : (
            <>
              <p className={styles.helper}>Drag a picture here or click "Add picture" button.</p>
              <Button
                primary={true}
                onClick={handleAddPictureClick}
              >Add picture</Button>
              <input
                id="uploader-input"
                type="file"
                style={{display: "none"}}
                onChange={handleInputChange}
              />
            </>
          )}
        </div>
  )
}


PinUploader.propTypes = {
  setFile: PropTypes.func.isRequired,
  file: PropTypes.object,
}


export default PinUploader


