import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import useQueryString from 'hooks/useQueryString'

import FieldWrapper from 'components/forms/fieldWrapper/FieldWrapper'
import Submit from 'components/forms/submit/Submit'
import LoginForm from 'components/user/loginForm/LoginForm'
import Spinner from 'components/common/spinner/Spinner'
import ModalSection from 'components/common/modalSection/ModalSection'
import ModalHeader from 'components/common/modalHeader/ModalHeader'
import ModalContent from 'components/common/modalContent/ModalContent'
import ModalFooter from 'components/common/modalFooter/ModalFooter'
import RedirectHomeIfAuthenticated from 'components/common/redirectHomeIfAuthenticated/RedirectHomeIfAuthenticated'

const LOGIN_FORM = 'LOGIN_FORM'


const LOGIN_MUTATION = gql`
mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    __typename
    id
    username
    email
    emailVerified
    lastLoginAt
    lastName
    firstName
    websiteLink
    facebookLink
    flickrLink
    twitterLink
    px500Link
    googleplusLink
    pinterestLink
    vkontakteLink
    instagramLink
  }
}
`



function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const queryString = useQueryString()
  const [login, { loading, error, client }] = useMutation(LOGIN_MUTATION, {
    onCompleted: (data) => {
      // We reset store
      client.resetStore()
      // We redirect to next querystring or user home page after success login
      let next = queryString.get("next") || `/${data.login.username}/`
      //console.log('next', next)
      navigate(next)
    },
    onError: (e) => { console.error(e) },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    login({ variables: { email, password } })
  }

  
  return (
    <RedirectHomeIfAuthenticated>
      <ModalSection>
        <ModalHeader
          title="Log into PinPict"
        />
        <ModalContent>
          <LoginForm
            form={LOGIN_FORM}
            onSubmit={handleSubmit}
            handleEmailChange={(e) => setEmail(e.target.value)}
            handlePasswordChange={(e) => setPassword(e.target.value)}
            email={email}
            password={password}
            error={error}
          />
        </ModalContent>
        <ModalFooter>
          {loading && <Spinner message="Logging in..." />}
          <FieldWrapper>
            <Submit
              primary={true}
              form={LOGIN_FORM}
              value="Log in"
            />
          </FieldWrapper>
          <p>No account yet? <Link to="/signup">Sign up</Link></p>
          <p>Loosed your password? <Link to="/start_password_reset">It's here</Link></p>
        </ModalFooter>
      </ModalSection>
    </RedirectHomeIfAuthenticated>
  )
}

export default Login
