import PropTypes from 'prop-types'
import AccessibilityText from 'components/common/accessibilityText/AccessibilityText'

function MinusButton({ onClick, text, title, className }) {
  return (
    <button
      title={title}
      className={`minusButton ${className}`}
      onClick={onClick}
    >
      <AccessibilityText text={text} />
    </button>
  )
}

MinusButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
}

export default MinusButton