import PropTypes from 'prop-types'

import styles from './modalHeader.module.css'

import CancelButton from 'components/forms/cancelButton/CancelButton'
  
function ModalHeader(props) {
  return (
    <header
      className={styles.modalHeader}
    >
      {props.close ?
        <CancelButton
          onClick={props.close}
          title={props.closeTitle}
        /> : null}
      <h1>{props.title}</h1>
    </header>
  )
}

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  closeTitle: PropTypes.string,
  close: PropTypes.func,
}

export default ModalHeader
