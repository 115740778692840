import PropTypes from 'prop-types'
import { modalStatusVar, modalTypes } from 'modal'

function LogoutButton(props) {
  const handleClick = () => {
    modalStatusVar({
      type: modalTypes.LOGOUT,
    })
    
    if (props.onClick) props.onClick() // closing popup
  }

  return (
    <button
      title={props.title || ""}
      className={props.className}
      onClick={handleClick}
    >{props.text || "Sign out"}</button>
  )
}

LogoutButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
}


export default LogoutButton