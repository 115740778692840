
import {
  useQuery,
  gql,
} from '@apollo/client'
import { useParams } from 'react-router-dom'

import styles from 'components/board/userBoards/userBoards.module.css'

import { PIN_IMAGE_FRAGMENT } from 'components/pin/pinPicture/fragments'
import ErrorMessage from 'components/common/errorMessage/ErrorMessage'
import Spinner from 'components/common/spinner/Spinner'
import FoldersList from 'components/folder/foldersList/FoldersList'
import PrivateFoldersWrapper from 'components/folder/privateFoldersWrapper/PrivateFoldersWrapper'

const USER_FOLDERS_QUERY = gql`
  ${PIN_IMAGE_FRAGMENT}
  query UserFoldersQuery($username: String!) {
    me {
      id
    }
    user(username: $username) {
      id
      folders {
        edges {
          id
          secret
          slug
          name
          cover1 { ...PinImageFragment }
          cover2 { ...PinImageFragment }
          cover3 { ...PinImageFragment }
          cover4 { ...PinImageFragment }
          boards { totalCount }
        }
      }
    }
  }
`

function UserFolders() {
  let { username } = useParams()
  const { loading, data, error } = useQuery(USER_FOLDERS_QUERY, {
    variables: { username },
  })

  if (loading) return <Spinner />
  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }
  //console.log('UserFolders', data)
  let owner = !!data.me && data.me.id === data.user.id

  return (
    <>
      <section className={styles.boardsSection + " columned"}>
        <FoldersList
          folders={data.user.folders.edges.filter(node => ! node.secret)}
          owner={owner}
        />
      </section>
      <PrivateFoldersWrapper
        folders={data.user.folders.edges.filter(node => node.secret)}
        owner={owner}
      />
    </>
  )

}

export default UserFolders
