import PropTypes from 'prop-types'

import { Link, useLocation } from 'react-router-dom'

import PinImg from 'components/pin/pinImg/PinImg'
import styles from 'components/board/boardAbstract/boardAbstract.module.css'

function FolderAbstract(props) {
  //console.log('FolderAbstract', props)

  const { pathname } = useLocation()
  const link = `${pathname}${props.slug}/`
  const boardsCount = `${props.boardsCount} ${props.boardsCount === 1 ? "Board" : "Boards"}`
  
  return (
    <article className={styles.folder}>
      <Link
        to={link}
        title={`More from ${props.name}`}
      >
        <header>
          <h1>{props.name}</h1>
          <div className={styles.headerSubline}>{boardsCount}</div>
        </header>
        <div className={styles.folderWrapper}>
          <ul>
            <li className="borderedImgWrapper">
              {props.cover1 ? (
                <PinImg
                  pinID={props.cover1.id}
                  minWidth={104}
                  minHeight={104}
                  alt={props.name}
                />
              ) : null}
            </li>
            <li className="borderedImgWrapper">
              {props.cover2 ? (
                <PinImg
                  pinID={props.cover2.id}
                  minWidth={104}
                  minHeight={104}
                  alt={props.name}
                />
              ) : null}
            </li>
            <li className="borderedImgWrapper">
              {props.cover3 ? (
                <PinImg
                  pinID={props.cover3.id}
                  minWidth={104}
                  minHeight={104}
                  alt={props.name}
                />
              ) : null}
            </li>
            <li className="borderedImgWrapper">
              {props.cover4 ? (
                <PinImg
                  pinID={props.cover4.id}
                  minWidth={104}
                  minHeight={104}
                  alt={props.name}
                />
              ) : null}
            </li>
          </ul>
          <div
            className={styles.label + " " + styles.boardsCount}
          >{boardsCount}</div>
        </div>
      </Link>
    </article>
  )
}

FolderAbstract.propTypes = {
  owner: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  secret: PropTypes.bool.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cover1: PropTypes.object,
  cover2: PropTypes.object,
  cover3: PropTypes.object,
  cover4: PropTypes.object,
  boardsCount: PropTypes.number.isRequired,
}

export default FolderAbstract
