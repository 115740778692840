import PropTypes from 'prop-types'

import CreatePinButton from 'components/pin/createPinButton/CreatePinButton'
import UpdateBoardButton from 'components/board/updateBoardButton/UpdateBoardButton'
import DeleteBoardButton from 'components/board/deleteBoardButton/DeleteBoardButton'

import styles from 'components/common/popupMenuWrapper/popupMenuWrapper.module.css'

function BoardDetailMenu({ boardID, handleOrderClick, backLink }) {
  return (
    <ul>
      <li><CreatePinButton
        text="New pin on that board"
        boardID={boardID}
      /></li>
      <hr className={styles.textPaddedHr} />
      <li><button
        onClick={handleOrderClick}
      >Order pins</button></li>
      <hr className={styles.textPaddedHr} />
      <li><UpdateBoardButton 
        boardID={boardID}
      /></li>
      <hr />
      <li><DeleteBoardButton
        boardID={boardID}
        backLink={backLink}
      /></li>
    </ul>
  )
}

BoardDetailMenu.protoTypes = {
  boardID: PropTypes.string.isRequired,
  handleOrderClick: PropTypes.func.isRequired,
  backLink: PropTypes.string,
}

export default BoardDetailMenu