
import PropTypes from 'prop-types'

import BoardAbstract from 'components/board/boardAbstract/BoardAbstract'
import NoBoards from 'components/board/noBoards/NoBoards'


function BoardsList(props) {
  //console.log('BoardsList', props)
  return (
    <>
      {props.boards.length === 0 ? 
        <NoBoards
          private={props.private || props.folderPrivate}
          folderID={props.folderID}
          owner={props.owner}
        />
      : null}
      {props.boards.map(board =>
        (<BoardAbstract
          owner={props.owner}
          key={board.id}
          pinsCount={board.pins.totalCount}
          baseLink={props.baseLink}
          {...board}
        />)
      )}
    </>
  )
}

BoardsList.propTypes = {
  boards: PropTypes.array.isRequired,
  owner: PropTypes.bool.isRequired,
  private: PropTypes.bool,
  folderID: PropTypes.string,
  folderPrivate: PropTypes.bool,
  baseLink: PropTypes.string.isRequired,
}

export default BoardsList
