import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import useQueryString from 'hooks/useQueryString'

import styles from './pinAbstract.module.css'

import PinAbstractRating from 'components/pin/pinAbstractRating/PinAbstractRating'
import UpdatePinButton from 'components/pin/updatePinButton/UpdatePinButton'
import PinItButton from 'components/pin/pinItButton/PinItButton'
import PinPicture from 'components/pin/pinPicture/PinPicture'


function PinAbstract(props) {
  //console.log('PinAbstract', props)
  const query = useQueryString().toString()
  let link = `${props.baseLink}pins/${props.id}/`
  if (query) {
    link += `?${query}`
  }

  return (
    <article
      className={styles.pin}
      title={`Original size: ${props.originalWidth}px × ${props.originalHeight}px`}
    >
      <div className={styles.mainWrapper}>
        <Link to={link}>
          <PinPicture
            pinID={props.id}
            minWidth={236}
            alt={props.caption}
          />
        </Link>
        <div className={styles.buttonWrapper}>
          <PinItButton
            pinID={props.id}
            owner={props.owner}
            className={`button primary ${styles.headerButton}`}
          />
          {props.owner ? (
            <UpdatePinButton
              pinID={props.id}
              className={`button primary ${styles.headerButton}`}
            />
          ): null}
        </div>
        <PinAbstractRating
          owner={props.owner}
          pinID={props.id}
          rating={props.rating}
        />
      </div>
    </article>
  )
}

PinAbstract.propTypes = {
  owner: PropTypes.bool,
  id: PropTypes.string.isRequired,
  originalWidth: PropTypes.number.isRequired,
  originalHeight: PropTypes.number.isRequired,
  caption: PropTypes.string.isRequired,
  rating: PropTypes.number,
  baseLink: PropTypes.string.isRequired,
}

export default PinAbstract
