import PropTypes from 'prop-types'
import {
  useQuery,
  gql,
} from '@apollo/client'

import styles from './userDetail.module.css'

import Spinner from 'components/common/spinner/Spinner'
import UserWebsite from 'components/user/userWebsite/UserWebsite'
import UserSocialsLinks from 'components/user/userSocialsLinks/UserSocialsLinks'
import UserNav from 'components/user/userNav/UserNav'
import UserAvatar from 'components/user/userAvatar/UserAvatar'

const USER_DETAIL_QUERY = gql`
  query UserDetailQuery($username: String!) {
    me {
      id
    }
    user(username: $username) {
      id
      username
      lastName
      firstName
      websiteLink
      facebookLink
      flickrLink
      twitterLink
      px500Link
      googleplusLink
      pinterestLink
      vkontakteLink
      instagramLink
      pins {
        totalCount
      }
      boards {
        totalCount
      }
      folders {
        totalCount
      }
    }
  }
`

export function getUserNames(user) {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`
  }
  if (user.firstName) return user.firstName
  return user.username
}

export function getUserShortName(user) {
  if (user.firstName) return user.firstName
  return user.username
}

function UserDetail(props) {

  const { loading, data, error } = useQuery(USER_DETAIL_QUERY, {
    variables: { 
      username: props.username,
    },
  })

  if (loading) return <Spinner />
  if (error) console.error(error)

  //console.log('UserDetail', data)

  return (
    <>
      <div className={styles.userDetail}>
        <div className={styles.userInfo}>
          <UserAvatar
            className={styles.avatar}
            username={data.user.username}
            minSize={60}
          />
          <div className={styles.nameWrapper}>
            <h1>{getUserNames(data.user)}</h1>
            <UserWebsite websiteLink={data.user.websiteLink} />
            <UserSocialsLinks
              className={styles.userSocialsLinks}
              facebookLink={data.user.facebookLink}
              flickrLink={data.user.flickrLink}
              twitterLink={data.user.twitterLink}
              px500Link={data.user.px500Link}
              googleplusLink={data.user.googleplusLink}
              pinterestLink={data.user.pinterestLink}
              vkontakteLink={data.user.vkontakteLink}
              instagramLink={data.user.instagramLink}
            />
          </div>
        </div>
      </div>
      <UserNav
        username={data.user.username}
        boardsNumber={data.user.boards.totalCount}
        pinsNumber={data.user.pins.totalCount}
        foldersNumber={data.user.folders.totalCount}
      />
    </>
  )
}


UserDetail.propTypes = {
  username: PropTypes.string.isRequired,
}

export default UserDetail
