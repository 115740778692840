
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  gql,
  useQuery,
} from '@apollo/client'
import { 
  humanDate,
  humanSize,
  humanExposureTime,
} from 'helpers/CommonHelpers'

import ErrorMessage from 'components/common/errorMessage/ErrorMessage'
import Spinner from 'components/common/spinner/Spinner'


import styles from './pinInfo.module.css'
import mainStyles from 'components/pin/pinDetail/pinDetail.module.css'

const PIN_INFO_QUERY = gql`
  query PinInfoQuery($pinID: ID!) {
    pin(id: $pinID) {
      id
      createdAt
      height
      width
      weight
      camera
      lens
      focalLength
      exposureTime
      aperture
      iso
      exifDate
    }
 } 
`

function PinInfo({ pinID }) {

  const { loading, data, error } = useQuery(PIN_INFO_QUERY, {
    variables: { pinID }
  })


  if (loading) return <Spinner />
  if (error) {
    return <ErrorMessage error={error} />
  }
  
  const pin = data.pin

  return (
    <article className={mainStyles.card}>
      <header className={mainStyles.cardHeader}>
        <h5>Image Metadata</h5>
      </header>
      <div className={styles.infoList}>
        <Exposure 
          exposureTime={pin.exposureTime}
          aperture={pin.aperture}
          iso={pin.iso}
        />
        <hr />
        <div>{pin.camera || (<em>No camera information</em>)}</div>
        <hr className={styles.paddedHr} />
        <div>{pin.lens || (<em>No lens information</em>)}
          {pin.focalLength ? ' (' + pin.focalLength + ' mm)' : ""}</div>
        <hr className={styles.paddedHr} />
        <div>{pin.width} × {pin.height} px{"\u2002\u2022\u2002"}{humanSize(pin.weight)}</div>
        <hr />
        <div className={styles.exifDate}>{pin.exifDate ?  humanDate(pin.exifDate) : (<em>No date information</em>)}</div>
      </div>
      <footer className={mainStyles.cardFooter}>
        <span>Added</span>{moment(pin.createdAt, 'x').fromNow()}
      </footer>
    </article>
  )
}

export default PinInfo


PinInfo.propTypes = {
  pinID: PropTypes.string.isRequired,
}


function Exposure({ exposureTime, aperture, iso }) {
  if (!exposureTime && !aperture && !iso) {
    return (<div className={styles.exposure}><em>No exposure information</em></div>)
  }
  return (
    <div className={styles.exposure}>
      {exposureTime ? (<span>{humanExposureTime(exposureTime)} sec</span>) : null}
      {aperture ? (<span><span className={styles.fnum}>{"\u{1D453}"}</span>/{aperture}</span>) : null}
      {iso ? (<span>ISO {iso}</span>) : null}
    </div>
  )
}