
import PropTypes from 'prop-types'

import FolderAbstract from 'components/folder/folderAbstract/FolderAbstract'
import NoFolders from 'components/folder/noFolders/NoFolders'


function FoldersList(props) {
  //console.log('FoldersList', props)
  return (
    <>
      {props.folders.length === 0 ? 
        <NoFolders 
          private={props.private}
          owner={props.owner}
        />
      : null}
      {props.folders.map(folder =>
        (<FolderAbstract
          owner={props.owner}
          key={folder.id}
          boardsCount={folder.boards.totalCount}
          {...folder}
        />)
      )}
    </>
  )
}

FoldersList.propTypes = {
  folders: PropTypes.array.isRequired,
  owner: PropTypes.bool.isRequired,
  private: PropTypes.bool,
}

export default FoldersList
