import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation } from '@apollo/client'

import withModal from 'hoc/withModal/withModal'

import ModalSection from 'components/common/modalSection/ModalSection'
import ModalHeader from 'components/common/modalHeader/ModalHeader'
import ModalContent from 'components/common/modalContent/ModalContent'
import ModalFooter from 'components/common/modalFooter/ModalFooter'
import UpdatePasswordForm from 'components/user/updatePasswordForm/UpdatePasswordForm'
import Submit from 'components/forms/submit/Submit'
import Spinner from 'components/common/spinner/Spinner'

const UPDATE_PASSWORD_FORM = 'UPDATE_PASSWORD_FORM'


const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdateUserPassword(
    $currentPassword: String!, 
    $newPassword: String!
  ) {
    updateUserPassword(
      currentPassword: $currentPassword,
      newPassword: $newPassword
    ) {
      id
    }
  }
`

function UpdatePassword(props) {
  const [localErrors, setLocalErrors] = useState(null)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
  const [updatePassword, { loading, error }] = useMutation(UPDATE_PASSWORD_MUTATION, {
    onCompleted: () => props.closeModal(),
    onError: (e) => console.error(e),
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    // we reset local errors
    setLocalErrors(null)
    let localErrors = []
    if (newPassword !== newPasswordConfirm) {
      localErrors.push("New password and new password confirmation must match!")
    }
    if (localErrors.length === 0) {
      updatePassword({ variables: { currentPassword, newPassword } })
    } else { setLocalErrors(localErrors) }
  }

  return (
    <ModalSection>
      <ModalHeader
        title="Change password"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <UpdatePasswordForm
          form={UPDATE_PASSWORD_FORM}
          onSubmit={handleSubmit}
          handleCurrentPasswordChange={(e) => setCurrentPassword(e.target.value)}
          handleNewPasswordChange={(e) => setNewPassword(e.target.value)}
          handleNewPasswordConfirmChange={(e) => setNewPasswordConfirm(e.target.value)}
          currentPassword={currentPassword}
          newPassword={newPassword}
          newPasswordConfirm={newPasswordConfirm}
          error={error}
          localErrors={localErrors}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Changing password..." />}
        <Submit
          primary={true}
          form={UPDATE_PASSWORD_FORM}
          value="Save"
        />
      </ModalFooter>
    </ModalSection>
  )
}


UpdatePassword.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}

export default withModal(UpdatePassword)













