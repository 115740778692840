import {
  useQuery,
  gql,
} from '@apollo/client'
import { useParams, Link } from 'react-router-dom'

import styles from 'components/board/boardDetail/boardDetail.module.css'

import { PIN_IMAGE_FRAGMENT } from 'components/pin/pinPicture/fragments'
import Header from 'components/common/header/Header'
import BoardsList from 'components/board/boardsList/BoardsList'
import ErrorMessage from 'components/common/errorMessage/ErrorMessage'
import Spinner from 'components/common/spinner/Spinner'
import NavigationBar from 'components/common/navigationBar/NavigationBar'
import NavigationMenuButton from 'components/common/navigationMenuButton/NavigationMenuButton'
import FolderDetailMenu from 'components/folder/folderDetailMenu/FolderDetailMenu'

import { getUserNames } from 'components/user/userDetail/UserDetail'


const FOLDER_DETAIL_QUERY = gql`
  ${PIN_IMAGE_FRAGMENT}
  query FolderDetailQuery(
    $username: String!,
    $folderSlug: String!,
  ) {
    me {
      id
    }
    user(username: $username) {
      id
      username
      lastName
      firstName
      folder(slug: $folderSlug) {
        id
        name
        secret
        boards {
          totalCount
          edges {
            id
            secret
            slug
            title
            caption
            pins { totalCount }
            cover1 { ...PinImageFragment }
            cover2 { ...PinImageFragment }
            cover3 { ...PinImageFragment }
            cover4 { ...PinImageFragment }
            cover5 { ...PinImageFragment }
          }
        }
      }
    }
  }
`


function FolderDetail() {
  let { username, folderSlug } = useParams()
  const { loading, data, error } = useQuery(FOLDER_DETAIL_QUERY, {
    variables: { 
      username,
      folderSlug,
    }
  })
  //console.log('FolderDetail', username, folderSlug, data, error)

  if (loading) return <Spinner />
  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }
  const owner = !!data.me && data.me.id === data.user.id
  const folder = data.user.folder
  const backLink = `/${username}/folders/`

  return (
    <>
      <Header>
        <NavigationBar
          backLink={backLink}
        >
          {owner ? (
            <NavigationMenuButton>
              <FolderDetailMenu
                folderID={folder.id}
                folderPrivate={folder.secret}
                backLink={backLink}
              />
            </NavigationMenuButton>
          ) : null}
        </NavigationBar>
      </Header>
      <section id="content">
        <header className={styles.header}>
          <h1>{folder.name}</h1>
          <p className={styles.user}>by <Link to={`/${username}`}>{getUserNames(data.user)}</Link></p>
          <p 
            className={styles.pinCount}
          >{folder.boards.totalCount} {folder.boards.totalCount === 1 ? " board" : " boards"}</p>
          {folder.secret ? <p>This folder is private</p> : null}
        </header>
        <section className={styles.boardsSection + " columned"}>
          <BoardsList
            boards={folder.boards.edges}
            owner={owner}
            folderID={folder.id}
            folderPrivate={folder.secret}
            baseLink={`/${username}/folders/${folderSlug}/`}
          />
        </section>
      </section>
    </>
  )
}


export default FolderDetail
