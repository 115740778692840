import PropTypes from 'prop-types'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'

function UpdateFolderButton(props) {
  const handleClick = () => {
    modalStatusVar({
      type: modalTypes.UPDATE_FOLDER,
      options: { folderID: props.folderID }
    })
  }

  return (
    <button
      title={props.title || ""}
      className={props.className}
      onClick={handleClick}
    >{props.text || "Edit folder"}</button>
  )
}

UpdateFolderButton.propTypes = {
  folderID: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
}

export default UpdateFolderButton
