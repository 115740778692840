//import PropTypes from 'prop-types'
import { forwardRef } from 'react'

import styles from './autoHeightTextArea.module.css'

const AutoHeightTextArea = forwardRef(function AutoHeightTextArea({ value, onChange, placeholder, wrapperClassName, textAreaClassName }, ref) {
  /*
   * As nothing allows for a textarea to grow with its content
   * and using contentEditable div sucks with react
   * We simply display a div with same properties and content as textArea under it
   * and we absolute position textArea over it
   * caveat: textAreaClassname mustn't contain any margin 
   */
  return (
    <div className={`${styles.wrapper} ${wrapperClassName}`}>
      <div className={`${styles.spring} ${textAreaClassName}`} aria-hidden>
        {value}
        <br />{/* chrome is adding this br to textarea end, so we need it else one line is missing. */}
      </div>
      <textarea
        ref={ref}
        className={textAreaClassName}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    </div>
  )
})

/* Not supported by forwardRef
AutoHeightTextArea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  textAreaClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
}
*/



export default AutoHeightTextArea