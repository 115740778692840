import React, { useState } from 'react'
import {
  useQuery,
  useMutation,
  gql,
} from '@apollo/client'
import { cache } from 'index'
import { useParams, Link } from 'react-router-dom'
import { NetworkStatus } from '@apollo/client'
import { deleteField } from 'helpers/GraphqlHelpers'

import styles from './boardDetail.module.css'

import Header from 'components/common/header/Header'
import OrderPinsList from 'components/pin/orderPinsList/OrderPinsList'
import PinsList from 'components/pin/pinsList/PinsList'
import ErrorMessage from 'components/common/errorMessage/ErrorMessage'
import Spinner from 'components/common/spinner/Spinner'
import NavigationBar from 'components/common/navigationBar/NavigationBar'
import NavigationBarButton from 'components/common/navigationBarButton/NavigationBarButton'
import NavigationMenuButton from 'components/common/navigationMenuButton/NavigationMenuButton'
import BoardDetailMenu from 'components/board/boardDetailMenu/BoardDetailMenu'
import BoardSharedWith from 'components/board/boardSharedWith/BoardSharedWith'

import { getUserNames } from 'components/user/userDetail/UserDetail'

const BOARD_DETAIL_QUERY = gql`
  query BoardDetailQuery(
    $username: String!,
    $boardSlug: String!,
    $first: Int,
    $after: String,
  ) {
    me {
      id
    }
    user(username: $username) {
      id
      username
      lastName
      firstName
      board(slug: $boardSlug) {
        id
        title
        caption
        secret
        pins(first: $first, after: $after) {
          totalCount
          edges {
            id
            order
            node {
              id
              caption
              rating
              width
              height
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
`

const ORDER_PIN_MUTATION = gql`
mutation OrderPinInBoard($pinID: ID!, $boardID: ID!, $order: Int!) {
  orderPinInBoard(pin: $pinID, board: $boardID, order: $order) {
    __typename
    id
    order
  }
}
`


function BoardDetail() {
  let { username, folderSlug, boardSlug } = useParams()
  const [ordering, setOrdering] = useState(false)
  const [orderedPins, setOrderedPins] = useState([])
  
  const { data, error, fetchMore, networkStatus } = useQuery(BOARD_DETAIL_QUERY, {
    variables: { 
      username,
      boardSlug,
      first: 50,
      after: null,
    },
  })

  const [orderPin] = useMutation(ORDER_PIN_MUTATION, {
    onError: (e) => console.error(e)
  })

  if (networkStatus === NetworkStatus.loading) return <Spinner />
  if (error) {
    return <ErrorMessage error={error} />
  }
  let owner = !!data.me && data.me.id === data.user.id
  let board = data.user.board

  const getBackLink = () => {
    if (username && folderSlug) {
      return `/${username}/folders/${folderSlug}/`
    }
    return `/${username}/boards/`
  }

  const getBaseLink = () => {
    if (username && folderSlug) {
      return `/${username}/folders/${folderSlug}/boards/${boardSlug}/`
    }
    return `/${username}/boards/${boardSlug}/`
  }

  const loadMore = () => {
    //console.log('Load more after', board.pins.pageInfo.endCursor)
    return fetchMore({
      variables: { 
        after: board.pins.pageInfo.endCursor,
      },
      notifyOnNetworkStatusChange: true
    })
  }

  const handleOrderClick = () => {
    if (! ordering) {
      setOrderedPins(board.pins.edges.slice())
      setOrdering(true)
    } else {
      // grab smallest order number
      const minOrder = Math.min(...orderedPins.map(pin => pin.order))
      // highest order is first in array, so we start from highest
      let order = minOrder + (orderedPins.length - 1) 
      for (let pin of orderedPins) {
        if (pin.order !== order) {
          orderPin({variables: {
            pinID: pin.node.id,
            boardID: board.id,
            order: order,
          }})
        }
        order--
      }
      // Update cache
      cache.modify({
        id: cache.identify(board),
        fields: {
          pins(cachedPins, { readField }) {
            let orderedIDs = orderedPins.map(pin => pin.id)
            let edges = []
            for (let pinRef of cachedPins.edges) {
              let index = orderedIDs.indexOf(readField('id', pinRef))
              edges[index] = pinRef
            }
            return {
              ...cachedPins,
              edges
            }
          },
          // we delete single pins, because prev / next ids are not good anymore
          pin: deleteField,
        }
      })
      setOrdering(false)
    }
  }

  const backLink = getBackLink()

  return (
    <>
      <Header>
        <NavigationBar
          backLink={backLink}
        >
          {owner ? 
            ordering ? (
              <NavigationBarButton
                text="Done"
                onClick={handleOrderClick}
              />
            ) : (
              <NavigationMenuButton>
                <BoardDetailMenu
                  boardID={board.id}
                  backLink={backLink}
                  handleOrderClick={handleOrderClick}
                />
              </NavigationMenuButton>
            )
          : null}
        </NavigationBar>
      </Header>
      <section id="content">
        <NavigationBar
          backLink={backLink}
        >
          {owner ? 
            ordering ? (
              <NavigationBarButton
                text="Done"
                onClick={handleOrderClick}
              />
            ) : (
              <NavigationMenuButton>
                <BoardDetailMenu
                  boardID={board.id}
                  handleOrderClick={handleOrderClick}
                />
              </NavigationMenuButton>
            )
          : null}
        </NavigationBar>
        <header className={styles.header}>
          <h1>{board.title}</h1>
          <p className={styles.user}>by <Link to={`/${username}`}>{getUserNames(data.user)}</Link></p>
          <p className={styles.description}>{board.caption}</p>
          <p 
            className={styles.pinCount}
          >{board.pins.totalCount} {board.pins.totalCount === 1 ? " pin" : " pins"}</p>
          {board.secret ? <p>This board is private</p> : null}
          {owner ? <BoardSharedWith  boardID={board.id} /> : null}
        </header>
        { ordering ? 
          (
            <OrderPinsList 
              pins={orderedPins}
              setPins={setOrderedPins}
            />
          ) : (
            <PinsList
              pins={board.pins.edges}
              boardID={board.id}
              owner={owner}
              hasNextPage={board.pins.pageInfo.hasNextPage}
              loadMore={loadMore}
              loadingMore={networkStatus === NetworkStatus.fetchMore }
              username={username}
              baseLink={getBaseLink()}
            />
          )
        }
      </section>
    </>
  )
}


export default BoardDetail
