import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import styles from 'components/forms/submit/submit.module.css'

/*
 * A React Router Link that displays as a button
 */

function getClassNames(props) {
  let classes = []

  // we add extra classes
  classes.push(styles.button)
  if (props.primary) { classes.push(styles.primary) }
  if (props.className) { classes.push(props.className) }

  return classes.join(" ")
}

function RouterLinkButton(props) {
  if (! props.to) return null
  return (
    <Link
      to={props.to}
      title={props.title || null}
      className={getClassNames(props)}
    >{props.value}</Link>
  )
}

RouterLinkButton.propTypes = {
  value: PropTypes.string.isRequired,
  to: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  primary: PropTypes.bool,
}

export default RouterLinkButton
