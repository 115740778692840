import PropTypes from 'prop-types'
import { useEffect, useRef, useCallback, useState } from 'react'

import PinsGridItem from 'components/pin/pinsGridItem/PinsGridItem'

import styles from './pinsGrid.module.css'


const GUTTER = 14
const MAX_ITEM_WIDTH = 236

function PinsGrid({ items }) {
  const ref = useRef(null)
  const [grid, setGrid] = useState({})
  const [positions, setPositions] = useState([])
  const [itemWidth, setItemWidth] = useState(0)
  const [containerMinHeight, setContainerMinHeight] = useState(0)

  const computeGrid = useCallback(() => {
    /* Must run at first render and on window resize  */
    //console.log('computeGrid', items)
    
    // we compute number of columns from container width
    const parentWidth = ref.current.clientWidth
    let columnsNumber = Math.floor((parentWidth + GUTTER) / (MAX_ITEM_WIDTH + GUTTER))
    if (columnsNumber === 1) { columnsNumber = 2 } // we always want at least 2 columns
    const itemWidth = Math.floor(parentWidth / columnsNumber) - GUTTER

    // We compute total width of columns + gutters to center them in container
    const leftMargin = Math.floor(
      (parentWidth - ((itemWidth * columnsNumber) + (GUTTER * (columnsNumber - 1)))) / 2
    )

    // We set X position for each column
    const gridX = []
    let n = leftMargin
    for (let i=0; i < columnsNumber; i++) {
      gridX.push(n)
      n += (GUTTER + itemWidth)
    }

    // We initiate each column Y position to gutter to get top gutter
    const gridY = Array(columnsNumber).fill(GUTTER)

    // We save everything in state
    setGrid({
      X: gridX,
      Y: gridY,
    })
    setItemWidth(itemWidth)
  }, [])

  const computePositions = useCallback(() => {
    /* Must run each time gridsize or children changes */
    //console.log('compute positions')
    let newPositions = []
    if (! grid.X || ! grid.Y) return // grid is not set yet
    let columnsHeights = [...grid.Y]

    for (let item of items) {
      const originalHeight = item.props.originalHeight
      if (! originalHeight) {
        console.error('Each item in PinsGrid must have a "originalHeight" prop with it\'s intrinsic height in pixel.')
        return
      }

      const originalWidth = item.props.originalWidth
      if (! originalWidth) {
        console.error('Each item in PinsGrid must have a "originalWidth" prop with it\'s intrinsic width in pixel.')
        return
      }
      const ratio = originalWidth / originalHeight
      const height = Math.floor(itemWidth / ratio)

      // We add item to less tall column
      let position = {}
      let column = columnsHeights.indexOf(Math.min(...columnsHeights))
      position.height = height
      position.X = grid.X[column]
      position.Y = columnsHeights[column]
      columnsHeights[column] += height + GUTTER
      newPositions.push(position)
    }

    // We store positions
    setPositions(newPositions)
    
    // We set container min height
    setContainerMinHeight(Math.max(...columnsHeights))

  }, [items, grid.X, grid.Y, itemWidth])


  useEffect(() => {
    // we compute grid at initial render
    computeGrid()
    // we compute grid at each window resize
    window.addEventListener('resize', computeGrid) 
    return () => window.removeEventListener('resize', computeGrid)
  }, [computeGrid])

  useEffect(() => {
    // We compute position at each changement on items, or grid
    computePositions()
  }, [computePositions])


  return (
    <ul
      className={styles.grid}
      ref={ref}
      style={{ minHeight: `${containerMinHeight}px` }}
    >
      {items.map((item, index) =>
        <PinsGridItem
          key={item.props.id}
          translateX={positions[index] ? positions[index].X : 0}
          translateY={positions[index] ? positions[index].Y : 0}
          width={itemWidth}
          height={positions[index] ? positions[index].height : 0}
          display={positions[index] ? true : false}
        >{item}</PinsGridItem>
      )}
    </ul>
  )
}

PinsGrid.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
}

export default PinsGrid
