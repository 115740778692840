
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import PinsGrid from 'components/pin/pinsGrid/PinsGrid'
import NoPins from 'components/pin/noPins/NoPins'
import PinAbstract from 'components/pin/pinAbstract/PinAbstract'
import LoadingMore from 'components/common/loadingMore/LoadingMore'


function LastPinsList(props) {
  //console.log('LastPinsList', props)
  
  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop
    const scrollHeight = document.documentElement.scrollHeight
    const clientHeight = document.documentElement.clientHeight
    
    if (scrollTop + clientHeight >= scrollHeight) {
      if (props.hasNextPage && ! props.loadingMore) {
        props.loadMore()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })


  return (
    <section className="columned">
      {props.pins.length === 0 ?
        <NoPins owner={false} />
      : null}
      <PinsGrid
        items = {props.pins.map(pin => {
          let owner = pin.node.user.id === props.visitorID
          
          return (
            <PinAbstract
              key={pin.node.id}
              owner={owner}
              id={pin.node.id}
              originalWidth={pin.node.width}
              originalHeight={pin.node.height}
              caption={pin.node.caption}
              rating={pin.node.rating}
              baseLink={props.baseLink}
              queryString={props.queryString}
            />
          )})}
      />
      <LoadingMore
        loadingMore={props.loadingMore}
      />
    </section>
  )
}

LastPinsList.propTypes = {
  pins: PropTypes.array.isRequired,
  visitorID: PropTypes.string,
  hasNextPage: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool.isRequired,
  baseLink: PropTypes.string.isRequired,
  queryString: PropTypes.string,
}

export default LastPinsList
