import { Outlet } from 'react-router-dom'

import Header from 'components/common/header/Header'


const BannerRootLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  )
}


export default BannerRootLayout