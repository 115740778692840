import PropTypes from 'prop-types'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'

function UpdateBoardButton(props) {
  const handleClick = () => {
    modalStatusVar({
      type: modalTypes.UPDATE_BOARD,
      options: { boardID: props.boardID }
    })

  }

  return (
    <button
      title={props.title || ""}
      className={props.className}
      onClick={handleClick}
    >{props.text || "Edit board"}</button>
  )
}

UpdateBoardButton.propTypes = {
  boardID: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
}

export default UpdateBoardButton
