import PropTypes from 'prop-types'

import styles from './loadingMore.module.css'

import Spinner from 'components/common/spinner/Spinner'

function LoadingMore({ loadingMore }) {
  //console.log('LoadingMore', props)
  if (loadingMore) {
    return (
      <footer className={styles.loadingMore}>
        <Spinner />
      </footer>
    )
  }
  return null
}

LoadingMore.propTypes = {
  loadingMore: PropTypes.bool.isRequired,
}

export default LoadingMore
