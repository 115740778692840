import PropTypes from 'prop-types'
import {
  useQuery,
  gql,
  makeVar,
  useReactiveVar,
} from '@apollo/client'

import { PIN_IMAGE_FRAGMENT } from './fragments'
import styles from './pinPicture.module.css'

export const showUnsafeVar = makeVar(false)

export const PIN_IMAGE_QUERY = gql`
  ${PIN_IMAGE_FRAGMENT}
  query PinImageQuery($pinID: ID!) {
    pin(id: $pinID) {
      ...PinImageFragment
    }
  }
`


export function getImageMaxSide(ratio, previewsSizes, minWidth, minHeight) {
  let landscape = ratio >=1
  let minSide = null
  let sizes = previewsSizes
  let maxSide = sizes[sizes.length -1] // we default to biggest

  if (minHeight && minWidth && landscape) { // landscape with both width and height constraints
    minSide = minHeight * ratio > minWidth ?
      minHeight * ratio : minWidth
  }
  else if (! minHeight && landscape) { // landscape with minWidth constraint
    minSide = minWidth
  }
  else if (! minWidth && landscape) { // landscape with minHeight
    minSide = minHeight * ratio
  }
  else if (minHeight && minWidth) { // portrait with box width and height constraints
    minSide = minWidth / ratio > minHeight ?
      minWidth / ratio : minHeight
  }
  else if (minWidth) { // portrait with minWidth constraint
    minSide = minWidth / ratio
  }
  else if (! minWidth) { // portrait with minHeight constraint
    minSide = minHeight
  }

  for (let size of sizes) {
    if (size >= minSide) {
      maxSide = size
      break
    }
  }

  return maxSide
}

export function getImageSrcSet(sha1, ratio, previewsSizes, format) {
  let widths

  // PreviewsSizes stores biggest side, so we extract widths
  if (ratio >= 1) {
    widths = previewsSizes
  } else {
    widths = previewsSizes.map(size => Math.round(size * ratio))
  }

  /*
   * We use 1x 1.5x 2x, so index, index +1 and index +2 for fixed layout
   * We add 0.25x and 0.5x, so index -1 and index -2, for fluid layout
   *
   * was not working, 0.25x isn't necessary index -2.
   * Instead give all sizes to browser, as they are already generated, and let it choose the best
   * no need to maintain a fluid / fixed layout prop too.
   */

  const sources = widths.map((width, index) =>
      `${getImageSrc(sha1, previewsSizes[index], format)} ${width}w`
  )

  return sources.join(', ')
}

function getImageSizes(ratio, minWidth, minHeight) {
  let targetWidth = minWidth || minHeight * ratio

  return `(min-width: ${targetWidth}px) ${targetWidth}px, 100vw`
}

export function getImageSrc(sha1, size, format) {
  /*
   * Images urls construction
   *
   * "/media/previews/{size}/{sha1[0:2]}/{sha1[2:4]}/{sha1}.{format}
   * "/media/originals/{sha1[0:2]}/{sha1[2:4]}/{sha1}
   */

  const dir1 = sha1.substring(0, 2)
  const dir2 = sha1.substring(2, 4)
  return `/media/previews/${dir1}/${dir2}/${sha1}/${sha1}_${size}.${format}`
}



export function getImageIntrinsicSizes(ratio, maxSide) {
  if (ratio >=1) {
    return {
      width: maxSide, 
      height: Math.round(maxSide / ratio),
    }
  }
  return {
    width: Math.round(maxSide * ratio),
    height: maxSide,
  }
}


function PinPicture({ 
  pinID,
  minWidth,
  minHeight,
  alt,
  title,
  draggable,
}) {

  const showUnsafe = useReactiveVar(showUnsafeVar)

  const { loading, data, error } = useQuery(PIN_IMAGE_QUERY, {
    fetchPolicy: 'cache-first',
    variables: { pinID },
  })

  if (loading) {
    return null
  }
  if (error) console.error(error)
 
  const { sha1, previewsSizes, previewsFormats, width, height, unsafe } = data.pin
  const ratio = width / height
  const maxSide = getImageMaxSide(ratio, previewsSizes, minWidth, minHeight)
  const src = getImageSrc(sha1, maxSide, previewsFormats[0])
  const sizes = getImageSizes(ratio, minWidth, minHeight)
  const intrinsicSizes = getImageIntrinsicSizes(ratio, maxSide)
  const hidden = unsafe && ! showUnsafe

  //console.log('render PinPicture', pinID)
  console.log('intrinsic width', intrinsicSizes.width)
  
  return (
    <picture 
      className={styles.picture}
      style={{
        // Never bigger than underlying image
        maxWidth: `${intrinsicSizes.width}px`, 
        maxHeight: `${intrinsicSizes.height}px`, 
      }}
    >
      {previewsFormats.map(format => {
        /* we don't return jpeg here else chrome choose it over webp */
        if (format === "jpeg") return null

        return (<source
          key={format}
          srcSet={getImageSrcSet(sha1, ratio, previewsSizes, format)}
          sizes={sizes}
          type={`image/${format}`}
        />)
      })}
      {hidden ? (
        <div 
          className={styles.forbidden}
          style={{
            // Never bigger than underlying image
            maxWidth: `${intrinsicSizes.width}px`, 
            maxHeight: `${intrinsicSizes.height}px`, 
            aspectRatio: `${intrinsicSizes.width} / ${intrinsicSizes.height}`,
        }}
        ></div>
      ) : (
        <img
          key={pinID /* we force full rerender for each pinID else image
            placeholder changes leaving previous src which does akward layouts. */}
          src={src}
          srcSet={getImageSrcSet(sha1, ratio, previewsSizes, previewsFormats[0])}
          sizes={sizes}
          alt={alt || ""}
          title={title || null}
          height={intrinsicSizes.height}
          width={intrinsicSizes.width}
          draggable={draggable}
          style={{
            aspectRatio: `${intrinsicSizes.width} / ${intrinsicSizes.height}`,
            backgroundColor: data.pin.dominantColor, 
          }}
        />
      )}
    </picture>
  )
}


export default PinPicture

PinPicture.propTypes = {
  pinID: PropTypes.string.isRequired,
  className: PropTypes.string,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  alt: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
  draggable: PropTypes.bool,
}
