import PropTypes from 'prop-types'

import FormRequiredField from 'components/forms/formRequiredField/FormRequiredField'
import FieldWrapper from 'components/forms/fieldWrapper/FieldWrapper'
import FormErrors from 'components/forms/formErrors/FormErrors'


function PasswordResetForm(props) {
  return (
    <form
      id={props.form}
      onSubmit={props.onSubmit}
    >
      <FieldWrapper>
        <label htmlFor="id-token">Token:<FormRequiredField /></label>
        <input
          id="id-token"
          name="token"
          type="text"
          maxLength="255"
          value={props.token}
          onChange={props.handleTokenChange}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-newPassword">New password:<FormRequiredField /></label>
        <input
          id="id-newPassword"
          name="newPassword"
          type="password"
          maxLength="255"
          value={props.newPassword}
          onChange={props.handleNewPasswordChange}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-newPasswordConfirm">New password confirmation:<FormRequiredField /></label>
        <input
          id="id-newPasswordConfirm"
          name="newPasswordConfirm"
          type="password"
          maxLength="255"
          value={props.newPasswordConfirm}
          onChange={props.handleNewPasswordConfirmChange}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <FormErrors
          error={props.error}
          localErrors={props.localErrors}
        />
      </FieldWrapper>
    </form>
  )
}


PasswordResetForm.propTypes = {
  form: PropTypes.string.isRequired,
  token: PropTypes.string,
  newPassword: PropTypes.string,
  newPasswordConfirm: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleTokenChange: PropTypes.func.isRequired,
  handleNewPasswordChange: PropTypes.func.isRequired,
  handleNewPasswordConfirmChange: PropTypes.func.isRequired,
  error: PropTypes.object,
  locolErrors: PropTypes.array,
}


export default PasswordResetForm
