import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { AUTHENTICATED_QUERY } from 'components/common/app/App'


function RedirectHomeIfAuthenticated({ children }) {
  
  const navigate = useNavigate()
  const { loading, data, error } = useQuery(AUTHENTICATED_QUERY)  
  
  if (loading) return null
  if (error) console.error(error)

  if (data.me && data.me.username) {
      navigate(`/${data.me.username}/`)
  }

  return children

}


export default RedirectHomeIfAuthenticated
