import { gql } from '@apollo/client'
import useQueryString from 'hooks/useQueryString'

import SearchPinsByQuery from 'components/pin/searchPinsByQuery/SearchPinsByQuery'
import SearchPinsByTag from 'components/pin/searchPinsByTag/SearchPinsByTag'

import styles from 'components/board/boardDetail/boardDetail.module.css'


export const SEARCH_PINS_QUERY = gql`
  query SearchPinsQuery(
    $search: String,
    $tag: String,
    $first: Int,
    $after: String,
  ) {
    me {
      id
    }
    pins(first: $first, after: $after, tag: $tag, search: $search) {
      totalCount
      edges {
        node {
          id
          caption
          rating
          height
          width
          user {
            id
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

function Search() {
  let queryString = useQueryString()
  let query = queryString.get('query')
  let tag = queryString.get('tag')

  if (query) {
    return (<SearchPinsByQuery query={query} />)
  }
  if (tag) {
    return (<SearchPinsByTag tag={tag} />)
  }
  return (
    <section id="content">
      <header className={styles.header}>
        <h1>Please type a query in search bar...</h1>
      </header>
    </section>
  )

}

export default Search
