import React, { useState } from 'react'
import PropTypes from 'prop-types'

import withModal from 'hoc/withModal/withModal'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'

import ModalSection from 'components/common/modalSection/ModalSection'
import ModalHeader from 'components/common/modalHeader/ModalHeader'
import ModalContent from 'components/common/modalContent/ModalContent'
import ModalFooter from 'components/common/modalFooter/ModalFooter'
import PinUploader from 'components/pin/pinUploader/PinUploader'
import Button from 'components/forms/button/Button'
import Spinner from 'components/common/spinner/Spinner'

const REACT_APP_UPLOAD_ENDPOINT = process.env.REACT_APP_UPLOAD_ENDPOINT

const UPLOAD_PIN_FORM = 'UPLOAD_PIN_FORM'

function UploadPin(props) {
  const [file, setFile] = useState(null)
  const [loadingMessage, setLoadingMessage] = useState(null)


  const handleSubmit = async (e) => {
    e.preventDefault()

    let tempPin
    let clones = null

    //console.log('submit!')
    setLoadingMessage('Uploading file...')
    const fd = new FormData()
    fd.append('pin', file)

    try {
      const response = await fetch(REACT_APP_UPLOAD_ENDPOINT + '/pin', {
        method: 'POST',
        body: fd,
      })
      const data = await response.json()
      
      tempPin = {
        tempFileName: data.tempFileName,
        tempFileUrl: data.tempFileUrl,
        originalName: data.originalName,
      }
      clones = data.clones
      //console.log('uploaded pin', data)
    } catch(e) {
      setLoadingMessage(null)
      console.error(e)
    }

    if (clones) { // we don't add pin
      modalStatusVar({
        type: modalTypes.PIN_CLONES, 
        options: { clones },
      })

      return
    }

    modalStatusVar({
      type: modalTypes.CREATE_PIN,
      options: {
        tempPin,
        ...props.modalOptions, // we pass down existing modalOptions, like default board
      },
    })
  }

  //console.log('UploadPin', props)
  return (
    <ModalSection>
      <ModalHeader
        title="Upload pin from your computer"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <form
          id={UPLOAD_PIN_FORM}
          onSubmit={handleSubmit}
        >
          <PinUploader
            file={file}
            setFile={setFile}
          />
        </form>
      </ModalContent>
      <ModalFooter>
        {loadingMessage && <Spinner message={loadingMessage} />}
        <Button
          type="submit"
          disabled={! Boolean(file)}
          primary={true}
          form={UPLOAD_PIN_FORM}
        >Upload</Button>
      </ModalFooter>
    </ModalSection>
  )
}

UploadPin.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}


export default withModal(UploadPin)


