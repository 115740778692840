import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { gql, useMutation, useQuery } from '@apollo/client'

import withModal from 'hoc/withModal/withModal'

import styles from 'components/pin/createPinForm/createPinForm.module.css'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'

import ModalSection from 'components/common/modalSection/ModalSection'
import ModalHeader from 'components/common/modalHeader/ModalHeader'
import ModalContent from 'components/common/modalContent/ModalContent'
import ModalFooter from 'components/common/modalFooter/ModalFooter'
import CreatePinForm from 'components/pin/createPinForm/CreatePinForm'
import Submit from 'components/forms/submit/Submit'
import Button from 'components/forms/button/Button'
import Spinner from 'components/common/spinner/Spinner'

import { MY_BOARDS_FRAGMENT } from './fragments'
import { 
  addPinToBoardUpdate,
  createPinUpdate,
} from 'helpers/GraphqlHelpers'

const CREATE_PIN_FORM = 'CREATE_PIN_FORM'



function getRecentBoardIDs() {
  const recentBoardIDs = localStorage.getItem('recentBoardIDs')
  if (recentBoardIDs) return recentBoardIDs.split(';')
  return []
}

export function setRecentBoardIDs(boardIDs) {
  let recentBoardIDs = getRecentBoardIDs()
  let newBoardIDs = [...boardIDs]

  for (let boardID of recentBoardIDs) {
    // we keep only 20 items
    if (!newBoardIDs.includes(boardID) && newBoardIDs.length < 20) {
      newBoardIDs.push(boardID)
    }
  }

  localStorage.setItem('recentBoardIDs', newBoardIDs.join(';'))
}

export function getRecentBoards(allBoards) {
  const recentBoardIDs = getRecentBoardIDs()
  const recentBoards = []
  for (let board of allBoards) {
    if (recentBoardIDs.includes(board.id)) {
      let index = recentBoardIDs.indexOf(board.id)
      recentBoards[index] = board
    }
  }
  return recentBoards
}

export function getBoard(boardID, allBoards) {
  for (let board of allBoards) {
    if (boardID === board.id) {
      return board
    }
  }
}

export function saveLastPinData({ title, caption, tags, selectedBoardIDs, unsafe }) {
  // We store last content first, so if something fails it's easier to add it back
  localStorage.setItem("lastTitle", title)
  localStorage.setItem("lastCaption", caption)
  localStorage.setItem("lastTags", tags.join(' '))
  localStorage.setItem("lastBoardIDs", selectedBoardIDs.join(";"))
  localStorage.setItem("lastUnsafe", unsafe)
}


export const ADD_PIN_TO_BOARD = gql`
  mutation AddPinToBoard(
    $pinID: ID!,
    $boardID: ID!
  ) {
    addPinToBoard(pin: $pinID, board: $boardID) {
      created
      boardPinEdge {
        __typename
        id
        cursor
        order
        node {
          id
        }
      }
    }
  }
`

export const REMOVE_PIN_FROM_BOARD = gql`
  mutation RemovePinFromBoard($pinID: ID!, $boardID: ID!) {
    removePinFromBoard(pin: $pinID, board: $boardID) {
      __typename
      id
      node { id }
    }
  }
`


const CREATE_PIN_MUTATION = gql`
  mutation AddPin(
    $tempFileName: String!,
    $originalName: String!,
    $url: String,
    $title: String,
    $caption: String!,
    $rating: Int,
    $unsafe: Boolean,
    $tags: [String],
  ) {
    addPin(
      tempFileName: $tempFileName,
      originalName: $originalName,
      url: $url,
      title: $title,
      caption: $caption,
      rating: $rating,
      unsafe: $unsafe,
      tags: $tags,
    ) {
      __typename
      id
      caption
      sourceFileUrl
      sourceDomain
      rating
      title
      createdAt
      addedVia { id }
      user { id }
      unsafe
      tags
    }
  }
`

const GET_MY_BOARDS_QUERY = gql`
  ${MY_BOARDS_FRAGMENT}
  query MyBoardsQuery {
    ...MyBoardsFragment
  }
`



function CreatePin(props) {
  const navigate = useNavigate()
  const [selectedBoardIDs, setSelectedBoardIDs] = useState([])
  const [recentBoards, setRecentBoards] = useState([])
  const [title, setTitle] = useState('')
  const [caption, setCaption] = useState('')
  const [defaultCaptionBoardID, setDefaultCaptionBoardID] = useState(null)
  const [rating, setRating] = useState(0)
  const [tags, setTags] = useState([])
  const [defaultTagsBoardID, setDefaultTagsBoardID] = useState(null)
  const [unsafe, setUnsafe] = useState(false)

  let tempPin = props.modalOptions.tempPin

  const getMyBoardsStatus = useQuery(GET_MY_BOARDS_QUERY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setRecentBoards(getRecentBoards(data.me.boards.edges))
      if (props.modalOptions.targetBoardID) {
        /*
        * If we have a target board (from CreatePinButton click)
        * we add it as default board
        */
        const targetBoardID = props.modalOptions.targetBoardID
        // Can't use that because getMyBoardsStatus is not yet available, sad…
        //handleSelectedBoardIDsChange([props.modalOptions.targetBoardID])
        const targetBoard = getBoard(targetBoardID, data.me.boards.edges)
        setDefaultCaptionBoardID(targetBoardID)
        setCaption(targetBoard.pinDefaultDescription || '')
        setTags(targetBoard.pinDefaultTags)
        setDefaultTagsBoardID(targetBoardID)
        setSelectedBoardIDs([targetBoardID])
      }
      if (props.modalOptions.defaultCaption) {
        /*
         * If we have a default caption (from scan)
         * we add it as caption
         */
        setCaption(props.modalOptions.defaultCaption)
      }
    }
  })


  //console.log('createPin', props)

  const [addPinToBoard, addPinToBoardStatus] = useMutation(ADD_PIN_TO_BOARD, {
    update: addPinToBoardUpdate,
    onError: (e) => console.error(e),
  })


  const [createPin, createPinStatus] = useMutation(CREATE_PIN_MUTATION, {
    update: createPinUpdate,
    onCompleted: (data) => {
      //console.log('createPin completed', data)
      setRecentBoardIDs(selectedBoardIDs)

      const pinID = data.addPin.id
      // TODO add and remove boards server side, like for tags, just send new list of boards IDs
      const promises = []
      for (let boardID of selectedBoardIDs) {
        promises.push(addPinToBoard({ variables: {pinID, boardID }}))
      }

      // Wait until all mutations are done
      Promise.all(promises).then(() => {
        navigate(`/pins/${pinID}/`)
        props.closeModal()
      })
    },
    onError: (e) => { console.error(e) },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    // We store last content first, so if something fails it's easier to add it back
    saveLastPinData({ title, caption, tags, selectedBoardIDs, unsafe })
    createPin({variables: {
      title, caption, rating, unsafe, tags,
      tempFileName: tempPin.tempFileName,
      originalName: tempPin.originalName,
      url: tempPin.url,
    }})
  }

  
  const handleSelectedBoardIDsChange = (newSelectedBoardIDs) => {
    // We get newly added board id if any
    let newBoardID
    for (let boardID of newSelectedBoardIDs) {
      if (!selectedBoardIDs.includes(boardID)) {
        newBoardID = boardID
        break // We can get only one new board at each call, no need to continue
      }
    }
    // We use last added board or first board as default board
    const defaultBoardID = newBoardID || newSelectedBoardIDs[0] || null

    if (defaultBoardID) { 
      const defaultBoard = getBoard(defaultBoardID, getMyBoardsStatus.data.me.boards.edges)
      // If caption is empty, we use board's default caption
      if (!caption || (defaultCaptionBoardID && !newSelectedBoardIDs.includes(defaultCaptionBoardID))) {
        setDefaultCaptionBoardID(defaultBoardID)
        setCaption(defaultBoard.pinDefaultDescription || '')
      }
      // We add board default tags to tags
      let nextTags = []
      if (tags.length === 0 || (defaultTagsBoardID && !newSelectedBoardIDs.includes(defaultTagsBoardID))) {
        setDefaultTagsBoardID(defaultBoardID)
      } else {
        nextTags = tags.slice()
      }
      for (let tag of defaultBoard.pinDefaultTags) {
        if (!nextTags.includes(tag)) {
          nextTags.push(tag)
        }
      }
      setTags(nextTags)
    } else { // no more boards, we remove default caption and default tags if any
      if (defaultCaptionBoardID) {
        setCaption('')
      }
      if (defaultTagsBoardID) {
        setTags([])
      }
    }
    setSelectedBoardIDs(newSelectedBoardIDs)
  }

  const handleCaptionChange = (e) => {
    setCaption(e.target.value)
    setDefaultCaptionBoardID(null)
  }

  const handleTagsChange = (tags) => {
    setTags(tags)
    setDefaultTagsBoardID(null)
  }

  if (getMyBoardsStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getMyBoardsStatus.error) {
    //console.error(getMyBoardsStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <p>Sorry, an error occured loading initial data...</p>
      </ModalSection>
    )
  }

  if (getMyBoardsStatus.data.me.boards.edges.length === 0) {
    // We have no board, can't create a pin
    return (
      <ModalSection>
        <ModalHeader
          title="Create pin"
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <h6>Oups...</h6>
          <p>It seems you have no boards, you must create one before adding pins.</p>
        </ModalContent>
        <ModalFooter>
          <Button
            title="Create a board"
            primary={true}
            onClick={() => modalStatusVar({ type: modalTypes.CREATE_BOARD })}
          >Create a board</Button>
        </ModalFooter>
      </ModalSection>
    )
  }

  // TODO delete tempFile mutation on cancel
  return (
    <ModalSection className={styles.splitedModal}>
      <ModalHeader
        title="Create a pin"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <CreatePinForm
          form={CREATE_PIN_FORM}
          onSubmit={handleSubmit}
          handleTitleChange={(e) => setTitle(e.target.value)}
          handleCaptionChange={handleCaptionChange}
          setRating={setRating}
          setTags={handleTagsChange}
          recentBoards={recentBoards}
          allBoards={getMyBoardsStatus.data.me.boards.edges}
          preview={tempPin.tempFileUrl}
          selectedBoardIDs={selectedBoardIDs}
          setSelectedBoardIDs={handleSelectedBoardIDsChange}
          title={title}
          caption={caption}
          rating={rating}
          tags={tags}
          error={createPinStatus.error}
          unsafe={unsafe}
          setUnsafe={setUnsafe}
          showLikePreviousButton={true}
        />
      </ModalContent>
      <ModalFooter>
        {createPinStatus.loading && <Spinner message="Creating pin..." />}
        {addPinToBoardStatus.loading && <Spinner message="Adding pin to board..." />}
        <Submit
          primary={true}
          form={CREATE_PIN_FORM}
          value="Pin it"
        />
      </ModalFooter>
    </ModalSection>
  )
}


CreatePin.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    tempPin: PropTypes.shape({
      url: PropTypes.string,
      tempFileName: PropTypes.string.isRequired,
      tempFileUrl: PropTypes.string.isRequired,
      originalName: PropTypes.string.isRequired,
    }),
    targetBoardID: PropTypes.string,
    defaultCaption: PropTypes.string,
  })
}



export default withModal(CreatePin)
