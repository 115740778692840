import PropTypes from 'prop-types'
import { useQuery, useReactiveVar } from '@apollo/client'
import { 
  showUnsafeVar, 
  PIN_IMAGE_QUERY,
  getImageMaxSide,
  getImageSrcSet,
  getImageSrc,
  getImageIntrinsicSizes,
} from 'components/pin/pinPicture/PinPicture'

function PinImg({ pinID, minWidth, minHeight, alt }) {
  const showUnsafe = useReactiveVar(showUnsafeVar)
  const { loading, data, error } = useQuery(PIN_IMAGE_QUERY, {
    fetchPolicy: 'cache-first',
    variables: { pinID },
  })
  if (loading) return null
  if (error) console.error(error)
  //console.log('PinImg data', data)
  if (data.pin.unsafe && !showUnsafe) return null

  const { sha1, previewsSizes, previewsFormats, width, height } = data.pin
  const ratio = width / height
  const maxSide = getImageMaxSide(ratio, previewsSizes, minWidth, minHeight)
  const src = getImageSrc(sha1, maxSide, previewsFormats[0])
  const srcSet = getImageSrcSet(sha1, ratio, previewsSizes, previewsFormats[0])
  const intrinsicSizes = getImageIntrinsicSizes(ratio, maxSide)
  
  
  return (
    <img
      src={src}
      srcSet={srcSet}
      height={intrinsicSizes.height}
      width={intrinsicSizes.width}
      alt={alt}
    />
  )
}

PinImg.propTypes = {
  pinID: PropTypes.string.isRequired,
  minWidth: PropTypes.number.isRequired,
  minHeight: PropTypes.number.isRequired,
  alt: PropTypes.string.isRequired,
}

export default PinImg
