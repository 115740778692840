import React from 'react'
import { gql } from '@apollo/client'
import { 
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom'

import styles from './app.module.css'

import BannerRootLayout from 'components/common/bannerRootLayout/BannerRootLayout'
import UserRootLayout from 'components/user/userRootLayout/UserRootLayout'
import Login from 'components/user/login/Login'
import Signup from 'components/user/signup/Signup'
import UserProfile from 'components/user/userProfile/UserProfile'
import StartPasswordReset from 'components/user/startPasswordReset/StartPasswordReset'
import PasswordReset from 'components/user/passwordReset/PasswordReset'
import VerifieEmail from 'components/user/verifieEmail/VerifieEmail'
import Modal from 'components/common/modal/Modal'
import LastPins from 'components/pin/lastPins/LastPins'
import NotFound from 'components/common/notFound/NotFound'
import UserFolders from 'components/folder/userFolders/UserFolders'
import FolderDetail from 'components/folder/folderDetail/FolderDetail'
import UserIndex from 'components/user/userIndex/UserIndex'
import UserBoards from 'components/board/userBoards/UserBoards'
import BoardDetail from 'components/board/boardDetail/BoardDetail'
import UserPins from 'components/pin/userPins/UserPins'
import PinDetail from 'components/pin/pinDetail/PinDetail'
import Lightbox from 'components/pin/lightbox/Lightbox'
import Search from 'components/common/search/Search'
import Scan from 'components/pin/scan/Scan'


export const AUTHENTICATED_QUERY = gql`
  query AuthenticatedQuery {
    me {
      id
      username
    }
  }
`

function App() {
  //console.log('App', props)

  return (
    <section
      role="main"
      className={styles.main}
    >
      <Routes>
        <Route path="/" element={<BannerRootLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/start_password_reset" element={<StartPasswordReset />} />
          <Route path="/password_reset" element={<PasswordReset />} />
          <Route path="/verifie_email" element={<VerifieEmail />} />
          <Route path="/scan/" element={<Scan />} end />
          <Route path="/search/" element={<Search />} end />
          <Route index element={<LastPins />} end />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="/search/pins/:pinID/lightbox" element={<Lightbox />} end />
        <Route path="/search/pins/:pinID/" element={<PinDetail />} end />
        <Route path="/pins/:pinID/" element={<PinDetail />} end />
        <Route path="/pins/:pinID/lightbox/" element={<Lightbox />} end />
        <Route path="/:username/pins/:pinID/" element={<PinDetail />} end />
        <Route path="/:username/pins/:pinID/lightbox" element={<Lightbox />} end />
        <Route path="/:username/boards/:boardSlug/" element={<BoardDetail />} end />
        <Route path="/:username/boards/:boardSlug/pins/:pinID/" element={<PinDetail />} end />
        <Route path="/:username/boards/:boardSlug/pins/:pinID/lightbox/" element={<Lightbox />} end />
        <Route path="/:username/folders/:folderSlug/" element={<FolderDetail />} end />
        <Route path="/:username/folders/:folderSlug/boards/:boardSlug/" element={<BoardDetail />} end />
        <Route path="/:username/folders/:folderSlug/boards/:boardSlug/pins/:pinID" element={<PinDetail />} end />
        <Route path="/:username/folders/:folderSlug/boards/:boardSlug/pins/:pinID/lightbox/" element={<Lightbox />} end />
        <Route path="/:username/" element={<UserRootLayout />} >
          <Route index element={<UserIndex />} />
          <Route path="/:username/folders/" element={<UserFolders />} end />
          <Route path="/:username/boards/" element={<UserBoards />} end />
          <Route path="/:username/pins/" element={<UserPins />} end />
        </Route>
      </Routes>
      <Modal />
    </section>
  )
}


function AppRoot() {
  return (
    <Router>
      <App />
    </Router>
  )
}

export default AppRoot
