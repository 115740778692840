import React, { useState } from 'react'

import { gql, useMutation } from '@apollo/client'

import ModalSection from 'components/common/modalSection/ModalSection'
import ModalHeader from 'components/common/modalHeader/ModalHeader'
import ModalContent from 'components/common/modalContent/ModalContent'
import ModalFooter from 'components/common/modalFooter/ModalFooter'
import StartPasswordResetForm from 'components/user/startPasswordResetForm/StartPasswordResetForm'
import RouterLinkButton from 'components/common/routerLinkButton/RouterLinkButton'
import Submit from 'components/forms/submit/Submit'
import Spinner from 'components/common/spinner/Spinner'

const START_PASSWORD_RESET_FORM = 'START_PASSWORD_RESET_FORM'


const START_PASSWORD_RESET_MUTATION = gql`
  mutation StartUserPasswordReset($email: String!) {
    startUserPasswordReset(email: $email) {
      __typename
      message
    }
  }
`

function StartPasswordReset() {
  const [email, setEmail] = useState('')
  const [done, setDone] = useState(false)

  const [startPasswordReset, { loading, error }] = useMutation(START_PASSWORD_RESET_MUTATION, {
    onCompleted: () => setDone(true),
    onError: (e) => console.error(e),
  })


  const handleSubmit = (e) => {
    e.preventDefault()
    startPasswordReset({ variables: { email }})
  }

  let header = (
    <ModalHeader
      title="Reset your password"
    />
  )


  if (loading) {
    return (
      <ModalSection>
        {header}
        <ModalContent>
          <Spinner message="Verifying your account..." />
        </ModalContent>
      </ModalSection>
    )
  }
  
  if (done) {
    return (
      <ModalSection>
        {header}
        <ModalContent>
          <p>Password reset mail sent!</p>
        </ModalContent>
        <ModalFooter>
          <RouterLinkButton
            value="Ok"
            to="/password_reset"
            primary={true}
          />
        </ModalFooter>
      </ModalSection>
    )
  }

  return (
    <ModalSection>
      {header}
      <ModalContent>
        <StartPasswordResetForm
          form={START_PASSWORD_RESET_FORM}
          onSubmit={handleSubmit}
          handleEmailChange={(e) => setEmail(e.target.value)}
          email={email}
          error={error}
        />
      </ModalContent>
      <ModalFooter>
        <Submit
          primary={true}
          form={START_PASSWORD_RESET_FORM}
          value="Send password reset mail"
        />
      </ModalFooter>
    </ModalSection>
  )
}


export default StartPasswordReset


