import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'

import withModal from 'hoc/withModal/withModal'

import styles from 'components/pin/createPinForm/createPinForm.module.css'

import ModalSection from 'components/common/modalSection/ModalSection'
import ModalHeader from 'components/common/modalHeader/ModalHeader'
import ModalContent from 'components/common/modalContent/ModalContent'
import ModalFooter from 'components/common/modalFooter/ModalFooter'
import CreatePinForm from 'components/pin/createPinForm/CreatePinForm'
import Submit from 'components/forms/submit/Submit'
import Spinner from 'components/common/spinner/Spinner'

import { MY_BOARDS_FRAGMENT } from 'components/pin/createPin/fragments'
import { 
  addPinToBoardUpdate,
  removePinFromBoardUpdate,
} from 'helpers/GraphqlHelpers'
import {
  substractList,
} from 'helpers/CommonHelpers'
import {
  setRecentBoardIDs,
  getRecentBoards,
  getBoard,
  ADD_PIN_TO_BOARD,
  REMOVE_PIN_FROM_BOARD,
} from 'components/pin/createPin/CreatePin'

const UPDATE_PIN_FORM = 'UPDATE_PIN_FORM'

export const getBoardIDsToRemove = (initialBoardIDs, nextBoardIDs) => {
  return substractList(initialBoardIDs, nextBoardIDs)
}

export const getBoardIDsToAdd = (initialBoardIDs, nextBoardIDs) => {
  return substractList(nextBoardIDs, initialBoardIDs)
}


const EDIT_PIN_QUERY = gql`
  ${MY_BOARDS_FRAGMENT}
  query EditPin($id: ID!, $minWidth: Int, $format: String!) {
    ...MyBoardsFragment
    pin(id: $id) {
      id
      title
      caption
      rating
      unsafe
      preview(minWidth: $minWidth, format: $format)
      boards {
        edges {
          id
        }
      }
      tags
      height
      width
    }
  }
`

const UPDATE_PIN_MUTATION = gql`
  mutation UpdatePin(
    $id: ID!,
    $title: String,
    $caption: String, 
    $rating: Int,
    $unsafe: Boolean,
    $tags: [String],
  ) {
    updatePin(
      id: $id,
      title: $title,
      caption: $caption,
      rating: $rating,
      unsafe: $unsafe,
      tags: $tags,
    ) {
      __typename
      id
      createdAt
      title
      caption
      rating
      unsafe
      tags
    }
  }
`


function UpdatePin(props) {
  const [initialBoardIDs, setInitialBoardIDs] = useState([])
  const [selectedBoardIDs, setSelectedBoardIDs] = useState([])
  const [recentBoards, setRecentBoards] = useState([])
  const [title, setTitle] = useState('')
  const [caption, setCaption] = useState('')
  const [defaultCaptionBoardID, setDefaultCaptionBoardID] = useState(null)
  const [rating, setRating] = useState(0)
  const [tags, setTags] = useState([])
  const [defaultTagsBoardID, setDefaultTagsBoardID] = useState(null)
  const [unsafe, setUnsafe] = useState(false)

  let pinID = props.modalOptions && props.modalOptions.pinID ?
    props.modalOptions.pinID : null

  const getPinStatus = useQuery(EDIT_PIN_QUERY, {
    variables: { 
      id: pinID,
      minWidth: 650, 
      format: "jpeg"
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      //console.log('data', data.pin)
      // We populate state with initial data
      const pinBoardIDs = data.pin.boards.edges.map(board => board.id)
      setInitialBoardIDs(pinBoardIDs)
      setSelectedBoardIDs(pinBoardIDs)
      setRecentBoards(getRecentBoards(data.me.boards.edges))
      setTitle(data.pin.title || '')
      setCaption(data.pin.caption || '')
      setRating(data.pin.rating || 0)
      setUnsafe(data.pin.unsafe)
      setTags(data.pin.tags || [])
    }
  })

  
  const [updatePin, updatePinStatus] = useMutation(UPDATE_PIN_MUTATION, {
    onCompleted: () => {
      //console.log('update pin', data)

      // Update boards relations
      const toAdd = getBoardIDsToAdd(initialBoardIDs, selectedBoardIDs)
      const toRemove = getBoardIDsToRemove(initialBoardIDs, selectedBoardIDs)
      setRecentBoardIDs(toAdd)
      
      const promises = []

      // TODO add and remove boards server side, like for tags, just send new list of boards IDs
      for (let boardID of toAdd) {
        promises.push(addPinToBoard({ variables: { pinID, boardID }}))
      }
      for (let boardID of toRemove) {
        promises.push(removePinFromBoard({ variables: { pinID, boardID }}))
      }

      // Wait until all mutations are done
      Promise.all(promises).then(() => props.closeModal())
    },
    onError: (e) => { console.error(e) },
  })

  const [addPinToBoard, addPinToBoardStatus] = useMutation(ADD_PIN_TO_BOARD, {
    update: addPinToBoardUpdate,
    onError: (e) => console.error(e),
  })

  const [removePinFromBoard, removePinFromBoardStatus] = useMutation(REMOVE_PIN_FROM_BOARD, {
    update: removePinFromBoardUpdate,
    onError: (e) => console.error(e),
  })

  const handleSubmit = (e) => {
    e.preventDefault()

    /*
     * Not sure about those.
     * That means next "like previous" click can set title from a pin and caption from another one.
     * Not so coherent. I keep code there in case one day we decide to add different
     * "like previous" button for each field. For moment I thing it's too heavy on the UI.
     * Maybe something tiny on hover ?
     */
    /*
    if (title !== getPinStatus.data.pin.title) {
      // title changed, set last title
      localStorage.setItem("lastTitle", title)
    }
    if (caption !== getPinStatus.data.pin.caption) {
      // caption changed, set last caption
      localStorage.setItem("lastCaption", caption)
    }
    if (tags !== getPinStatus.data.pin.tags) {
      // tags changed, set last tags
      localStorage.setItem("lastTags", tags.join(' '))
    }
    if (selectedBoardIDs !== initialBoardIDs) {
      // boards changed, set last boards
      localStorage.setItem("lastBoardIDs", selectedBoardIDs.join(";"))
    }
    if (unsafe !== getPinStatus.data.pin.unsafe) {
      // unsafe changed, set last unsafe
      localStorage.setItem("lastUnsafe", unsafe)
    }
    */
    updatePin({variables: { id: pinID, title, caption, rating, unsafe, tags }})
  }

  
  const handleSelectedBoardIDsChange = (newSelectedBoardIDs) => {
    // We get newly added board id if any
    let newBoardID
    for (let boardID of newSelectedBoardIDs) {
      if (!selectedBoardIDs.includes(boardID)) {
        newBoardID = boardID
        break // We can get only one new board at each call, no need to continue
      }
    }
    // We use last added board or first board as default board
    const defaultBoardID = newBoardID || newSelectedBoardIDs[0] || null

    if (defaultBoardID) { 
      const defaultBoard = getBoard(defaultBoardID, getPinStatus.data.me.boards.edges)
      // If caption is empty, we use board's default caption
      if (!caption || (defaultCaptionBoardID && !newSelectedBoardIDs.includes(defaultCaptionBoardID))) {
        setDefaultCaptionBoardID(defaultBoardID)
        setCaption(defaultBoard.pinDefaultDescription || '')
      }
      // We add board default tags to tags
      let nextTags = []
      if (tags.length === 0 || (defaultTagsBoardID && !newSelectedBoardIDs.includes(defaultTagsBoardID))) {
        setDefaultTagsBoardID(defaultBoardID)
      } else {
        nextTags = tags.slice()
      }
      for (let tag of defaultBoard.pinDefaultTags) {
        if (!nextTags.includes(tag)) {
          nextTags.push(tag)
        }
      }
      setTags(nextTags)
    } else { // no more boards, we remove default caption and default tags if any
      if (defaultCaptionBoardID) {
        setCaption('')
      }
      if (defaultTagsBoardID) {
        setTags([])
      }
    }
    setSelectedBoardIDs(newSelectedBoardIDs)
  }

  const handleCaptionChange = (e) => {
    setCaption(e.target.value)
    setDefaultCaptionBoardID(null)
  }

  const handleTagsChange = (tags) => {
    setTags(tags)
    setDefaultTagsBoardID(null)
  }

  // console.log('UpdatePin', props)

  if (getPinStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getPinStatus.error) {
    console.error(getPinStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured loading initial data...</p>
        </ModalContent>
      </ModalSection>
    )
  }
  
  const allBoards = getPinStatus.data.me.boards.edges
  return (
    <ModalSection className={styles.splitedModal}>
      <ModalHeader
        title="Edit a pin"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <CreatePinForm
          form={UPDATE_PIN_FORM}
          onSubmit={handleSubmit}
          handleTitleChange={(e) => setTitle(e.target.value)}
          handleCaptionChange={handleCaptionChange}
          setRating={setRating}
          setTags={handleTagsChange}
          setSelectedBoardIDs={handleSelectedBoardIDsChange}
          preview={getPinStatus.data.pin.preview}
          selectedBoardIDs={selectedBoardIDs}
          recentBoards={recentBoards}
          allBoards={allBoards}
          title={title}
          caption={caption}
          rating={rating}
          tags={tags}
          height={getPinStatus.data.pin.height}
          width={getPinStatus.data.pin.width}
          error={updatePinStatus.error}
          unsafe={unsafe}
          setUnsafe={setUnsafe}
          pinID={pinID}
        />
      </ModalContent>
      <ModalFooter>
        {updatePinStatus.loading && <Spinner message="Editing pin..." />}
        {addPinToBoardStatus.loading && <Spinner message="Adding new boards..." />}
        {removePinFromBoardStatus.loading && <Spinner message="Removing old boards..." />}
        <Submit
          primary={true}
          form={UPDATE_PIN_FORM}
          value="Save changes"
        />
      </ModalFooter>
    </ModalSection>
  )
}


UpdatePin.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}



export default withModal(UpdatePin)

