import PropTypes from 'prop-types'

import { gql, useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'

import { getUserNames } from 'components/user/userDetail/UserDetail'
import Spinner from 'components/common/spinner/Spinner'
import UserAvatar from 'components/user/userAvatar/UserAvatar'

import styles from 'components/pin/pinDetailUser/pinDetailUser.module.css'


const PIN_ADDED_VIA_QUERY = gql`
  query PinAddedViaQuery($id: ID!) {
    me {
      id
    }
    pin(id: $id) {
      id
      user {
        id
        username
        lastName
        firstName
      }
   }
}
`



function PinAddedVia({ addedVia }) {

  const { loading, data, error } = useQuery(PIN_ADDED_VIA_QUERY, {
    variables: { 
      id: addedVia,
    },
  })

  if (loading) return <Spinner />
  if (error) console.error(error)
  
  let user = data.pin.user
  let userHomeLink = `/${user.username}/`

  return (
    <div className={styles.addedVia}>
      <Link 
        to={userHomeLink}
      >
        <UserAvatar
          className={styles.avatar}
          username={user.username}
          minSize={60}
        />
      </Link>
      <p>Added via <Link to={`/pins/${addedVia}/`}>{getUserNames(user)}'s pin</Link></p>
    </div>
  )

}


PinAddedVia.propTypes = {
  addedVia: PropTypes.string.isRequired,
}

export default PinAddedVia
