import PropTypes from 'prop-types'
import { modalStatusVar, modalTypes } from 'modal'

function CreateFolderButton(props) {
  const handleClick = () => {
    modalStatusVar({
      type: modalTypes.CREATE_FOLDER,
      options: { secret: props.private},
    })
    
    if (props.onClick) props.onClick() // closing popup
  }

  return (
    <button
      title={props.title || ""}
      className={props.className}
      onClick={handleClick}
    >{props.text || props.private ? "New private folder" : "New folder"}</button>
  )
}

CreateFolderButton.propTypes = {
  private: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
}


export default CreateFolderButton