import PropTypes from 'prop-types'

import CreateBoardButton from 'components/board/createBoardButton/CreateBoardButton'

import styles from 'components/board/userBoards/userBoards.module.css'


function NoBoards(props) {
  const text = props.private ? "No private boards..." : props.owner ? "No public boards..." : "No boards..."
  return (
    <div className={styles.noElements}>
        <p>{text}</p>
        {props.owner ? (
          <CreateBoardButton 
            private={props.private}
            folderID={props.folderID}
            className="button primary"
          />
        ) : null}
    </div>
  )
}

NoBoards.propTypes = {
  owner: PropTypes.bool.isRequired,
  folderID: PropTypes.string,
  private: PropTypes.bool,
}

export default NoBoards