import { useParams, Outlet } from 'react-router-dom'

import Header from 'components/common/header/Header'
import UserDetail from 'components/user/userDetail/UserDetail'

function UserRootLayout() {
  let { username } = useParams()

  return (
    <>
      <Header />
      <UserDetail username={username} />
      <Outlet />
    </>
  )
}

export default UserRootLayout
