
import { useState } from 'react'
import {
  gql,
  useQuery,
  useLazyQuery,
} from '@apollo/client'
import { 
  Link, 
  useParams, 
  useNavigate 
} from 'react-router-dom'

import useKeyboardNavigation from 'hooks/useKeyboardNavigation'
import useSwipeGestures from 'hooks/useSwipeGestures'
import useFullscreenStatus from 'hooks/useFullscreenStatus'
import useQueryString from 'hooks/useQueryString'

import ErrorMessage from 'components/common/errorMessage/ErrorMessage'
import Spinner from 'components/common/spinner/Spinner'
import AccessibilityText from 'components/common/accessibilityText/AccessibilityText'
import PinPicture from 'components/pin/pinPicture/PinPicture'
import { PIN_IMAGE_FRAGMENT } from 'components/pin/pinPicture/fragments'

import styles from './lightbox.module.css'

const PIN_LIGHTBOX_FRAGMENT = gql`
fragment PinLightboxFragment on Pin {
  id
  caption
  user { id }
}
`

/* 
 * Getting pin data in PinSingleEdge is bad because it can't be automatically cached by Apollo.
 * Better keep a separate pin(id: ID!) query for that and only get context from PinSingleEdge.
 */

const BOARD_PIN_DETAIL_QUERY = gql`
${PIN_LIGHTBOX_FRAGMENT}
${PIN_IMAGE_FRAGMENT}
query BoardPinDetailQuery(
  $username: String!, 
  $boardSlug: String!, 
  $pinID: ID!
) {
  pin(id: $pinID) {
    ...PinLightboxFragment
    ...PinImageFragment
  }
  user(username: $username) {
    id
    board(slug: $boardSlug) {
      id
      pin(id: $pinID) {
        prevID
        nextID
      }
    }
  }
}
`

const USER_PIN_DETAIL_QUERY = gql`
${PIN_LIGHTBOX_FRAGMENT}
${PIN_IMAGE_FRAGMENT}
query UserPinDetailQuery($username: String!, $pinID: ID!) {
  pin(id: $pinID) {
    ...PinLightboxFragment
    ...PinImageFragment
  }
  user(username: $username) {
    id
    pin(id: $pinID) {
      prevID
      nextID
    }
  }
}
`

const ALL_PIN_DETAIL_QUERY = gql`
${PIN_LIGHTBOX_FRAGMENT}
${PIN_IMAGE_FRAGMENT}
query AllPinDetailQuery($pinID: ID!, $tag: String, $query: String) {
  pin(id: $pinID) {
    ...PinLightboxFragment
    ...PinImageFragment
  }
  singlePin(id: $pinID, tag: $tag, search: $query) {
    prevID
    nextID
  }
}
`

function Lightbox() {
  let { username, folderSlug, boardSlug, pinID } = useParams()
  let queryString = useQueryString()
  let query = queryString.get('query')
  let tag = queryString.get('tag')
  
  const [showControls, setShowControls] = useState(false)
  const [nextID, setNextID] = useState(null)
  const [prevID, setPrevID] = useState(null)


  const navigateNext = () => {
    if (nextID) navigate(nextLink)
  }

  const navigatePrev = () => {
    if (prevID) navigate(prevLink)
  }

  const closeLightbox = (e) => {
    e.preventDefault()
    return navigate(getBackLink(pinID))
  }

  const navigate = useNavigate()
  useKeyboardNavigation(navigateNext, navigatePrev, closeLightbox)

  const isFullscreen = useFullscreenStatus()

  const [handleTouchStart, handleTouchEnd, isTouchScreen] = useSwipeGestures({
    handleSwipeUp: () => null,
    handleSwipeDown: closeLightbox,
    handleSwipeRight: navigatePrev,
    handleSwipeLeft: navigateNext,
  })


  const getQuery = () => {
    // We need different queries to have 
    // parent related navigation
    if (username && boardSlug && pinID) {
      // Pin in board context
      return BOARD_PIN_DETAIL_QUERY
    } else if (username && pinID) {
      return USER_PIN_DETAIL_QUERY
    }
    return ALL_PIN_DETAIL_QUERY
  }

  const { loading, data, error } = useQuery(getQuery(), {
    variables: { username, boardSlug, pinID, tag, query },
    onCompleted: (data) => {
      const pinEdge = data.singlePin || data.user?.pin || data.user?.board?.pin
      setNextID(pinEdge.nextID)
      setPrevID(pinEdge.prevID)

      // If we have a next pin, we preload it, then we preload previous one.
      if (pinEdge.nextID) {
        preloadAdjacent({ variables: { username, boardSlug, pinID: pinEdge.nextID, tag, query }}).then(() => {
          if (pinEdge.prevID) {
            preloadAdjacent({ variables: { username, boardSlug, pinID: pinEdge.prevID, tag, query }})
          }
        })
      // If we only have a previous pin, we preload it.
      } else if (pinEdge.prevID) {
        preloadAdjacent({ variables: { username, boardSlug, pinID: pinEdge.prevID, tag, query }})
      }
    },
  })
  const [preloadAdjacent] = useLazyQuery(getQuery())
  
  if (loading) return <div className={styles.lightbox}><Spinner /></div>
  
  if (error) {
    return <ErrorMessage error={error} />
  }

  const getBaseLink = (pinID) => {
    if (username && folderSlug && boardSlug) {
      // User => Folders => Board context
      return `/${username}/folders/${folderSlug}/boards/${boardSlug}/pins/${pinID}/`
    }
    if (username && boardSlug) {
      // User => Boards context
      return `/${username}/boards/${boardSlug}/pins/${pinID}/`
    }
    if (username) {
      // User pins context
      return `/${username}/pins/${pinID}/`
    }
    if (query || tag) {
      // Search context
      return `/search/pins/${pinID}/`
    }
    // All pins context
    return `/pins/${pinID}/`
  }

  const getQueryString = () => {
    let query = queryString.toString()
    if (query) {
      return `?${query}`
    }
    return ''
  }

  const getBackLink = (pinID) => {
    return getBaseLink(pinID) + getQueryString()
  }

  const getLightboxLink = (pinID) => {
    if (pinID) {
      return `${getBaseLink(pinID)}lightbox/${getQueryString()}`
    }
    return null
  }



  //console.log('lightbox', data.pin.id)

  const pin = data.pin
  const nextLink = getLightboxLink(nextID)
  const prevLink = getLightboxLink(prevID)

  return (
    <div
      className={styles.lightbox}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onClick={() => setShowControls(!showControls)}
    >
      <div className={styles.imageWrapper}>
        <PinPicture
          pinID={pin.id}
          minWidth={2048}
          className={styles.image}
          alt={pin.caption}
        />
      </div>
      {prevLink && showControls && !isTouchScreen ? (<Link
        className={styles.prev}
        to={prevLink}
        onClick={(e) => e.stopPropagation()}
      ><AccessibilityText text="Previous pin" /></Link>) : null}
      {nextLink && showControls && !isTouchScreen ? (<Link
        className={styles.next}
        to={nextLink}
        onClick={(e) => e.stopPropagation()}
      ><AccessibilityText text="Next pin" /></Link>) : null}
      { !isFullscreen && showControls ? (
        <Link
          className={styles.close}
          to={getBackLink(pinID)}
          onClick={(e) => e.stopPropagation()}
        ><AccessibilityText text="Close lightbox" /></Link>
      ) : null}
      { isFullscreen && showControls && !isTouchScreen ? (
        <div
          className={styles.exitFullScreen}
          onClick={(e) => {
            e.stopPropagation()
            if (document.exitFullscreen) {
              document.exitFullscreen()
            }
          }}
        ><AccessibilityText text="Exit full screen" /></div>
      ) : null}
      {!isFullscreen && showControls && !isTouchScreen ? (
        <div
          className={styles.fullScreen}
          onClick={(e) => {
            e.stopPropagation()
            if (!document.fullscreenElement) {
              document.documentElement.requestFullscreen()
            }
          }}
        ><AccessibilityText text="Full screen" /></div>
    ) : null}
    </div>
  )
}

export default Lightbox
