import { useState } from 'react'
import {
  gql,
  useQuery,
  useLazyQuery,
} from '@apollo/client'
import { useParams, useNavigate, Link } from 'react-router-dom'

import styles from './pinDetail.module.css'

import useKeyboardNavigation from '../../../hooks/useKeyboardNavigation'
import useSwipeGestures from '../../../hooks/useSwipeGestures'
import useQueryString from '../../../hooks/useQueryString'

import Header from 'components/common/header/Header'
import PinDetailHeader from 'components/pin/pinDetailHeader/PinDetailHeader'
import PinDetailFooter from 'components/pin/pinDetailFooter/PinDetailFooter'
import PinDetailUser from 'components/pin/pinDetailUser/PinDetailUser'
import PinTags from 'components/pin/pinTags/PinTags'
import PinRater from 'components/pin/pinRater/PinRater'
import PinInfo from 'components/pin/pinInfo/PinInfo'
import PinBoards from 'components/pin/pinBoards/PinBoards'
import ErrorMessage from 'components/common/errorMessage/ErrorMessage'
import Spinner from 'components/common/spinner/Spinner'
import AccessibilityText from 'components/common/accessibilityText/AccessibilityText'
import NavigationBar from 'components/common/navigationBar/NavigationBar'
import NavigationMenuButton from 'components/common/navigationMenuButton/NavigationMenuButton'
import PinDetailMenu from 'components/pin/pinDetailMenu/PinDetailMenu'
import PinDetailImage from 'components/pin/pinDetailImage/PinDetailImage'
import { PIN_IMAGE_FRAGMENT } from 'components/pin/pinPicture/fragments'



export const PIN_DETAIL_FRAGMENT = gql`
fragment PinDetailFragment on Pin {
  __typename
  id
  sourceFileUrl
  sourceDomain
  title
  caption
  rating
  original
  width
  height
  previewsSizes
  unsafe
  secret
  addedVia { id }
  tags
  user {
    id
    username
    lastName
    firstName
  }
}
`

/* 
 * Getting pin data in PinSingleEdge is bad because it can't be automatically cached by Apollo.
 * Better keep a separate pin(id: ID!) query for that and only get context from PinSingleEdge.
 */

const BOARD_PIN_DETAIL_QUERY = gql`
${PIN_DETAIL_FRAGMENT}
${PIN_IMAGE_FRAGMENT}
query BoardPinDetailQuery(
  $username: String!, 
  $boardSlug: String!, 
  $pinID: ID!
) {
  me { id }
  pin(id: $pinID) {
    ...PinDetailFragment
    ...PinImageFragment
  }
  user(username: $username) {
    id
    board(slug: $boardSlug) {
      id
      pin(id: $pinID) {
        prevID
        nextID
      }
    }
  }
}
`

const USER_PIN_DETAIL_QUERY = gql`
${PIN_DETAIL_FRAGMENT}
${PIN_IMAGE_FRAGMENT}
query UserPinDetailQuery($username: String!, $pinID: ID!) {
  me { id }
  pin(id: $pinID) {
    ...PinDetailFragment
    ...PinImageFragment
  }
  user(username: $username) {
    id
    pin(id: $pinID) {
      prevID
      nextID
    }
  }
}
`

const ALL_PIN_DETAIL_QUERY = gql`
${PIN_DETAIL_FRAGMENT}
${PIN_IMAGE_FRAGMENT}
query AllPinDetailQuery($pinID: ID!, $tag: String, $query: String) {
  me { id }
  pin(id: $pinID) {
    ...PinDetailFragment
    ...PinImageFragment
  }
  singlePin(id: $pinID, tag: $tag, search: $query) {
    prevID
    nextID
  }
}
`


function PinDetail(props) {
  let { username, folderSlug, boardSlug, pinID } = useParams()
  let queryString = useQueryString()
  let query = queryString.get('query')
  let tag = queryString.get('tag')
  
  const [nextID, setNextID] = useState(null)
  const [prevID, setPrevID] = useState(null)

  const navigate = useNavigate()

  const navigateNext = () => {
    if (nextID) navigate(nextLink)
  }

  const navigatePrev = () => {
    if (prevID) navigate(prevLink)
  }

  const navigateBack = () => {
    navigate(getBackLink())
  }

  const getQuery = () => {
    // We need different queries to have 
    // parent related navigation
    if (username && boardSlug && pinID) {
      // Pin in board context
      return BOARD_PIN_DETAIL_QUERY
    } else if (username && pinID) {
      return USER_PIN_DETAIL_QUERY
    }
    return ALL_PIN_DETAIL_QUERY
  }

  const getBaseLink = () => {
    if (username && folderSlug && boardSlug) {
      // User => Folders => Board context
      return `/${username}/folders/${folderSlug}/boards/${boardSlug}/`
    }
    if (username && boardSlug) {
      // User => Boards context
      return `/${username}/boards/${boardSlug}/`
    }
    if (username) {
      // User pins context
      return `/${username}/pins/`
    }
    if (query || tag) {
      // Search context
      return '/search/'
    }
    // All pins context
    return '/'
  }

  const getQueryString = () => {
    let query = queryString.toString()
    if (query) {
      return `?${query}`
    }
    return ''
  }

  const getBackLink = () => {
    return getBaseLink() + getQueryString()
  }

  const getPrevNextLink = (pinID) => {
    if (! pinID) return null
    let baseLink = getBaseLink()
    if (! baseLink.endsWith('pins/')) {
      // We need to add 'pins/' before pinID except for user pins url
      baseLink += 'pins/'
    }
      
    return `${baseLink}${pinID}/${getQueryString()}`
  }



  useKeyboardNavigation(navigateNext, navigatePrev, navigateBack)
  const [handleTouchStart, handleTouchEnd] = useSwipeGestures({
    handleSwipeUp: () => null,
    handleSwipeDown: () => null,
    handleSwipeRight: navigatePrev,
    handleSwipeLeft: navigateNext,
  })

  const { loading, data, error } = useQuery(getQuery(), {
    variables: { username, boardSlug, pinID, tag, query },
    onCompleted: (data) => {
      const pinEdge = data.singlePin || data.user?.pin || data.user?.board?.pin
      setNextID(pinEdge.nextID)
      setPrevID(pinEdge.prevID)

      // If we have a next pin, we preload it, then we preload previous one.
      if (pinEdge.nextID) {
        preloadAdjacent({ variables: { username, boardSlug, pinID: pinEdge.nextID, tag, query }}).then(() => {
          if (pinEdge.prevID) {
            preloadAdjacent({ variables: { username, boardSlug, pinID: pinEdge.prevID, tag, query }})
          }
        })
      // If we only have a previous pin, we preload it.
      } else if (pinEdge.prevID) {
        preloadAdjacent({ variables: { username, boardSlug, pinID: pinEdge.prevID, tag, query }})
      }
    }
  })
  const [preloadAdjacent] = useLazyQuery(getQuery())
 
  if (loading) return <Spinner />
  if (error) {
    console.error(error)
    return <ErrorMessage error={error} />
  }
 
  //console.log('PinDetail', username, folderSlug, boardSlug, pinID, query, tag, data, error)

  const boardID = data.user?.board?.id
  const pin = data.pin
  const owner = !!data.me && data.me.id === pin.user.id
  const nextLink = getPrevNextLink(nextID)
  const prevLink = getPrevNextLink(prevID)
  const backLink = getBackLink()
 
  //console.log('render PinDetail', pinID)
  return (
    <>
      <Header>
        <NavigationBar
          backLink={backLink}
        >
          <NavigationMenuButton>
            <PinDetailMenu
              sourceFileUrl={pin.sourceFileUrl}
              original={pin.original}
              pinID={pin.id}
              boardID={boardID}
              owner={owner}
              backLink={backLink}
            />
          </NavigationMenuButton>
        </NavigationBar>
      </Header>
      <section id="content">
        <div
          className={styles.mainWrapper}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <article className={`${styles.card} ${styles.pinDetail}`}>
            {props.showHeader ? (
              <PinDetailHeader
                sourceFileUrl={pin.sourceFileUrl}
                original={pin.original}
                pinID={pin.id}
                boardID={boardID}
                owner={owner}
              />
            ) : null}
            <Link
              className={styles.lightboxLink}
              to={`./lightbox/${getQueryString()}`}
            >
              <PinDetailImage 
                pinID={pinID}
                alt={pin.caption}
                height={pin.height}
                width={pin.width}
                previewsSizes={pin.previewsSizes}
                secret={pin.secret}
                unsafe={pin.unsafe}
              />
            </Link>
            <div className={styles.navigation}>
              <div>{prevLink ? (
                <Link
                  className={styles.prevPinLink}
                  to={prevLink}
                  title="Previous pin"
                > 
                  <AccessibilityText text="Previous pin" />
                </Link>
              ) : null}</div>
              <PinRater
                className={styles.rating}
                pinID={pin.id}
                owner={owner}
                rating={pin.rating}
              />
              <div>{nextLink ? (
                <Link
                  className={styles.nextPinLink}
                  to={nextLink}
                  title="Next pin"
                > 
                  <AccessibilityText text="Next pin" />
                </Link>
              ) : null}</div>
            </div>
            <div className={styles.pinTitle}>
              {pin.title ? (
                <h4>{pin.title}</h4>
              ) : (
                <hr />
              )}
            </div>
            <div className={styles.captionWrapper}>
              <p
                className={styles.caption}
              >{pin.caption}</p>
              <PinTags tags={pin.tags} />
            </div>
            <PinDetailFooter
              sourceFileUrl={pin.sourceFileUrl}
              sourceDomain={pin.sourceDomain}
            />
          </article>


          <PinInfo pinID={pin.id} />

          
          <PinBoards
            pinID={pin.id}
            owner={owner}
          />


          <PinDetailUser
            username={pin.user.username}
            addedVia={pin.addedVia?.id}
          />
        </div>
      </section>
    </>
  )
}

export default PinDetail
