import { useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import PinPicture from 'components/pin/pinPicture/PinPicture'

import styles from 'components/pin/pinDetail/pinDetail.module.css'

function PinDetailImage({ pinID, alt, height, width, previewsSizes, secret, unsafe }) {

  const ref = useRef(null)
  const [isSmallImage, setIsSmallImage] = useState(false)

  const computeWidth = useCallback(() => {
    const containerWidth = ref.current.clientWidth
    let maxWidth = previewsSizes[previewsSizes.length - 1]
    if (height > width) { // previews sizes contains height
      const ratio = width / height
      maxWidth = maxWidth * ratio
    }
    if (maxWidth < containerWidth) {
      setIsSmallImage(true)
    } else {
      setIsSmallImage(false)
    }
  }, [height, width, previewsSizes])

  useEffect(() => {
    // we compute width at initial render
    computeWidth()
    // we compute width at each window resize
    window.addEventListener('resize', computeWidth) 
    return () => window.removeEventListener('resize', computeWidth)

  }, [pinID, computeWidth])

  //console.log('render PinDetailIMage', pinID, height, width)

  return (
    <div 
      ref={ref}
      className={isSmallImage ? styles.smallImageWrapper : styles.imageWrapper}
    >
      <div 
        className={styles.badgesWrapper}
        style={{
          // Never bigger than underlying image
          // necessary for forbidden div to show up
          maxWidth: `${width}px`, 
          maxHeight: `${height}px`, 
          aspectRatio: `${width} / ${height}`,
        }}
      >
        <PinPicture
          pinID={pinID}
          alt={alt}
          minWidth={738}
        />
        <div className={styles.badges}>
          {secret ? <div>Private</div> : null}
          {unsafe ? <div>NSFW</div> : null}
        </div>
      </div>
    </div>
  )
}


export default PinDetailImage

PinDetailImage.propTypes = {
  pinID: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  previewsSizes: PropTypes.array.isRequired,
  secret: PropTypes.bool,
  unsafe: PropTypes.bool,
}