import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation } from '@apollo/client'

import withModal from 'hoc/withModal/withModal'


import ModalSection from 'components/common/modalSection/ModalSection'
import ModalHeader from 'components/common/modalHeader/ModalHeader'
import ModalContent from 'components/common/modalContent/ModalContent'
import ModalFooter from 'components/common/modalFooter/ModalFooter'
import CreateFolderForm from 'components/folder/createFolderForm/CreateFolderForm'
import Submit from 'components/forms/submit/Submit'
import Spinner from 'components/common/spinner/Spinner'
import { 
  createItemRef,
  addItemToEdges,
} from 'helpers/GraphqlHelpers'

const CREATE_FOLDER_FORM = 'CREATE_FOLDER_FORM'

const CREATE_FOLDER_MUTATION = gql`
mutation AddFolder(
  $name: String!,
  $secret: Boolean
) {
  addFolder(
    name: $name,
    secret: $secret
  ) {
    __typename
    id
    slug
    createdAt
    name
    secret
    user { id }
  }
}
`

const NEW_FOLDER_FRAGMENT = gql`
  fragment NewFolder on Folder {
    id
    slug
    createdAt
    name
    secret
    user
  }
`


function CreateFolder(props) {
  const [name, setName] = useState('')
  const [createFolder, { loading, error }] = useMutation(CREATE_FOLDER_MUTATION, {
    update: (cache, { data: { addFolder } }) => {
      
      const newFolderRef = createItemRef(cache, addFolder, NEW_FOLDER_FRAGMENT)

      function addFolderToCache(cachedFolders) {
        return addItemToEdges(cachedFolders, newFolderRef)
      }

      cache.modify({
        id: cache.identify(addFolder.user),
        fields: {
          folders: addFolderToCache,
        }
      })
    },
    onCompleted: (data) => {
      //console.log('created board', data)
      props.closeModal()
    },
    onError: (e) => { console.error(e) },
  })

  let secret = props.modalOptions && props.modalOptions.secret ? 
    props.modalOptions.secret : false

  const handleSubmit = (e) => {
    e.preventDefault()
    createFolder({ variables: {
      name, secret
    }})
  }

  //console.log('CreateFolder', props)
  return (
    <ModalSection>
      <ModalHeader
        title={secret ? "Create a private folder" : "Create a folder"}
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <CreateFolderForm
          form={CREATE_FOLDER_FORM}
          onSubmit={handleSubmit}
          handleNameChange={(e) => setName(e.target.value)}
          name={name}
          error={error}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Creating folder..." />}
        <Submit
          primary={true}
          form={CREATE_FOLDER_FORM}
          value={secret ? "Create private folder" : "Create folder"}
        />
      </ModalFooter>
    </ModalSection>
  )
}

CreateFolder.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}



export default withModal(CreateFolder)
