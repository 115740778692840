
import {
  useQuery,
  gql,
} from '@apollo/client'
import { useParams } from 'react-router-dom'


import styles from './userBoards.module.css'

import { PIN_IMAGE_FRAGMENT } from 'components/pin/pinPicture/fragments'
import ErrorMessage from 'components/common/errorMessage/ErrorMessage'
import Spinner from 'components/common/spinner/Spinner'
import BoardsList from 'components/board/boardsList/BoardsList'
import PrivateBoardsWrapper from 'components/board/privateBoardsWrapper/PrivateBoardsWrapper'

const USER_BOARDS_QUERY = gql`
  ${PIN_IMAGE_FRAGMENT}
  query UserBoardsQuery($username: String!) {
    me {
      id
    }
    user(username: $username) {
      id
      boards {
        edges {
          id
          secret
          slug
          title
          caption
          pins { totalCount }
          cover1 { ...PinImageFragment }
          cover2 { ...PinImageFragment }
          cover3 { ...PinImageFragment }
          cover4 { ...PinImageFragment }
          cover5 { ...PinImageFragment }
        }
      }
    }
  }
`

function UserBoards() {
  let { username } = useParams()
  const { loading, data, error } = useQuery(USER_BOARDS_QUERY, {
    variables: { username },
  })
  //console.log('UserBoards', username, data, error)

  if (loading) return <Spinner />
  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }
  let owner = !!data.me && data.me.id === data.user.id
  

  return (
    <>
      <section className={styles.boardsSection + " columned"}>
        <BoardsList
          boards={data.user.boards.edges.filter(node => ! node.secret)}
          owner={owner}
          baseLink={`/${username}/`}
        />
      </section>
      <PrivateBoardsWrapper
        boards={data.user.boards.edges.filter(node => node.secret)}
        owner={owner}
        baseLink={`/${username}/`}
      />
    </>
  )
}


export default UserBoards
