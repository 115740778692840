
import PropTypes from 'prop-types'

import CreatePinButton from 'components/pin/createPinButton/CreatePinButton'

import styles from 'components/board/userBoards/userBoards.module.css'

function NoPins({ owner, boardID }) {
  return (
    <div className={styles.noElements}>
      <p>No pins...</p>
      {owner ? (
        <CreatePinButton
          className="button primary"
          boardID={boardID}
        />
      ) : null}
    </div>
  )
}

NoPins.propTypes = {
  owner: PropTypes.bool.isRequired,
  boardID: PropTypes.string,

}

export default NoPins