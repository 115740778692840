import PropTypes from 'prop-types'
import React, { useState } from 'react'

import AccessibilityText from 'components/common/accessibilityText/AccessibilityText'
import PopupMenuWrapper from 'components/common/popupMenuWrapper/PopupMenuWrapper'

import styles from './navigationMenuButton.module.css'
import popupStyles from 'components/common/popupMenuWrapper/popupMenuWrapper.module.css'

function NavigationMenuButton({ children }) {
  const [ showMenu, setShowMenu ] = useState(false)

  const closeMenu = () => setShowMenu(false)

  const getMenu = () => {
    if (! showMenu) return null
    return (
      <PopupMenuWrapper
        closeMenu={closeMenu}
        menuClassName={popupStyles.navigationMenu}
      >
        {children}
      </PopupMenuWrapper>
    )
  }

  return (
    <div className={styles.wrapper}>
      <button
        className={styles.menuButton}
        onClick={() => setShowMenu(! showMenu)}
      >
          <AccessibilityText text="Navigation bar menu" />
      </button>
      {getMenu()}
    </div>
  )
}


NavigationMenuButton.propTypes = {
  children: PropTypes.element.isRequired,
}

export default NavigationMenuButton