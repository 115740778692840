import PropTypes from 'prop-types'

import styles from 'components/common/navigationBar/navigationBar.module.css'

function NavigationBarButton({ text, onClick }) {
  return (
    <button
      className={styles.textButton}
      onClick={onClick}
    >{text}</button>
  )
}

NavigationBarButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default NavigationBarButton