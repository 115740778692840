import PropTypes from 'prop-types'

import styles from 'components/board/userBoards/userBoards.module.css'

import BoardsList from 'components/board/boardsList/BoardsList'

function PrivateBoardsWrapper(props) {
  // we show section only if owner (to show create private board button)
  // or if there are some private boards to show
  if (! props.owner && props.boards.length === 0) return null
  return (
    <div className={styles.privateBoardsWrapper}>
      <section className={styles.boardsSection + " columned"}>
        <h2>Private boards</h2>
          <BoardsList
            boards={props.boards}
            owner={props.owner}
            private={true}
            baseLink={props.baseLink}
          />
      </section>
    </div>
  )
}

PrivateBoardsWrapper.propTypes = {
  boards: PropTypes.array.isRequired,
  owner: PropTypes.bool.isRequired,
  baseLink: PropTypes.string.isRequired,
}

export default PrivateBoardsWrapper
