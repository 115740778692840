
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'

import withModal from 'hoc/withModal/withModal'


import ModalSection from 'components/common/modalSection/ModalSection'
import ModalHeader from 'components/common/modalHeader/ModalHeader'
import ModalContent from 'components/common/modalContent/ModalContent'
import ModalFooter from 'components/common/modalFooter/ModalFooter'
import UpdateFolderForm from 'components/folder/updateFolderForm/UpdateFolderForm'
import Submit from 'components/forms/submit/Submit'
import Spinner from 'components/common/spinner/Spinner'

const UPDATE_FOLDER_FORM = 'UPDATE_FOLDER_FORM'


const GET_FOLDER_QUERY = gql`
query Folder($id: ID!) {
  folder(id: $id) {
    id
    name
    secret
  }
}
`

const UPDATE_FOLDER_MUTATION = gql`
mutation UpdateFolder(
  $id: ID!,
  $name: String!,
  $secret: Boolean
) {
  updateFolder(
    id: $id,
    name: $name,
    secret: $secret
  ) {
    __typename
    id
    slug
    createdAt
    name
    secret
  }
}
`



function UpdateFolder(props) {
  const [name, setName] = useState('')
  const [policie, setPolicie] = useState("public")
  
  let id = props.modalOptions && props.modalOptions.folderID ?
    props.modalOptions.folderID : null

  const getFolderStatus = useQuery(GET_FOLDER_QUERY, {
    variables: { id },
    onCompleted: ({ folder }) => {
      //console.log('data', folder)
      // We populate state with initial data
      setName(folder.name || '')
      setPolicie(folder.secret ? "private" : "public")
    }
  })

  const [updateFolder, { loading, error }] = useMutation(UPDATE_FOLDER_MUTATION, {
    onCompleted: (data) => {
      //console.log('updated folder', data)
      props.closeModal()
    },
    onError: (e) => console.error(e),
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    let secret = policie === "private" ? true : false
    updateFolder({ variables: {
      id, name, secret
    }})
  }

  //console.log('UpdateFolder', props)

  if (getFolderStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getFolderStatus.error) {
    console.error(getFolderStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <p>Sorry, an error occured loading initial data...</p>
      </ModalSection>
    )
  }

  return (
    <ModalSection>
      <ModalHeader
        title="Edit a folder"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <UpdateFolderForm
          form={UPDATE_FOLDER_FORM}
          onSubmit={handleSubmit}
          handleNameChange={(e) => setName(e.target.value)}
          handlePolicieChange={(e) => setPolicie(e.target.value)}
          name={name}
          policie={policie}
          error={error}
          id={id}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Updating folder..." />}
        <Submit
          primary={true}
          form={UPDATE_FOLDER_FORM}
          value="Save changes"
        />
      </ModalFooter>
    </ModalSection>
  )
}


UpdateFolder.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}



export default withModal(UpdateFolder)
