import PropTypes from 'prop-types'

import FieldWrapper from 'components/forms/fieldWrapper/FieldWrapper'
import FormErrors from 'components/forms/formErrors/FormErrors'


function UserProfileForm(props) {
  return (
    <form
      id={props.form}
      onSubmit={props.onSubmit}
    >
      <FieldWrapper>
        <label htmlFor="id-lastname">Lastname:</label>
        <input
          id="id-lastname"
          name="lastname"
          type="text"
          value={props.lastName}
          onChange={props.handleLastNameChange}
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-firstname">Firstname:</label>
        <input
          id="id-firstname"
          name="firstname"
          type="text"
          value={props.firstName}
          onChange={props.handleFirstNameChange}
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-website">Website:</label>
        <input
          id="id-website"
          name="website"
          type="text"
          value={props.websiteLink}
          onChange={props.handleWebsiteLinkChange}
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-facebook">Facebook:</label>
        <input
          id="id-facebook"
          name="facebook"
          type="text"
          value={props.facebookLink}
          onChange={props.handleFacebookLinkChange}
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-flickr">Flickr:</label>
        <input
          id="id-flickr"
          name="flickr"
          type="text"
          value={props.flickrLink}
          onChange={props.handleFlickrLinkChange}
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-twitter">Twitter:</label>
        <input
          id="id-twitter"
          name="twitter"
          type="text"
          value={props.twitterLink}
          onChange={props.handleTwitterLinkChange}
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-px500">500px:</label>
        <input
          id="id-px500"
          name="px500"
          type="text"
          value={props.px500Link}
          onChange={props.handlePx500LinkChange}
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-googleplus">Google+:</label>
        <input
          id="id-googleplus"
          name="googleplus"
          type="text"
          value={props.googleplusLink}
          onChange={props.handleGoogleplusLinkChange}
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-pinterest">Pinterest:</label>
        <input
          id="id-pinterest"
          name="pinterest"
          type="text"
          value={props.pinterestLink}
          onChange={props.handlePinterestLinkChange}
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-vkontakte">Vkontakte:</label>
        <input
          id="id-vkontakte"
          name="vkontakte"
          type="text"
          value={props.vkontakteLink}
          onChange={props.handleVkontakteLinkChange}
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-instagram">Instagram:</label>
        <input
          id="id-instagram"
          name="instagram"
          type="text"
          value={props.instagramLink}
          onChange={props.handleInstagramLinkChange}
        />
      </FieldWrapper>
      <FieldWrapper>
        <FormErrors
          error={props.error}
          localErrors={props.localErrors}
        />
      </FieldWrapper>
    </form>
  )
}


UserProfileForm.propTypes = {
  form: PropTypes.string.isRequired,
  lastName: PropTypes.string,
  firstName: PropTypes.string,
  websiteLink: PropTypes.string,
  facebookLink: PropTypes.string,
  flickrLink: PropTypes.string,
  twitterLink: PropTypes.string,
  px500Link: PropTypes.string,
  googleplusLink: PropTypes.string,
  pinterestLink: PropTypes.string,
  vkontakteLink: PropTypes.string,
  instagramLink: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleLastNameChange: PropTypes.func.isRequired,
  handleFirstNameChange: PropTypes.func.isRequired,
  handleWebsiteLinkChange: PropTypes.func.isRequired,
  handleFacebookLinkChange: PropTypes.func.isRequired,
  handleFlickrLinkChange: PropTypes.func.isRequired,
  handleTwitterLinkChange: PropTypes.func.isRequired,
  handlePx500LinkChange: PropTypes.func.isRequired,
  handleGoogleplusLinkChange: PropTypes.func.isRequired,
  handlePinterestLinkChange: PropTypes.func.isRequired,
  handleVkontakteLinkChange: PropTypes.func.isRequired,
  handleInstagramLinkChange: PropTypes.func.isRequired,
  error: PropTypes.object,
}


export default UserProfileForm
