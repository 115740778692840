import PropTypes from 'prop-types'

import styles from './popupMenuWrapper.module.css'

function PopupMenuWrapper({ children, closeMenu, menuClassName }) {
  return (
    <div
      className={styles.overlay}
      onClick={closeMenu}
    >
      <div className={`${styles.menu} ${menuClassName}`}>
        {children}
      </div>
    </div>
  )
}

PopupMenuWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  closeMenu: PropTypes.func.isRequired,
  menuClassName: PropTypes.string,
}

export default PopupMenuWrapper