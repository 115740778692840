import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { gql, useMutation, useQuery } from '@apollo/client'

import withModal from 'hoc/withModal/withModal'

import ModalSection from 'components/common/modalSection/ModalSection'
import ModalHeader from 'components/common/modalHeader/ModalHeader'
import ModalContent from 'components/common/modalContent/ModalContent'
import ModalFooter from 'components/common/modalFooter/ModalFooter'
import Button from 'components/forms/button/Button'
import Spinner from 'components/common/spinner/Spinner'
import { 
  deleteField,
  removeItemFromEdges,
} from 'helpers/GraphqlHelpers'



const GET_BOARD_QUERY = gql`
query Board($id: ID!) {
  board(id: $id) {
    id
    title
    secret
    pins {
      totalCount
    }
  }
}
`

const DELETE_BOARD_MUTATION = gql`
mutation DeleteBoard($id: ID!) {
  deleteBoard(id: $id) {
    id
    user { id }
    folder { id }
  }
}
`

function DeleteBoard(props) {
  const navigate = useNavigate()
  
  let id = props.modalOptions && props.modalOptions.boardID ?
    props.modalOptions.boardID : null
  const backLink = props.modalOptions?.backLink

  const getBoardStatus = useQuery(GET_BOARD_QUERY, {
    variables: { id }
  })

  const [deleteBoard, { loading, error }] = useMutation(DELETE_BOARD_MUTATION, {
    variables: { id },
    update: (cache, { data: { deleteBoard } }) => {

      function removeBoardFromCache(cachedBoards, { readField }) {
        return removeItemFromEdges(cachedBoards, readField, deleteBoard.id)
      }

      // remove board from user's boards list
      cache.modify({
        id: cache.identify(deleteBoard.user),
        fields: {
          boards: removeBoardFromCache,
        }
      })
      if (deleteBoard.folder) {
        // remove board from it's folder's boards list
        cache.modify({
          id: cache.identify(deleteBoard.folder),
          fields: {
            boards: removeBoardFromCache,
            cover1: deleteField,
            cover2: deleteField,
            cover3: deleteField,
            cover4: deleteField,
            cover5: deleteField,
          }
        })
      }
    },
    onCompleted: (data) => {
      //console.log('deleted board', data)
      // Navigate to parent context
      navigate(backLink)
      props.closeModal()
    },
    onError: (e) => { console.error(e) },
  })

  //console.log('DeleteBoard', props)

  if (getBoardStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getBoardStatus.error) {
    console.error(getBoardStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title="Delete a board"
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured loading initial data...</p>
        </ModalContent>
      </ModalSection>
    )
  }  

  if (error) {
    console.error(getBoardStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title="Delete a board"
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured deleting...</p>
        </ModalContent>
      </ModalSection>
    )
  }

  let data = getBoardStatus.data.board

  return (
    <ModalSection>
      <ModalHeader
        title="Delete a board"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <h6>Are you sure you want to delete board <em>"{data.title}"</em> ?</h6>
        <p>It contains {data.pins.totalCount} {data.pins.totalCount === 0 ? "pin" : "pins"}.</p>
        <p><em>(this operation is irreversible)</em></p>
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Deleting board..." />}
        <Button
          onClick={deleteBoard}
          primary={true}
        >Delete board</Button>
      </ModalFooter>
    </ModalSection>
  )
}


DeleteBoard.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}



export default withModal(DeleteBoard)
