import PropTypes from 'prop-types'
import { useReactiveVar } from '@apollo/client'
import { showUnsafeVar } from 'components/pin/pinPicture/PinPicture'

function ToogleNSFWButton(props) {
  const showUnsafe = useReactiveVar(showUnsafeVar)

  const handleClick = () => {
    showUnsafeVar(! showUnsafe)
    
    if (props.onClick) props.onClick() // closing popup
  }

  return (
    <button
      className={props.className}
      onClick={handleClick}
    >{showUnsafe ? "Hide NSFW" : "Show NSFW"}</button>
  )
}

ToogleNSFWButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}


export default ToogleNSFWButton