import PropTypes from 'prop-types'
import styles from 'components/pin/pinDetail/pinDetail.module.css'


function PinDetailFooter(props) {
  return (
    <footer className={styles.cardFooter}>
      {props.sourceDomain && props.sourceFileUrl ? (
        <>
          <span>Found on</span><a
            target="_blank"
            rel="noopener noreferrer"
            href={props.sourceFileUrl}
          >{props.sourceDomain}</a>
        </>
      ) : (
        <span>Uploaded to Pinpict</span>
      )}
    </footer>
  )
}

PinDetailFooter.propTypes = {
  sourceFileUrl: PropTypes.string,
  sourceDomain: PropTypes.string,
}

export default PinDetailFooter
