import PropTypes from 'prop-types'

import FormRequiredField from 'components/forms/formRequiredField/FormRequiredField'
import FieldWrapper from 'components/forms/fieldWrapper/FieldWrapper'
import FormErrors from 'components/forms/formErrors/FormErrors'


function SignupForm(props) {
  return (
    <form
      id={props.form}
      onSubmit={props.onSubmit}
    >
      <FieldWrapper>
        <label htmlFor="id-email">Email:<FormRequiredField /></label>
        <input
          id="id-email"
          name="email"
          type="email"
          value={props.email}
          onChange={props.handleEmailChange}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-username">Username:<FormRequiredField /></label>
        <input
          id="id-username"
          name="username"
          type="text"
          maxLength="30"
          value={props.username}
          onChange={props.handleUsernameChange}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-password">Password:<FormRequiredField /></label>
        <input
          id="id-password"
          name="password"
          type="password"
          maxLength="255"
          value={props.password}
          onChange={props.handlePasswordChange}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-passwordConfirm">Password confirmation:<FormRequiredField /></label>
        <input
          id="id-passwordConfirm"
          name="passwordConfirm"
          type="password"
          maxLength="255"
          value={props.passwordConfirm}
          onChange={props.handlePasswordConfirmChange}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <FormErrors
          error={props.error}
          localErrors={props.localErrors}
        />
      </FieldWrapper>
    </form>
  )
}


SignupForm.propTypes = {
  form: PropTypes.string.isRequired,
  email: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  passwordConfirm: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  handleUsernameChange: PropTypes.func.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  handlePasswordConfirmChange: PropTypes.func.isRequired,
  error: PropTypes.object,
  locolErrors: PropTypes.array,
}


export default SignupForm
