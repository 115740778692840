import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'

import withModal from 'hoc/withModal/withModal'

import ModalSection from 'components/common/modalSection/ModalSection'
import ModalHeader from 'components/common/modalHeader/ModalHeader'
import ModalContent from 'components/common/modalContent/ModalContent'
import ModalFooter from 'components/common/modalFooter/ModalFooter'
import Submit from 'components/forms/submit/Submit'
import Spinner from 'components/common/spinner/Spinner'
import FieldWrapper from 'components/forms/fieldWrapper/FieldWrapper'
import FormErrors from 'components/forms/formErrors/FormErrors'
import BoardSelect from 'components/board/boardSelect/BoardSelect'

import {
  setRecentBoardIDs,
  getRecentBoards,
  ADD_PIN_TO_BOARD,
  REMOVE_PIN_FROM_BOARD,
} from 'components/pin/createPin/CreatePin'
import { MY_BOARDS_FRAGMENT } from 'components/pin/createPin/fragments'
import { 
  addPinToBoardUpdate,
  removePinFromBoardUpdate,
} from 'helpers/GraphqlHelpers'
import {
  getBoardIDsToRemove,
  getBoardIDsToAdd,
} from 'components/pin/updatePin/UpdatePin'

import styles from './managePinBoards.module.css'

const MANAGE_PIN_BOARDS_FORM = 'MANAGE_PIN_BOARDS_FORM'

const MANAGE_PIN_BOARDS_QUERY = gql`
  ${MY_BOARDS_FRAGMENT}
  query ManagePinBoardsQuery($pinID: ID!, $minWidth: Int, $format: String!) {
    ...MyBoardsFragment
    pin(id: $pinID) {
      id
      caption
      preview(minWidth: $minWidth, format: $format)
      boards {
        totalCount
        edges {
          id
          title
        }
      }
    }
  }
`


function ManagePinBoards(props) {
  const [initialBoardIDs, setInitialBoardIDs] = useState([])
  const [selectedBoardIDs, setSelectedBoardIDs] = useState([])
  const [recentBoards, setRecentBoards] = useState([])

  let pinID = props.modalOptions && props.modalOptions.pinID ?
    props.modalOptions.pinID : null


  const getPinStatus = useQuery(MANAGE_PIN_BOARDS_QUERY, {
    variables: { 
      pinID,
      minWidth: 650, 
      format: "jpeg"
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      //console.log('data', data.pin)
      // We populate state with initial data
      const pinBoardIDs = data.pin.boards.edges.map(board => board.id)
      setInitialBoardIDs(pinBoardIDs)
      setSelectedBoardIDs(pinBoardIDs)
      setRecentBoards(getRecentBoards(data.me.boards.edges))
    }
  })


  const [addPinToBoard, addPinToBoardStatus] = useMutation(ADD_PIN_TO_BOARD, {
    update: addPinToBoardUpdate,
    onError: (e) => console.error(e),
  })

  const [removePinFromBoard, removePinFromBoardStatus] = useMutation(REMOVE_PIN_FROM_BOARD, {
    update: removePinFromBoardUpdate,
    onError: (e) => console.error(e),
  })





  const handleSubmit = (e) => {
    e.preventDefault()
    const toAdd = getBoardIDsToAdd(initialBoardIDs, selectedBoardIDs)
    const toRemove = getBoardIDsToRemove(initialBoardIDs, selectedBoardIDs)
    
    setRecentBoardIDs(toAdd)
    let promises = []

    // TODO add and remove boards server side, like for tags, just send new list of boards IDs
    // also add new board's default tags to pin server side
    for (let boardID of toAdd) {
      promises.push(addPinToBoard({variables: { pinID, boardID }}))
    }
    for (let boardID of toRemove) {
      promises.push(removePinFromBoard({variables: { pinID, boardID }}))
    }

    // Wait until all mutations are done
    Promise.all(promises).then(() => props.closeModal())
  }


  //console.log('ManagePinBoards props', props)
  //console.log('ManagePinBoards getPinStatus', getPinStatus)

  if (getPinStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getPinStatus.error) {
    console.error(getPinStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured loading initial data...</p>
        </ModalContent>
      </ModalSection>
    )
  }

  const allBoards = getPinStatus.data.me.boards.edges
  const pin = getPinStatus.data.pin
  return (
    <ModalSection>
      <ModalHeader
        title="Pin it"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <div className={styles.previewWrapper}>
          <div className={styles.previewFrame}>
            <img 
              src={pin.preview}
              alt={pin.caption}
            />
          </div>
        </div>
        <form
          id={MANAGE_PIN_BOARDS_FORM}
          onSubmit={handleSubmit}
        >
          <div className={styles.boardsWrapper}>
            <BoardSelect
              selectedBoardIDs={selectedBoardIDs}
              setSelectedBoardIDs={setSelectedBoardIDs}
              recentBoards={recentBoards}
              allBoards={allBoards}
            />
          </div>
          <FieldWrapper>
            <FormErrors
              error={addPinToBoardStatus.error}
            />
          </FieldWrapper>
        </form>
      </ModalContent>
      <ModalFooter>
        {addPinToBoardStatus.loading && <Spinner message="Adding new boards..." />}
        {removePinFromBoardStatus.loading && <Spinner message="Removing old boards..." />}
        <Submit
          primary={true}
          form={MANAGE_PIN_BOARDS_FORM}
          value="Save boards"
        />
      </ModalFooter>
    </ModalSection>
  )
}

ManagePinBoards.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    pinID: PropTypes.string.isRequired,
  }),
}

export default withModal(ManagePinBoards)
