import React, { useState } from 'react'
import {
  useQuery,
  gql,
} from '@apollo/client'

import UserMenu from 'components/user/userMenu/UserMenu'
import UserAvatar from 'components/user/userAvatar/UserAvatar'
import PopupMenuWrapper from 'components/common/popupMenuWrapper/PopupMenuWrapper'
import popupStyles from 'components/common/popupMenuWrapper/popupMenuWrapper.module.css'

import styles from './userMenuButton.module.css'

import { getUserNames } from 'components/user/userDetail/UserDetail'

const USER_MENU_QUERY = gql`
  query UserMenuQuery {
    me {
      id
      username
      firstName
      lastName
    }
  }
`


function UserMenuButton() {
  const [ showMenu, setShowMenu ] = useState(false)

  const closeMenu = () => setShowMenu(false)

  const { loading, data, error } = useQuery(USER_MENU_QUERY)

  if (loading) return null
  if (error) console.error(error)

  function getMenu() {
    if (! showMenu) return null
    return (
      <PopupMenuWrapper
        closeMenu={closeMenu}
        menuClassName={popupStyles.userMenu}
      >
        <UserMenu
          username={data.me.username}
        />
      </PopupMenuWrapper>
    )
  }

  return (
    <div className={styles.wrapper}>
      <button
        className={styles.menuButton}
        onClick={() => setShowMenu(! showMenu)}
      ><UserAvatar
        className={styles.avatar}
        username={data.me.username}
        minSize={32}
      /><div className={styles.userNames}>{getUserNames(data.me)}</div>
      </button>
        {getMenu()}
    </div>
  )
}


export default UserMenuButton
