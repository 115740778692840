import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export function listsHaveCommon(list1, list2) {
  /*
   * Returns true if one element of list1 is also in list2
   * false otherwise
   */
  for (let i=0, l=list2.length; i < l; i++) {
    if (list1.indexOf(list2[i]) > -1) return true
  }
  return false
}

export function substractList(list1, list2) {
  /*
   * Returns items from list1 that aren't in list2
   */
  const listDiff = []
  for (let item of list1) {
    if (!list2.includes(item)) listDiff.push(item)
  }

  return listDiff
}


export function formatFileSize(bytes, precision = 1) {
  if (isNaN(bytes) || ! isFinite(bytes)) return '-'
  let units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
  let number = Math.floor(Math.log(bytes) / Math.log(1024))
  return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + units[number]
}



// A custom hook that builds on useLocation to parse
// the query string for you.
// from https://v5.reactrouter.com/web/example/query-parameters
export function useQueryString() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}



export function humanDate(unixTimestamp) {
  // convert unix timestamp in human readable date
  let d = new Date(+unixTimestamp) // convert string to int
  let date = d.toLocaleDateString('en-EN', { // force english until we translate whole site
    day: "numeric",
    month: "long",
    year: "numeric",
  })
  let time = d.toLocaleTimeString([], {
    hour: "2-digit", minute: "2-digit", second: "2-digit"
  })
  return date + ' at ' + time
}

export function humanSize(weight) {
  // convert weight in bytes to kylobytes, megabytes or gygabytes
  if (weight < 1000) return `${weight} B`
  if (weight < 1000000) return `${Math.round(weight * 0.001 * 100) / 100} KB`
  if (weight < 1000000000) return `${Math.round(weight * 0.000001 * 100) / 100} MB`
  return `${Math.round(weight * 0.000000001 * 100) / 100} GB`
}

export function humanExposureTime(time) {
  // From 0,3s we count in seconds
  if (time >= 0.3) return time

  // Under we count in fraction of seconds
  return `1/${Math.round(1/time)}`
}
