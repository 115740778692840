import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import withModal from 'hoc/withModal/withModal'

import ModalSection from 'components/common/modalSection/ModalSection'
import ModalHeader from 'components/common/modalHeader/ModalHeader'
import ModalContent from 'components/common/modalContent/ModalContent'
import ModalFooter from 'components/common/modalFooter/ModalFooter'
import Button from 'components/forms/button/Button'
import Spinner from 'components/common/spinner/Spinner'
import { DUPLICATE_PIN_QUERY } from 'components/pin/duplicatePin/DuplicatePin'
import { 
  deleteField,
  removeNodeFromEdges,
} from 'helpers/GraphqlHelpers'

import styles from 'components/pin/managePinBoards/managePinBoards.module.css'


const DELETE_PIN_MUTATION = gql`
mutation DeletePin($id: ID!) {
  deletePin(id: $id) {
    id
    user {
      id
      username
    }
    boards {
      edges {
        id
        slug
      }
    }
  }
}
`


function DeletePin(props) {
  const navigate = useNavigate()
  
  let id = props.modalOptions && props.modalOptions.pinID ?
    props.modalOptions.pinID : null
  const backLink = props.modalOptions?.backLink

  const getPinStatus = useQuery(DUPLICATE_PIN_QUERY, {
    variables: { 
      id,
      minWidth: 648, 
      format: "jpeg"
    }
  })

  
  const [deletePin, { loading, error }] = useMutation(DELETE_PIN_MUTATION, {
    variables: { id },
    update: (cache, { data: { deletePin } }) => {

      function deletePinFromCache(cachedPins, { readField }) {
        return removeNodeFromEdges(cachedPins, readField, deletePin.id)
      }

      for (let board of deletePin.boards.edges) {
        // Remove pin from board Pins
        cache.modify({
          id: cache.identify(board),
          fields: {
            pins: deletePinFromCache,
            cover1: deleteField,
            cover2: deleteField,
            cover3: deleteField,
            cover4: deleteField,
            cover5: deleteField,
          }
        })
      }
      // Remove pin from user Pins
      cache.modify({
        id: cache.identify(deletePin.user),
        fields: {
          pins: deletePinFromCache
        }
      })
      // Remove pin from all Pins
      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          pins: deletePinFromCache
        }
      })
    },
    onCompleted: (data) => {
      //console.log('deleted pin', data)
      // Navigate to parent context
      navigate(backLink)
      props.closeModal()
    },
    onError: (e) => { console.error(e) },
  })

  //console.log('DeletePin', props)

  if (getPinStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }

  let errorMessage
  if (getPinStatus.error) {
    console.error(getPinStatus.error)
    errorMessage = "Sorry, an error occured loading initial data..."
  }
  if (error) {
    console.error(error)
    errorMessage = "Sorry, an error occured deleting..."
  }

  if (errorMessage) {
    return (
      <ModalSection>
        <ModalHeader
          title="Delete a pin"
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>{errorMessage}</p>
        </ModalContent>
      </ModalSection>
    )
  }  

  let pin = getPinStatus.data.pin

  return (
    <ModalSection>
      <ModalHeader
        title="Delete a pin"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <div className={styles.previewWrapper}>
          <div className={styles.previewFrame}>
            <img 
              src={pin.preview}
              alt={pin.caption}
            />
          </div>
        </div>
        <h6>Are you sure you want to delete this pin?</h6>
        <p><em>(this operation is irreversible)</em></p>
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Deleting pin..." />}
        <Button
          onClick={deletePin}
          primary={true}
        >Delete pin</Button>
      </ModalFooter>
    </ModalSection>
  )
}


DeletePin.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}



export default withModal(DeletePin)



