import { gql } from '@apollo/client'

export const PIN_IMAGE_FRAGMENT = gql`
fragment PinImageFragment on Pin {
  id
  sha1
  previewsSizes
  previewsFormats
  width
  height
  unsafe
  dominantColor
}
`