import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'

import { SET_BOARD_COVER_MUTATION } from 'components/board/editBoardCover/EditBoardCover'

function SetCoverButton(props) {
  //console.log('SetCoverButton', props)
  
  const [updateBoard] = useMutation(SET_BOARD_COVER_MUTATION)
  
  const handleClick = () => {
    updateBoard({
      variables: {
        id: props.boardID,
        cover1: props.pinID,
      }
    })
  }

  return (
    <button
      title={props.title || "Set as board cover"}
      className={props.className}
      onClick={handleClick}
    >{props.text || "Cover"}</button>
  )

}

SetCoverButton.propTypes = {
  pinID: PropTypes.string.isRequired,
  boardID: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
}

export default SetCoverButton
