import PropTypes from 'prop-types'
import { modalStatusVar, modalTypes } from 'modal'

function DeleteBoardButton(props) {
  
  const handleClick = () => {
    modalStatusVar({
      type: modalTypes.DELETE_BOARD,
      options: { 
        boardID: props.boardID,
        backLink: props.backLink,
      },
    })
  }

  return (
    <button
      title={props.title || ""}
      className={props.className}
      onClick={handleClick}
    >{props.text || "Delete board"}</button>
  )

}

DeleteBoardButton.propTypes = {
  boardID: PropTypes.string.isRequired,
  backLink: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
}

export default DeleteBoardButton