import PropTypes from 'prop-types'
import { AUTHENTICATED_QUERY } from '../app/App'
import { useQuery } from '@apollo/client'
import { useNavigate, useLocation } from 'react-router-dom'


import styles from 'components/common/notFound/notFound.module.css'

import NotFound from 'components/common/notFound/NotFound'


function ErrorMessage({ error }) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { data } = useQuery(AUTHENTICATED_QUERY)
  
  console.error(error)
  console.error('graphQLErrors:', error.graphQLErrors)
  console.error('networkErrors:', error.networkError)

  let message = "Sorry, an error occured."
  
  let err = error && error.graphQLErrors && error.graphQLErrors[0] ?
    error.graphQLErrors[0] : null
  
  if (err && err.extensions && err.extensions.code === 'NOT_FOUND_ERROR') {
    console.log('not found error')
    return <NotFound />
  }

  if (err && err.extensions && err.extensions.code === 'PERMISSION_DENIED_ERROR') {
    console.log('permission denied')
    message = "Sorry, it seems you don't have access to this."
    if ( data && ! data.me) {
      // We redirect to login form if user isn't authenticated
      return navigate(`/login/?next=${pathname}`)
    }
  }


  return (
    <article className={styles.notFound}>
      <h1>Oups...</h1>
      <p>{message}</p>
    </article>
  )
}


ErrorMessage.propTypes = {
  error: PropTypes.object,
}


export default ErrorMessage 
