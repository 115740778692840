import PropTypes from 'prop-types'

import styles from 'components/pin/pinAbstract/pinAbstract.module.css'
import PinRater from 'components/pin/pinRater/PinRater'


function PinAbstractRating(props) {
  if (! props.owner) return null
  return (
    <div className={styles.rate}>
      <PinRater
        pinID={props.pinID}
        owner={props.owner}
        rating={props.rating}
        className={styles.rating}
      />
    </div>
  )
}


PinAbstractRating.propTypes = {
  owner: PropTypes.bool,
  pinID: PropTypes.string.isRequired,
  rating: PropTypes.number,
}

export default PinAbstractRating


