import withModal from 'hoc/withModal/withModal'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'

import styles from './choosePinOrigin.module.css'

import ModalSection from 'components/common/modalSection/ModalSection'
import ModalHeader from 'components/common/modalHeader/ModalHeader'


function ChoosePinOrigin(props) {
  return (
    <ModalSection>
      <ModalHeader
        title="Add a pin from"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <div className={styles.chooseOrigin}>
        <button
          onClick={() => modalStatusVar({
            type: modalTypes.DOWNLOAD_PIN,
            options: { targetBoardID: props.modalOptions.targetBoardID }, 
          })}
        >The web</button>
        <button
          onClick={() => modalStatusVar({
            type: modalTypes.UPLOAD_PIN,
            options: { targetBoardID: props.modalOptions.targetBoardID }, 
          })}
        >Your computer</button>
      </div>
    </ModalSection>
  )
}

export default withModal(ChoosePinOrigin)


