import PropTypes from 'prop-types'

import CreateFolderButton from 'components/folder/createFolderButton/CreateFolderButton'

import styles from 'components/board/userBoards/userBoards.module.css'


function NoFolders(props) {
  const text = props.private ? "No private folders..." : props.owner ? "No public folders..." : "No folders..."
  return (
    <div className={styles.noElements}>
        <p>{text}</p>
        {props.owner ? (
          <CreateFolderButton 
            private={props.private}
            className="button primary"
          />
        ) : null}
    </div>
  )
}

NoFolders.propTypes = {
  owner: PropTypes.bool.isRequired,
  private: PropTypes.bool,
}

export default NoFolders

