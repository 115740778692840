import {
  gql,
} from '@apollo/client'


export const MY_BOARDS_FRAGMENT = gql`
fragment MyBoardsFragment on Query {
  me {
    id
    username #necessary for redirections
    boards {
      edges {
        id
        slug #necessary for redirections
        title
        pinDefaultDescription
        pinDefaultTags
      }
    }
  }
}
`

export const NEW_PIN_FRAGMENT = gql`
  fragment NewPinFragment on Pin {
    id
    createdAt
    title
    caption
    rating
    tags
  }
`

export const NEW_BOARD_PIN_EDGE_FRAGMENT = gql`
  fragment NewBoardPinEdgeFragment on BoardPinEdge {
    id
    cursor
    order
    node { id }
  }
`


