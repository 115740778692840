import PropTypes from 'prop-types'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'


function UpdatePinButton(props) {
  const handleClick = () => {
    modalStatusVar({
      type: modalTypes.UPDATE_PIN,
      options: { pinID: props.pinID },
    })
  }

  return (
    <button
      title={props.title || ""}
      className={props.className}
      onClick={handleClick}
    >{props.text || "Edit"}</button>
  )
}

UpdatePinButton.propTypes = {
  pinID: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
}

export default UpdatePinButton
