import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import withModal from 'hoc/withModal/withModal'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'

import ModalSection from 'components/common/modalSection/ModalSection'
import ModalHeader from 'components/common/modalHeader/ModalHeader'
import ModalContent from 'components/common/modalContent/ModalContent'
import ModalFooter from 'components/common/modalFooter/ModalFooter'
import DownloadPinForm from 'components/pin/downloadPinForm/DownloadPinForm'
import Submit from 'components/forms/submit/Submit'
import Spinner from 'components/common/spinner/Spinner'

const DOWNLOAD_PIN_FORM = 'DOWNLOAD_PIN_FORM'

export const DOWNLOAD_PIN_MUTATION = gql`
mutation DownloadPin($url: String!) {
  downloadPinFile(url: $url) {
    __typename
    tempFileName
    tempFileUrl
    originalName
    clones {
      id
      caption
    }
  }
}
`


function DownloadPin(props) {
  const navigate = useNavigate()
  const [url, setUrl] = useState('')
  const [downloadPin, { loading, error }] = useMutation(DOWNLOAD_PIN_MUTATION, {
    onCompleted: (data) => {
      //console.log('downloaded pin', data)

      if (data.downloadPinFile.clones) { // we don't add pin
        modalStatusVar({
          type: modalTypes.PIN_CLONES,
          options: { clones: data.downloadPinFile.clones },
        })

        return
      }
      modalStatusVar({
        type: modalTypes.CREATE_PIN,
        options: {
          tempPin: {
            url: url,
            tempFileName: data.downloadPinFile.tempFileName,
            tempFileUrl: data.downloadPinFile.tempFileUrl,
            originalName: data.downloadPinFile.originalName,
          },
          ...props.modalOptions, // we pass down existing modalOptions, like default board
        },
      })
      //console.log('modal status', modalStatusVar())
    },
    onError: (e) => { 
      /*
       * We first try to download url because we can't check it's mimetype client side
       * to know if it's an image or not.
       * In case of error, we redirect to scan page
       */
      console.error('downloaded pin errors', e) 
      let scanUrl = `/scan/?url=${btoa(url)}`
      if (props.modalOptions?.targetBoardID) {
        scanUrl += `&targetBoardID=${props.modalOptions.targetBoardID}`
      }
      navigate(scanUrl)
      props.closeModal()
    },
  })


  const handleSubmit = (e) => {
    e.preventDefault()
    downloadPin({ variables: { url }})
  }

  //console.log('DownloadPin', props)

  return (
    <ModalSection>
      <ModalHeader
        title="Add a pin from a website"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <DownloadPinForm
          form={DOWNLOAD_PIN_FORM}
          onSubmit={handleSubmit}
          handleUrlChange={(e) => setUrl(e.target.value)}
          url={url}
          error={error}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Downloading pin..." />}
        <Submit
          primary={true}
          form={DOWNLOAD_PIN_FORM}
          value="Next"
        />
      </ModalFooter>
    </ModalSection>
  )
}


DownloadPin.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}


export default withModal(DownloadPin)
