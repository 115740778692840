import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import styles from './createPinForm.module.css'

import FieldWrapper from 'components/forms/fieldWrapper/FieldWrapper'
import FormErrors from 'components/forms/formErrors/FormErrors'
import BoardSelect from 'components/board/boardSelect/BoardSelect'
import AutoHeightTextArea from 'components/forms/autoHeightTextArea/AutoHeightTextArea'
import TagInput from 'components/forms/tagInput/TagInput'
import CheckboxButton from 'components/forms/checkboxButton/CheckboxButton'
import PinRate from 'components/pin/pinRate/PinRate'

function CreatePinForm(props) {
  //console.log('CreatePinForm', props)

  useEffect(() => {
    /*
     * We only get last data at component mount, else
     * when we set new last data in submit function (parent),
     * it shows "Undo like previous" button during saving.
     */
    setLastCaption(localStorage.getItem("lastCaption") || '')
    setLastTitle(localStorage.getItem("lastTitle") || '')
    setLastTags(localStorage.getItem("lastTags")?.split(' ') || [])
    setLastBoardIDs(localStorage.getItem("lastBoardIDs")?.split(';') || [])
    setLastUnsafe(JSON.parse(localStorage.getItem("lastUnsafe") || false))
  }, [])

  const titleRef = useRef(null)
  const captionRef = useRef(null)
  const previewRef = useRef(null)
  const [previewSize, setPreviewSize] = useState(null)

  // For like previous button
  const [lastCaption, setLastCaption] = useState('')
  const [lastTitle, setLastTitle] = useState('')
  const [lastTags, setLastTags] = useState([])
  const [lastBoardIDs, setLastBoardIDs] = useState([])
  const [lastUnsafe, setLastUnsafe] = useState(false)

  // For like previous button undo
  const [undoTitle, setUndoTitle] = useState('')
  const [undoCaption, setUndoCaption] = useState('')
  const [undoTags, setUndoTags] = useState([])
  const [undoBoardIDs, setUndoBoardIDs] = useState([])
  const [undoUnsafe, setUndoUnsafe] = useState(false)

  const loadPreviewSize = () => {
    const { naturalWidth, naturalHeight } = previewRef.current
    setPreviewSize({ width: naturalWidth, height: naturalHeight })
  }

  const showUndoPrevious = props.title === lastTitle &&
    props.caption === lastCaption &&
    props.unsafe === lastUnsafe &&
    props.tags.join(' ') === lastTags.join(' ') &&
    props.selectedBoardIDs.join(';') === lastBoardIDs.join(';')

  const setPrevious = () => {
    /* We keep track of content before replacing it */
    setUndoBoardIDs(props.selectedBoardIDs.slice())
    setUndoTitle(props.title)
    setUndoCaption(props.caption)
    setUndoTags(props.tags.slice())
    setUndoUnsafe(props.unsafe)
    
    /* We set last boards first else it changes tags and caption to its default */
    props.setSelectedBoardIDs(lastBoardIDs)

    /*
     * Using document.execCommand instead of replacing state value
     * allows us to keep browser undo history
     */
    titleRef.current.focus()
    document.execCommand("selectAll")
    document.execCommand("insertText", false, lastTitle)
    
    captionRef.current.focus()
    document.execCommand("selectAll")
    document.execCommand("insertText", false, lastCaption)

    props.setTags(lastTags)
    props.setUnsafe(lastUnsafe)
  }

  const undoPrevious = () => {
    /* We set undo boards first else it changes tags and caption to its default */
    props.setSelectedBoardIDs(undoBoardIDs)

    titleRef.current.focus()
    document.execCommand("selectAll")
    document.execCommand("insertText", false, undoTitle)
    
    captionRef.current.focus()
    document.execCommand("selectAll")
    document.execCommand("insertText", false, undoCaption)

    props.setTags(undoTags)
    props.setUnsafe(undoUnsafe)
  }

  
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.previewWrapper}>
        <div className={styles.previewImageWrapper}>
          <img 
            src={props.preview}
            alt="" 
            ref={previewRef}
            onLoad={loadPreviewSize}
          />
        </div>
        <PinRate 
          rating={props.rating}
          setRating={props.setRating}
          className={styles.rating}
        />
      </div>
      <form
        id={props.form}
        onSubmit={props.onSubmit}
        className={styles.pinForm}
      >
        <div className={styles.fields}>
          <input
            ref={titleRef}
            id="id-title"
            name="title"
            type="text"
            value={props.title}
            onChange={props.handleTitleChange}
            className="titleInput"
            placeholder="Pin's title"
          />
          <AutoHeightTextArea
            ref={captionRef}
            value={props.caption}
            onChange={props.handleCaptionChange}
            textAreaClassName="captionInput"
            wrapperClassName="captionInputWrapper"
            placeholder="Few words about this pin"
          />
          <div className={styles.tagsWrapper}>
            <TagInput tags={props.tags} setTags={props.setTags} />
          </div>
          <div className={styles.fieldSpacer}></div>
          <BoardSelect
            selectedBoardIDs={props.selectedBoardIDs}
            setSelectedBoardIDs={props.setSelectedBoardIDs}
            recentBoards={props.recentBoards}
            allBoards={props.allBoards}
          />
          <CheckboxButton
            checked={props.unsafe}
            onClick={() => props.setUnsafe(! props.unsafe)}
            label="NSFW (not safe for work)"
          />
        <div className={styles.previewSize}>
          {((props.width && props.height) || previewSize) ? 
            `Original image is ${props.width || previewSize.width} × ${props.height || previewSize.height} px`
          : "…"}
        </div>
        {(props.showLikePreviousButton) ? 
          showUndoPrevious ? (
            <button
              type="button"
              className={`button secondary ${styles.previousButton}`}
              onClick={undoPrevious}
            >Undo like previous</button>
          ) : (
            <button
              type="button"
              className={`button secondary ${styles.previousButton}`}
              onClick={setPrevious}
              title="Set content as last saved pin"
            >Like previous</button>
          )
         : null}


          <FieldWrapper>
            <FormErrors
              error={props.error}
            />
          </FieldWrapper>
        </div>
      </form>
    </div>
  )
}


CreatePinForm.propTypes = {
  form: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  title: PropTypes.string,
  caption: PropTypes.string,
  rating: PropTypes.number,
  unsafe: PropTypes.bool.isRequired,
  tags: PropTypes.array.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
  handleCaptionChange: PropTypes.func.isRequired,
  setUnsafe: PropTypes.func.isRequired,
  setRating: PropTypes.func.isRequired,
  setTags: PropTypes.func.isRequired,
  showLikePreviousButton: PropTypes.bool,
  selectedBoardIDs: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired,
  setSelectedBoardIDs: PropTypes.func.isRequired,
  allBoards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  recentBoards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  pinID: PropTypes.string,
}


export default CreatePinForm
