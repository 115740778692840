
import PropTypes from 'prop-types'

import spinner from './assets/spinner.svg'
import styles from './spinner.module.css'

function Spinner(props) {
  return (
    <div className={styles.spinner}>
      <img src={spinner} alt="spinner" height="40px" />
      <p><em>{props.message}</em></p>
    </div>
  )
}


Spinner.propTypes = {
  message: PropTypes.string,
}

export default Spinner

