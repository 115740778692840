import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'

import withModal from 'hoc/withModal/withModal'

import styles from 'components/pin/createPinForm/createPinForm.module.css'

import ModalSection from 'components/common/modalSection/ModalSection'
import ModalHeader from 'components/common/modalHeader/ModalHeader'
import ModalContent from 'components/common/modalContent/ModalContent'
import ModalFooter from 'components/common/modalFooter/ModalFooter'
import CreatePinForm from 'components/pin/createPinForm/CreatePinForm'
import Submit from 'components/forms/submit/Submit'
import Spinner from 'components/common/spinner/Spinner'

import { MY_BOARDS_FRAGMENT } from 'components/pin/createPin/fragments'
import { 
  addPinToBoardUpdate,
  duplicatePinUpdate,
} from 'helpers/GraphqlHelpers'
import {
  setRecentBoardIDs,
  getRecentBoards,
  getBoard,
  saveLastPinData,
  ADD_PIN_TO_BOARD,
} from 'components/pin/createPin/CreatePin'

const DUPLICATE_PIN_FORM = 'DUPLICATE_PIN_FORM'


export const DUPLICATE_PIN_QUERY = gql`
  ${MY_BOARDS_FRAGMENT}
  query DuplicatePinQuery($id: ID!, $minWidth: Int, $format: String!) {
    ...MyBoardsFragment
    pin(id: $id) {
      id
      title
      caption
      unsafe
      preview(minWidth: $minWidth, format: $format)
      tags
      width
      height
    }
  }
`

const DUPLICATE_PIN_MUTATION = gql`
mutation DuplicatePin(
  $id: ID!,
  $title: String,
  $caption: String, 
  $rating: Int,
  $unsafe: Boolean,
  $tags: [String],
) {
  duplicatePin(
    pin: $id,
    title: $title,
    caption: $caption,
    rating: $rating,
    unsafe: $unsafe,
    tags: $tags,
  ) {
    id
    createdAt
    title
    caption
    rating
    addedVia { id }
    user { id }
    unsafe
    tags
  }
}
`

function DuplicatePin(props) {
  const [selectedBoardIDs, setSelectedBoardIDs] = useState([])
  const [recentBoards, setRecentBoards] = useState([])
  const [title, setTitle] = useState('')
  const [caption, setCaption] = useState('')
  const [defaultCaptionBoardID, setDefaultCaptionBoardID] = useState(null)
  const [rating, setRating] = useState(0)
  const [tags, setTags] = useState([])
  const [defaultTagsBoardID, setDefaultTagsBoardID] = useState(null)
  const [unsafe, setUnsafe] = useState(false)

  let id = props.modalOptions && props.modalOptions.pinID ?
    props.modalOptions.pinID : null

  const getPinStatus = useQuery(DUPLICATE_PIN_QUERY, {
    variables: { 
      id,
      minWidth: 650, 
      format: "jpeg"
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      //console.log('data', data.pin)
      // We populate state with initial data
      setTitle(data.pin.title || '')
      setCaption(data.pin.caption || '')
      setTags(data.pin.tags || [])
      setUnsafe(data.pin.unsafe)
      setRecentBoards(getRecentBoards(data.me.boards.edges))
    }
  })


  const [addPinToBoard, addPinToBoardStatus] = useMutation(ADD_PIN_TO_BOARD, {
    update: addPinToBoardUpdate,
    onError: (e) => console.error(e),
  })

  const [duplicatePin, duplicatePinStatus] = useMutation(DUPLICATE_PIN_MUTATION, {
    update: duplicatePinUpdate,
    onCompleted: (data) => {
      //console.log('duplicatePin completed', data)
      setRecentBoardIDs(selectedBoardIDs)

      const pinID = data.duplicatePin.id
      // TODO add and remove boards server side, like for tags, just send new list of boards IDs
      const promises = []
      for (let boardID of selectedBoardIDs) {
        promises.push(addPinToBoard({ variables: {pinID, boardID }}))
      }

      // Wait until all mutations are done
      Promise.all(promises).then(() => props.closeModal())
    },
    onError: (e) => { console.error(e) },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    // We store last content first, so if something fails it's easier to add it back
    saveLastPinData({ title, caption, tags, selectedBoardIDs, unsafe })
    duplicatePin({variables: { id, title, caption, rating, unsafe, tags }})
  }

  
  const handleSelectedBoardIDsChange = (newSelectedBoardIDs) => {
    // We get newly added board id if any
    let newBoardID
    for (let boardID of newSelectedBoardIDs) {
      if (!selectedBoardIDs.includes(boardID)) {
        newBoardID = boardID
        break // We can get only one new board at each call, no need to continue
      }
    }
    // We use last added board or first board as default board
    const defaultBoardID = newBoardID || newSelectedBoardIDs[0] || null

    if (defaultBoardID) { 
      const defaultBoard = getBoard(defaultBoardID, getPinStatus.data.me.boards.edges)
      // If caption is empty, we use board's default caption
      if (!caption || (defaultCaptionBoardID && !newSelectedBoardIDs.includes(defaultCaptionBoardID))) {
        setDefaultCaptionBoardID(defaultBoardID)
        setCaption(defaultBoard.pinDefaultDescription || '')
      }
      // We add board default tags to tags
      let nextTags = []
      if (tags.length === 0 || (defaultTagsBoardID && !newSelectedBoardIDs.includes(defaultTagsBoardID))) {
        setDefaultTagsBoardID(defaultBoardID)
      } else {
        nextTags = tags.slice()
      }
      for (let tag of defaultBoard.pinDefaultTags) {
        if (!nextTags.includes(tag)) {
          nextTags.push(tag)
        }
      }
      setTags(nextTags)
    } else { // no more boards, we remove default caption and default tags if any
      if (defaultCaptionBoardID) {
        setCaption('')
      }
      if (defaultTagsBoardID) {
        setTags([])
      }
    }
    setSelectedBoardIDs(newSelectedBoardIDs)
  }

  const handleCaptionChange = (e) => {
    setCaption(e.target.value)
    setDefaultCaptionBoardID(null)
  }

  const handleTagsChange = (tags) => {
    setTags(tags)
    setDefaultTagsBoardID(null)
  }


  //console.log('DuplicatePin', props)

  if (getPinStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getPinStatus.error) {
    console.error(getPinStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured loading initial data...</p>
        </ModalContent>
      </ModalSection>
    )
  }

  return (
    <ModalSection className={styles.splitedModal}>
      <ModalHeader
        title="Pin it"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <CreatePinForm
          form={DUPLICATE_PIN_FORM}
          onSubmit={handleSubmit}
          handleTitleChange={(e) => setTitle(e.target.value)}
          handleCaptionChange={handleCaptionChange}
          setRating={setRating}
          setTags={handleTagsChange}
          recentBoards={recentBoards}
          allBoards={getPinStatus.data.me.boards.edges}
          preview={getPinStatus.data.pin.preview}
          selectedBoardIDs={selectedBoardIDs}
          setSelectedBoardIDs={handleSelectedBoardIDsChange}
          title={title}
          caption={caption}
          rating={rating}
          tags={tags}
          height={getPinStatus.data.pin.height}
          width={getPinStatus.data.pin.width}
          error={duplicatePinStatus.error}
          unsafe={unsafe}
          setUnsafe={setUnsafe}
          showLikePreviousButton={true}
        />
      </ModalContent>
      <ModalFooter>
        {duplicatePinStatus.loading && <Spinner message="Duplicating pin..." />}
        {addPinToBoardStatus.loading && <Spinner message="Adding pin to board..." />}
        <Submit
          primary={true}
          form={DUPLICATE_PIN_FORM}
          value="Pin it"
        />
      </ModalFooter>
    </ModalSection>
  )
}


DuplicatePin.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    pinID: PropTypes.string.isRequired,
  }),
}



export default withModal(DuplicatePin)
