import PropTypes from 'prop-types'

import CreateBoardButton from 'components/board/createBoardButton/CreateBoardButton'
import UpdateFolderButton from 'components/folder/updateFolderButton/UpdateFolderButton'
import DeleteFolderButton from 'components/folder/deleteFolderButton/DeleteFolderButton'

import styles from 'components/common/popupMenuWrapper/popupMenuWrapper.module.css'

function FolderDetailMenu({ folderID, folderPrivate, backLink }) {
  return (
    <ul>
      <li><CreateBoardButton
        text={folderPrivate ? "New private board in that folder" : "New board in that folder"}
        folderID={folderID}
        private={folderPrivate}
      /></li>
      <hr className={styles.textPaddedHr} />
      <li><UpdateFolderButton 
        folderID={folderID}
      /></li>
      <hr />
      <li><DeleteFolderButton
        folderID={folderID}
        backLink={backLink}
      /></li>
    </ul>
  )

}

FolderDetailMenu.propTypes = {
  folderID: PropTypes.string.isRequired,
  folderPrivate: PropTypes.bool,
  backLink: PropTypes.string,
}

export default FolderDetailMenu