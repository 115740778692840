import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './orderPinsItem.module.css'

import PinPicture from 'components/pin/pinPicture/PinPicture'
//import { PIN_DRAG_TYPE } from '../orderPinsList/OrderPinsList'


function OrderPinsItem(props) {
  //console.log('OrderPinsItem', props)
  const [basket, setBasket] = useState(false)
  const [basketRightOver, setBasketRightOver] = useState(false)
  const [basketLeftOver, setBasketLeftOver] = useState(false)


  const handleDragEnter = (e) => {
    if (props.dropValid(e.dataTransfer.types) &&
      props.dragIndex !== props.index // no basket if dragged item
    ) {
      e.preventDefault()
      setBasket(true)
    }
  }

  const handleBasketLeave = (e) => {
    e.preventDefault()
    setBasket(false)
    setBasketRightOver(false)
    setBasketLeftOver(false)
  }

  const handleBasketRightEnter = (e) => {
    e.preventDefault()
    setBasketRightOver(true)
  }

  const handleBasketLeftEnter = (e) => {
    e.preventDefault()
    setBasketLeftOver(true)
  }

  const handleBasketOver = (e) => {
    if (props.dropValid(e.dataTransfer.types)) {
      e.preventDefault()
      e.dataTransfer.dropEffect = "move"
    }
  }

  const handleBasketRightDrop = (e) => {
    if (props.dropValid(e.dataTransfer.types)) {
      e.preventDefault()
      e.stopPropagation() // to avoid parent drop to trigger
      props.handleDrop(e, props.index + 1)
      handleBasketLeave(e) // reset styles
    }
  }

  const handleBasketLeftDrop = (e) => {
    if (props.dropValid(e.dataTransfer.types)) {
      e.preventDefault()
      e.stopPropagation() // to avoid parent drop to trigger
      props.handleDrop(e, props.index)
      handleBasketLeave(e) // reset styles
    }
  }

  const getClassName = () => {
    if (props.dragIndex === props.index) {
      // item is dragged, change style
      return styles.pinWrapper + ' ' + styles.dragPin
    }
    if (basketRightOver) {
      return styles.pinWrapper + ' ' + styles.pinRightOver
    }
    if (basketLeftOver) {
      return styles.pinWrapper + ' ' + styles.pinLeftOver
    }
    return styles.pinWrapper
  }



  return (
    <div className={getClassName()}>
      <article
        title="Drag me!"
        className={styles.pin}
        onDragStart={(e) => props.handleDrag(e, props.index)}
        onDragEnter={handleDragEnter}
        onDragEnd={props.handleDragend}
        draggable="true"
      >
        <div
          className={styles.imgWrapper}
        >
          <PinPicture
            pinID={props.pinID}
            alt=""
            minWidth={216}
            minHeight={216}
            draggable={false}
          />
        </div>
      </article>
      {basket ? (
        <div>
          <div
            className={basketLeftOver ? styles.basketLeftOver : styles.basketLeft}
            onDragLeave={handleBasketLeave}
            onDragEnter={handleBasketLeftEnter}
            onDragOver={handleBasketOver}
            onDrop={handleBasketLeftDrop}
          />
          <div
            className={basketRightOver ? styles.basketRightOver : styles.basketRight}
            onDragLeave={handleBasketLeave}
            onDragEnter={handleBasketRightEnter}
            onDragOver={handleBasketOver}
            onDrop={handleBasketRightDrop}
          />
        </div>) : null }
    </div>
  )
}


OrderPinsItem.propTypes = {
  pinID: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  handleDrag: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  handleDragend: PropTypes.func.isRequired,
  dropValid: PropTypes.func.isRequired,
  dragIndex: PropTypes.number,
}

export default OrderPinsItem
