import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import { useQuery, useLazyQuery, gql } from '@apollo/client'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'

import { AUTHENTICATED_QUERY } from 'components/common/app/App'

const PIN_CLONES_QUERY = gql`
  query PinClonesQuery($pinID: ID!) {
  me {
    id
    clones(pin: $pinID) {
      id
      sha1
      title
      caption
    }
  }
}
`

function PinItButton(props) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { data } = useQuery(AUTHENTICATED_QUERY)
  const [getPinClones] = useLazyQuery(PIN_CLONES_QUERY)

  const handleClick = () => {
    if ( data && data.me) { // we have a user
      // If user is owner, we redirect to ManagePinBoards
      if (props.owner) {
        modalStatusVar({
          type: modalTypes.MANAGE_PIN_BOARDS,
          options: { pinID: props.pinID }
        })
      } else { // we search clones for user
        getPinClones({
          variables: { pinID: props.pinID },
          fetchPolicy: 'network-only',
          onCompleted: (data) => {
            if (data.me.clones.length > 0) {
              // we have clone, redirect to ManagePinBoards
              modalStatusVar({
                type: modalTypes.MANAGE_PIN_BOARDS,
                options: { pinID: data.me.clones[0].id }
              })
            } else { // no clones, show DuplicatePin
              modalStatusVar({
                type: modalTypes.DUPLICATE_PIN,
                options: { pinID: props.pinID }
              })
            }
          },
          onError: (error) => {
            console.error(error)
          }
        })
      }
    } else { // user is not authenticated, we redirect to login page
      return navigate(`/login/?next=${pathname}`)
    }
  }

  return (
    <button
      title={props.title || ""}
      className={props.className}
      onClick={handleClick}
    >{props.children || props.text || "Pin it"}</button>
  )
}

PinItButton.propTypes = {
  pinID: PropTypes.string.isRequired,
  owner: PropTypes.bool.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
}

export default PinItButton
