import { gql } from '@apollo/client'
import { NEW_BOARD_PIN_EDGE_FRAGMENT, NEW_PIN_FRAGMENT } from "components/pin/createPin/fragments"

// Delete a field in cache
export function deleteField(_, { DELETE }) {
  return DELETE
}

// Invalidate a field in cache
export function invalidateField(_, { INVALIDATE }) {
  return INVALIDATE
}

// Create a ref from item
export function createItemRef(cache, data, fragment) {
  return cache.writeFragment({
    data,
    fragment,
  })
}

// Create an edge from item ref
export function createItemEdgeNode(itemRef, typename) {
  return {
    __typename: typename,
    node: { itemRef }
  }
}


// Add an item to Edges list
export function addItemToEdges(cachedItems, newItemRef) {
  let edges
  if (! cachedItems.edges) {
    edges = [newItemRef]
  } else {
    edges = [newItemRef, ...cachedItems.edges]
  }
  return {
    ...cachedItems,
    totalCount: cachedItems.totalCount + 1,
    edges,
  }
}


// Remove an item from Edges list
export function removeItemFromEdges(cachedItems, readField, idToRemove) {
  if (! cachedItems.edges) return
  return {
    ...cachedItems,
    totalCount: cachedItems.totalCount - 1,
    edges: cachedItems.edges.filter(
      itemRef =>  idToRemove !== readField('id', itemRef)
    )
  }
}


// Add node item to Edges list
export function addNodeToEdges(cachedItems, itemRef, typename) {
  return addItemToEdges(cachedItems, createItemEdgeNode(itemRef, typename))
}

// Remove node item from Edges list
export function removeNodeFromEdges(cachedItems, readField, idToRemove) {
  if (! cachedItems.edges) return
  return {
    ...cachedItems,
    totalCount: cachedItems.totalCount - 1,
    edges: cachedItems.edges.filter(
      itemRef =>  idToRemove !== readField('id', itemRef.node)
    )
  }
}

function newPinUpdate(cache, pin) {
  const newPinRef = createItemRef(cache, pin, NEW_PIN_FRAGMENT)

  function addPinToCache(cachedPins) {
    return addNodeToEdges(cachedPins, newPinRef, "PinEdge")
  }

  // We update user pins list
  cache.modify({
    id: cache.identify(pin.user),
    fields: {
      pins: addPinToCache,
      // We delete single pins, because prev / next ids are not good anymore
      pin: deleteField,
    }
  })

  // We update all pins list
  cache.modify({
    id: "ROOT_QUERY",
    fields: {
      pins: addPinToCache,
      // We delete single pins, because prev / next ids are not good anymore
      pin: deleteField,
    }
  })
}

export function duplicatePinUpdate(cache, { data: { duplicatePin }}) {
  newPinUpdate(cache, duplicatePin)
}

export function createPinUpdate(cache, { data: { addPin }}) {
  newPinUpdate(cache, addPin)
}


export function addPinToBoardUpdate(cache, { data: { addPinToBoard }}, { variables }) {
  // we don't update cache if boardPin hasn't been created
  if (! addPinToBoard.created) return

  const newBoardPinEdgeRef = createItemRef(
    cache, addPinToBoard.boardPinEdge, NEW_BOARD_PIN_EDGE_FRAGMENT
  )

  function addPinBoardEdgeToCache(cachedEdges) {
    return addItemToEdges(cachedEdges, newBoardPinEdgeRef)
  }
      
  const pinID = variables.pinID
  const boardID = variables.boardID

  // We update board detail
  cache.modify({
    id: `Board:${boardID}`,
    fields: {
      pins: addPinBoardEdgeToCache,
      cover1: deleteField,
      cover2: deleteField,
      cover3: deleteField,
      cover4: deleteField,
      cover5: deleteField,
      // we delete single pins, because prev / next ids are not good anymore
      pin: deleteField,
    }
  })

  // We update pin board's
  cache.modify({
    id: `Pin:${pinID}`,
    fields: {
      boards: deleteField,
    }
  })

}

export function removePinFromBoardUpdate(cache, { data: { removePinFromBoard }}, { variables }) {
  /* Update cache after removing a pin from a board */
  function removePinEdgeFromCache(cachedEdges, { readField }) {
    return removeItemFromEdges(cachedEdges, readField, removePinFromBoard.id)
  }

  const pinID = variables.pinID
  const boardID = variables.boardID
      
  // We remove pinEdge from it's board
  cache.modify({
    id: `Board:${boardID}`,
    fields: {
      pins: removePinEdgeFromCache,
      // we delete single pins, because prev / next ids are not good anymore
      pin: deleteField,
    }
  })

  // We update pin boards
  cache.modify({
    id: `Pin:${pinID}`,
    fields: {
      boards: deleteField,
    }
  })

  const data = cache.readQuery({
    query: gql`
      query BoardCoverQuery($boardID: ID!) {
        node(id: $boardID) {
          id
          cover1
          cover2
          cover3
          cover4
          cover5
        }
      }
    `,
    variables: {
      boardID,
    }
  })

  const covers = ['cover1', 'cover2', 'cover3', 'cover4', 'cover5']

  // TODO will work only when we will get pinID as covers instead of previews urls
  for (let cover of covers) {
    if (data && data.node[cover] === pinID) {
      cache.modify({
        id: `Board:${boardID}`,
        fields: {
          [cover](existingCover, { DELETE }) {
            return DELETE
          }
        }
      })
    }
  }
}