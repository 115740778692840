import { useState } from 'react'

const MIN_MOVE = 50

function useSwipeGestures({ handleSwipeUp, handleSwipeDown, handleSwipeRight, handleSwipeLeft }) {
  const [isTouchScreen, setIsTouchScreen] = useState(false)
  const [touchStartPosition, setTouchStartPosition] = useState([0, 0])

  const handleTouchStart = (e) => {
    setIsTouchScreen(true)
    setTouchStartPosition([e.touches[0].clientX, e.touches[0].clientY])
  }

  const handleTouchEnd = (e) => {
    const touchEndPosition = [e.changedTouches[0].clientX, e.changedTouches[0].clientY]
    const xDelta = Math.abs(touchStartPosition[0] - touchEndPosition[0])
    const yDelta = Math.abs(touchStartPosition[1] - touchEndPosition[1])
    
    if (yDelta > xDelta && yDelta >= MIN_MOVE) { // user swiped up or down
      if (touchStartPosition[1] < touchEndPosition[1]) { // user swiped down
        handleSwipeDown(e)
      } else {
        handleSwipeUp(e)
      }
    }

    if (xDelta >= yDelta && xDelta >= MIN_MOVE ) { // user swiped right or left
      if (touchStartPosition[0] > touchEndPosition[0]) { // user swiped left
        handleSwipeLeft(e)
      } else {
        handleSwipeRight(e)
      }
    }
  }


  return [handleTouchStart, handleTouchEnd, isTouchScreen]
}

export default useSwipeGestures