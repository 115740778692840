import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import HeaderLinks from 'components/common/headerLinks/HeaderLinks'
import HeaderSearch from 'components/common/headerSearch/HeaderSearch'

import pinpict1x from './assets/pinpict.1x.png'
import pinpict2x from './assets/pinpict.2x.png'
import styles from './header.module.css'


function Header({ children }) {
  const [showHeader, setShowHeader] = useState(true)
  const [scrollPosition, setScrollPosition] = useState(window.scrollY)

  const handleScroll = () => {
    const newScrollPosition = window.scrollY
    setShowHeader(newScrollPosition <= 55 || newScrollPosition < scrollPosition)
    setScrollPosition(newScrollPosition)
  }

  useEffect(() => {
    // hide header on scroll down, show it on scroll up
    window.addEventListener('scroll', handleScroll)

    return (() => window.removeEventListener('scroll', handleScroll))
  })

  return (
    <header
      role="banner"
      className={showHeader ? styles.header : `${styles.header} ${styles.headerHidden}`}
    >
      <div className={styles.banner}>
        <h1
          className={styles.bannerTitle}
        >
          <Link to="/">
            <img
              className={styles.pinpictLogo}
              src={pinpict1x}
              srcSet={`${pinpict1x} 1x, ${pinpict2x} 2x`}
              alt="PinPict"
            />
          </Link>
        </h1>
        <HeaderSearch />
        <HeaderLinks />
      </div>
      { children }
    </header>
  )
}

export default Header
