import PropTypes from 'prop-types'

import UpdatePinButton from 'components/pin/updatePinButton/UpdatePinButton'
import SetCoverButton from 'components/board/setCoverButton/SetCoverButton'
import PinItButton from 'components/pin/pinItButton/PinItButton'
import styles from 'components/pin/pinDetail/pinDetail.module.css'


function PinDetailHeader(props) {
  return (
    <header>
      <a
        title="Visit site pin comes from"
        href={props.sourceFileUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={`button primary ${styles.headerButton}`}
      >Visit site</a>
      <a
        title="Open original image"
        href={props.original}
        target="_blank"
        rel="noopener noreferrer"
        className={`button primary ${styles.headerButton}`}
      >Image</a>
      { props.owner && props.pinID && props.boardID ? (
        <SetCoverButton
          owner={props.owner}
          pinID={props.pinID}
          boardID={props.boardID}
          className={`button primary ${styles.headerButton}`}
        />
      ) : null}
      { props.owner ? (
        <UpdatePinButton
          pinID={props.pinID}
          className={`button primary ${styles.headerButton}`}
        />
      ) : null}
      <PinItButton
        pinID={props.pinID}
        owner={props.owner}
        className={`button primary ${styles.headerPinButton}`}
      />
    </header>
  )
}

PinDetailHeader.propTypes = {
  owner: PropTypes.bool.isRequired,
  pinID: PropTypes.string.isRequired,
  boardID: PropTypes.string,
  sourceFileUrl: PropTypes.string,
  original: PropTypes.string,
}

export default PinDetailHeader
