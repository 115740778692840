import PropTypes from 'prop-types'
import { modalStatusVar, modalTypes } from 'modal'

function CreateBoardButton(props) {
  const handleClick = () => {
    modalStatusVar({
      type: modalTypes.CREATE_BOARD,
      options: { secret: props.private, folderID: props.folderID },
    })
    
    if (props.onClick) props.onClick() // closing popup
  }

  return (
    <button
      title={props.title || ""}
      className={props.className}
      onClick={handleClick}
    >{props.text || (props.private ? "New private board" : "New board")}</button>
  )
}

CreateBoardButton.propTypes = {
  private: PropTypes.bool,
  folderID: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
}


export default CreateBoardButton