import PropTypes from 'prop-types'

function PinsGridItem(props) {
  
  //console.log('PinsGridItem', props)
  return (
    <li
      style={{
        transform: `translate(${props.translateX}px, ${props.translateY}px)`,
        opacity: props.display ? 1 : 0,
        height: `${props.height}px`,
        width: `${props.width}px`,
      }}
    >
      {props.children}
    </li>
  )
}


PinsGridItem.propTypes = {
  display: PropTypes.bool,
  translateX: PropTypes.number.isRequired,
  translateY: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default PinsGridItem