import PropTypes from 'prop-types'
import {
  useQuery,
  gql,
} from '@apollo/client'

import defaultAvatar from './assets/default_avatar.png'

const USER_AVATAR_QUERY = gql`
  query UserAvatarQuery($username: String!, $minSize1x: Int!, $minSize2x: Int!) {
    user(username: $username) {
      id
      avatar1x: avatar(minSize: $minSize1x, format: "jpeg")
      avatar2x: avatar(minSize: $minSize2x, format: "jpeg")
    }
  }
`


function UserAvatar({ username, minSize, title, className }) {
  //console.log('UserAvatar props', username, minSize, onClick, title)
  const { loading, data, error } = useQuery(USER_AVATAR_QUERY, {
    variables: {
      username: username,
      minSize1x: minSize,
      minSize2x: minSize * 2,
    },
  })


  if (loading) return null
  if (error) console.error(error)

    //console.log('UserAvatar', data)

  const src = data.user.avatar1x || defaultAvatar
  const srcSet = data.user.avatar2x ? `${data.user.avatar1x} 1x, ${data.user.avatar2x} 2x` : ""

  return (
    <div className={`avatar ${className}`}>
      <img
        src={src}
        srcSet={srcSet}
        alt={`${username}'s avatar`}
        title={title || null}
      />
    </div>
  )
}


export default UserAvatar


UserAvatar.propTypes = {
  username: PropTypes.string.isRequired,
  minSize: PropTypes.number.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
}
