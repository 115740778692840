import PropTypes from 'prop-types'

import styles from 'components/board/userBoards/userBoards.module.css'

import FoldersList from 'components/folder/foldersList/FoldersList'

function PrivateFoldersWrapper(props) {
  // we show section only if owner (to show create private folder button)
  // or if there are some private folders to show
  if (! props.owner && props.folders.length === 0) return null
  return (
    <div className={styles.privateBoardsWrapper}>
      <section className={styles.boardsSection + " columned"}>
        <h2>Private folders</h2>
          <FoldersList
            folders={props.folders}
            owner={props.owner}
            private={true}
          />
      </section>
    </div>
  )
}

PrivateFoldersWrapper.propTypes = {
  folders: PropTypes.array.isRequired,
  owner: PropTypes.bool.isRequired
}

export default PrivateFoldersWrapper
