import { gql, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { getUserNames } from 'components/user/userDetail/UserDetail'
import Spinner from 'components/common/spinner/Spinner'
import UserAvatar from 'components/user/userAvatar/UserAvatar'
import PinAddedVia from 'components/pin/pinAddedVia/PinAddedVia'

import styles from './pinDetailUser.module.css'
import mainStyles from 'components/pin/pinDetail/pinDetail.module.css'



const USER_ABSTRACT_QUERY = gql`
  query UserShortDetailQuery($username: String!) {
    me {
      id
    }
    user(username: $username) {
      id
      username
      lastName
      firstName
      websiteLink
    }
  }
`



function PinDetailUser({ username, addedVia }) {

  const { loading, data, error } = useQuery(USER_ABSTRACT_QUERY, {
    variables: { 
      username: username,
    },
  })
  
  // TODO move this to client only graphql field
  let userHomeLink = `/${username}/`

  if (loading) return <Spinner />
  if (error) console.error(error)

  return (
    <article className={mainStyles.card}>
      {/* Not sure about header there */}
      <header className={mainStyles.cardHeader}>
        <h5>Users</h5>
      </header>
      
      <div className={styles.owner}>
        <Link 
          to={userHomeLink}
        >
          <UserAvatar
            className={styles.avatar}
            username={data.user.username}
            minSize={60}
          />
        </Link>
        <p>By <Link to={userHomeLink}>{getUserNames(data.user)}</Link></p>
      </div>
      <hr className={styles.paddedHr} />
      {addedVia ? (<PinAddedVia addedVia={addedVia} />) : null}
    </article>
  )

}


PinDetailUser.propTypes = {
  username: PropTypes.string.isRequired,
  addedVia: PropTypes.string,
}

export default PinDetailUser
