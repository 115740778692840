import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import PinImg from 'components/pin/pinImg/PinImg'
import styles from './boardAbstract.module.css'

import EditBoardCover from 'components/board/editBoardCover/EditBoardCover'


function BoardAbstract(props) {
  //console.log('BoardAbstract', props)

  const [editCover, setEditCover] = useState('')
  const link = `${props.baseLink}boards/${props.slug}/`
  const pinsCount = `${props.pinsCount} ${props.pinsCount === 1 ? "Pin" : "Pins"}`

  return (
    <article className={styles.board}>
      <Link
        to={link}
        title={`More from ${props.title}`}
      >
        <header>
          <h1>{props.title}</h1>
          <div className={styles.headerSubline}>{pinsCount}</div>
        </header>
        {editCover ? (
            <EditBoardCover
              actualCover={props.cover1}
              closeEdition={() => setEditCover(false)}
              boardID={props.id}
            />
            ) : (
            <div
              className={`borderedImgWrapper ${styles.imgWrapper}`}
            >
              {props.cover1 ? (
                <PinImg
                  pinID={props.cover1.id}
                  minWidth={214}
                  minHeight={157}
                  alt={props.title}
                />
              ) : null}
              {props.owner && ! editCover ? (
                <div className={styles.changeCover}>
                  <button
                    className={styles.labelButton}
                    title="Change board cover"
                    onClick={(e) => {
                      e.preventDefault()
                      setEditCover(true)
                    }}
                  >Change cover</button>
                </div>
              ) : null}
              <div
                className={styles.label + " " + styles.pinsCount}
              >{pinsCount}</div>
            </div>
        )}
        <ul>
          <li className="borderedImgWrapper">
            {props.cover2 ? (
              <PinImg
                pinID={props.cover2.id}
                minWidth={49}
                minHeight={49}
                alt={props.title}
              />
            ) : null}
          </li>
          <li className="borderedImgWrapper">
            {props.cover3 ? (
              <PinImg
                pinID={props.cover3.id}
                minWidth={49}
                minHeight={49}
                alt={props.title}
              />
            ) : null}
          </li>
          <li className="borderedImgWrapper">
            {props.cover4 ? (
              <PinImg
                pinID={props.cover4.id}
                minWidth={49}
                minHeight={49}
                alt={props.title}
              />
            ) : null}
          </li>
          <li className="borderedImgWrapper">
            {props.cover5 ? (
              <PinImg
                pinID={props.cover5.id}
                minWidth={49}
                minHeight={49}
                alt={props.title}
              />
            ) : null}
          </li>
        </ul>
      </Link>
    </article>
  )
}

BoardAbstract.propTypes = {
  owner: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  secret: PropTypes.bool.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
  pinsCount: PropTypes.number.isRequired,
  cover1: PropTypes.object,
  cover2: PropTypes.object,
  cover3: PropTypes.object,
  cover4: PropTypes.object,
  cover5: PropTypes.object,
  baseLink: PropTypes.string.isRequired,
}

export default BoardAbstract
