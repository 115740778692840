import PropTypes from 'prop-types'

import { NavLink } from 'react-router-dom'

import styles from './userNav.module.css'


function UserNav(props) {
  return (
    <nav className={styles.userNav}>
      <ul>
        <li>
          <NavLink
            to={`/${props.username}/boards/`}
            end
            className={({ isActive }) => (isActive ? styles.active : "")}
          >
            <span
              className={styles.number}
            >{props.boardsNumber}</span>
            <br />
            <span
              className={styles.title}
            >{props.boardsNumber === 1 ? "Board" : "Boards"}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/${props.username}/pins/`}
            end
            className={({ isActive }) => (isActive ? styles.active : "")}
          >
            <span
              className={styles.number}
            >{props.pinsNumber}</span>
            <br />
            <span
              className={styles.title}
            >{props.pinsNumber === 1 ? "Pin" : "Pins"}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
          to={`/${props.username}/folders/`}
            end
            className={({ isActive }) => (isActive ? styles.active : "")}
          >
            <span
              className={styles.number}
            >{props.foldersNumber}</span>
            <br />
            <span
              className={styles.title}
            >{props.foldersNumber === 1 ? "Folder" : "Folders"}</span>
          </NavLink>
          </li>
      </ul>
    </nav>
  )
}

UserNav.propsTypes = {
  username: PropTypes.string.isRequired,
  boardsNumber: PropTypes.number.isRequired,
  pinsNumber: PropTypes.number.isRequired,
  foldersNumber: PropTypes.number.isRequired,
}

export default UserNav
