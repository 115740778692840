import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useApolloClient, useQuery } from '@apollo/client'

import withModal from 'hoc/withModal/withModal'

import { AUTHENTICATED_QUERY } from 'components/common/app/App'

import ModalSection from 'components/common/modalSection/ModalSection'
import ModalHeader from 'components/common/modalHeader/ModalHeader'
import ModalContent from 'components/common/modalContent/ModalContent'
import ModalFooter from 'components/common/modalFooter/ModalFooter'
import UploadAvatarForm from 'components/user/uploadAvatarForm/UploadAvatarForm'
import Submit from 'components/forms/submit/Submit'
import Spinner from 'components/common/spinner/Spinner'
const REACT_APP_UPLOAD_ENDPOINT = process.env.REACT_APP_UPLOAD_ENDPOINT


const UPLOAD_AVATAR_FORM = 'UPLOAD_AVATAR_FORM'


function UploadAvatar(props) {
  const client = useApolloClient()
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const meStatus = useQuery(AUTHENTICATED_QUERY)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const fd = new FormData()
    fd.append('avatar', file)

    try {
      await fetch(REACT_APP_UPLOAD_ENDPOINT + '/avatar', {
        method: 'POST',
        body: fd,
      })
      const cache = client.cache
      // Invalidate cached avatar
      //console.log('me', meStatus.data.me)
      cache.modify({
        id: cache.identify(meStatus.data.me),
        fields: {
          avatar(existingAvatar, { DELETE }) {
            console.log('delete')
            return DELETE
          }
        }
      })
      //console.log('updated me', meStatus.data.me)

      
      /*cache.evict({
        id: cache.identify(meStatus.data.me),
        fieldName: "avatar"
      })*/

      props.closeModal()
    } catch(e) {
      setLoading(false)
      console.error(e)
      setError(e.message)
    }
  }

  const handleFileChange = (e) => {
    setFile(e.target.files[0])
  }

  if (meStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }

  return (
    <ModalSection>
      <ModalHeader
        title="Change my avatar"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <UploadAvatarForm
          form={UPLOAD_AVATAR_FORM}
          onSubmit={handleSubmit}
          handleFileChange={handleFileChange}
          error={error}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Uploading avatar..." />}
        <Submit
          primary={true}
          form={UPLOAD_AVATAR_FORM}
          value="Upload"
        />
      </ModalFooter>
    </ModalSection>
  )
}


UploadAvatar.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}


export default withModal(UploadAvatar)
