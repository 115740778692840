import PropTypes from 'prop-types'
import { modalStatusVar, modalTypes } from 'modal'

function CreatePinButton(props) {
  const handleClick = () => {
    modalStatusVar({
      type: modalTypes.CHOOSE_PIN_ORIGIN,
      options: { targetBoardID: props.boardID },
    })
    
    if (props.onClick) props.onClick() // closing popup
  }

  return (
    <button
      title={props.title || ""}
      className={props.className}
      onClick={handleClick}
    >{props.text || "New pin"}</button>
  )
}

CreatePinButton.propTypes = {
  boardID: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
}


export default CreatePinButton