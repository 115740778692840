import PropTypes from 'prop-types'

import { gql, useMutation } from '@apollo/client'

import PinRate from 'components/pin/pinRate/PinRate'


const RATE_PIN_MUTATION = gql`
mutation RatePin($id: ID!, $rating: Int!) {
  ratePin(id: $id, rating: $rating) {
    __typename
    id
    rating
  }
}
`

/*
 * Wrapper arround PinRate handling rate mutation.
 */
function PinRater({ pinID, owner, rating, className }) {
  const [ratePin] = useMutation(RATE_PIN_MUTATION, {
    onError: (e) => { console.error(e) },
  })

  if (!owner) return null
  
  const setRating = (targetRate) => {
    ratePin({variables: { 
      id: pinID,
      rating: targetRate,
    }})
  }

  return (
    <PinRate 
      rating={rating}
      setRating={setRating}
      className={className}
    />
  )
}

PinRater.propTypes = {
  pinID: PropTypes.string.isRequired,
  owner: PropTypes.bool,
  rating: PropTypes.number,
  className: PropTypes.string,
}

export default PinRater