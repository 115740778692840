import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation, useQuery } from '@apollo/client'

import PinImg from 'components/pin/pinImg/PinImg'
import AccessibilityText from 'components/common/accessibilityText/AccessibilityText'
import styles from 'components/board/boardAbstract/boardAbstract.module.css'



const GET_BOARD_COVERS_QUERY = gql`
query GetBoardCovers(
  $id: ID!,
  $first: Int,
  $after: String
) {
  board(id: $id) {
    id
    pins(first: $first, after: $after) {
      edges {
        node {
          id
        }
      }
    }
  }
}
`

export const SET_BOARD_COVER_MUTATION = gql`
mutation SetBoardCover($id: ID!, $cover1: ID) {
  updateBoard(id: $id, cover1: $cover1) {
    __typename
    id
    cover1 { id }
  }
}
`


function EditBoardCover({ boardID, closeEdition, actualCover }) {

  const [selectedCover, setSelectedCover] = useState(null)
  const { loading, data, error } = useQuery(GET_BOARD_COVERS_QUERY, {
    variables: {
      id: boardID,
      first: 20000,
      after: null,
    },
    onCompleted: ({ board }) => {
      // if no actual cover set, we use first in list
      if (! actualCover && board.pins.edges.length > 0) {
        setSelectedCover(0)
        return
      }
      // else we search current cover in list
      for (const [index, edge] of board.pins.edges.entries()) {
        if (edge.node.id === actualCover) {
          setSelectedCover(index)
          return
        }
      }
    }
  })

  if (error) {
    console.error(error)
  }

  const [updateBoard] = useMutation(SET_BOARD_COVER_MUTATION)

  let covers = []
  if (data && data.board && data.board.pins) {
    covers = data.board.pins.edges
  }

  const setNextCover = () => {
    if (selectedCover === null) return
    let next = selectedCover + 1
    // we loop other the array
    if (next > (covers.length - 1)) next = 0
    setSelectedCover(next)
  }

  const setPrevCover = () => {
    if (selectedCover === null) return
    let prev = selectedCover - 1
    // we loop other the array
    if (prev < 0) prev = covers.length - 1
    setSelectedCover(prev)
  }

  const saveCover = () => {
    if (selectedCover === null) return
    let newCover = covers[selectedCover].node
    if (newCover.preview === actualCover) {
      return
    }
    updateBoard({
      variables: {
        id: boardID,
        cover1: newCover.id
      },
      optimisticResponse: {
        updateBoard: {
          id: boardID,
          __typename: "Board",
          cover1: newCover.id
        }
      },
    })
  }
 
  let selectedCoverID = actualCover
  if (selectedCover !== null && covers[selectedCover]) {
    selectedCoverID = covers[selectedCover].node.id
  }

  return (
    <div className={`borderedImgWrapper ${styles.imgWrapper}`} >
      <PinImg
        pinID={selectedCoverID}
        minWidth={214}
        minHeight={157}
        alt="Board's cover preview"
      />
      { loading ? 
        (<div className={styles.loading}>Loading...</div>) :
        (<>
          <div className={styles.changeCover}>
            <button
              className={styles.labelButton}
              title="Select this cover"
              onClick={(e) => {
                e.preventDefault()
                saveCover()
                closeEdition()
              }}
            >Select</button>
          </div>
          <div className={styles.leftArrow}>
            <button
              title="Try previous cover"
              onClick={(e) => {
                e.preventDefault()
                setPrevCover()
              }}
            ><AccessibilityText text="Previous arrow" /></button>
          </div>
          <div className={styles.rightArrow}>
            <button
              title="Try next cover"
              onClick={(e) => {
                e.preventDefault()
                setNextCover()
              }}
            ><AccessibilityText text="Next arrow" /></button>
            </div>
          </>)
      }
    </div>
  )
}

EditBoardCover.propTypes = {
  boardID: PropTypes.string.isRequired,
  closeEdition: PropTypes.func.isRequired,
  actualCover: PropTypes.string,
}

export default EditBoardCover
