
import {
  useQuery,
  gql,
} from '@apollo/client'
import { NetworkStatus } from '@apollo/client'

import LastPinsList from 'components/pin/lastPinsList/LastPinsList'
import ErrorMessage from 'components/common/errorMessage/ErrorMessage'
import Spinner from 'components/common/spinner/Spinner'

import styles from 'components/board/boardDetail/boardDetail.module.css'

const LAST_PINS_QUERY = gql`
  query LastPinsQuery(
    $first: Int,
    $after: String,
  ) {
    me {
      id
    }
    pins(first: $first, after: $after) {
      totalCount
      edges {
        node {
          id
          caption
          rating
          height
          width
          user {
            id
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`


function LastPins(props) {

  const { data, error, fetchMore, networkStatus } = useQuery(LAST_PINS_QUERY, {
    variables: { 
      first: 50,
      after: null,
    }
  })
  //console.log('LastPins', data, error)
  
  if (networkStatus === NetworkStatus.loading) return <Spinner />
  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }
  let pins = data.pins
  let visitorID = data.me ? data.me.id : null
  

  return (
    <section id="content">
      <header className={styles.header}>
        <h1>Last pins on PinPict</h1>
        <p 
          className={styles.pinCount}
        >{pins.totalCount} {pins.totalCount === 1 ? " pin": " pins"}</p>
      </header>
      <LastPinsList
        pins={pins.edges}
        visitorID={visitorID}
        hasNextPage={pins.pageInfo.hasNextPage}
        loadMore={() => {
          //console.log('Load more after', pins.pageInfo.endCursor)
          return fetchMore({
            variables: { 
              after: pins.pageInfo.endCursor,
            },
            notifyOnNetworkStatusChange: true
          })
        }}
        loadingMore={networkStatus === NetworkStatus.fetchMore }
        baseLink="/"
      />
    </section>
  )
}


export default LastPins
